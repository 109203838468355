import { useState } from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AgentFirstStep from "./AgentFirstStep";
import AgentSecondStep from "./AgentSecondStep";
import AgentThirdStep from "./AgentThirdStep";
import AgentFourthStep from "./AgentFourthStep";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];

const AgentOnboarding = () => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            // Handle the final submission or navigation after the last step
            navigate('/finish');
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    return (
        <Box sx={{mt:5}}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: '32px', margin: '32px', borderRadius: '12px' }}>
                {/* Step 1 Content */}
                {activeStep === 0 && (
                    <AgentFirstStep/>
                )}

                {/* Step 2 Content */}
                {activeStep === 1 && (
                    <AgentSecondStep/>
                )}

                {/* Step 3 Content */}
                {activeStep === 2 && (
                    <AgentThirdStep/>
                )}

                {/* Step 4 Content */}
                {activeStep === 3 && (
                    <AgentFourthStep/>
                )}

                <Divider sx={{ my: 2 }} />

                {/* Navigation Buttons */}
                <Box sx={{ ml: 'auto', mt: 5, display: 'flex', gap: '12px', justifyContent: 'flex-end' }}>
                    {activeStep > 0 && (
                        <Button variant="cancel" onClick={handleBack}>
                            Back
                        </Button>
                    )}
                    <Button variant="save" onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Submit Application' : 'Next'}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default AgentOnboarding;

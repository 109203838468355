import React, { useState } from 'react';
import {Button, Box, Tabs, Tab, Divider, Typography} from '@mui/material';

import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from '@mui/icons-material/GetApp';
import BarChartIcon from '@mui/icons-material/BarChart';
import Team from "./TeamSecurityContents/Team";
import SecurityHistory from "./TeamSecurityContents/SecurityHistory";
import InstalledApps from "./TeamSecurityContents/InstalledApps";
import DataSharing from "./TeamSecurityContents/DataSharing";
import ViewRolePermissions from "./TeamSecurityContents/ViewRolePermissions";
import AddNewUsers from "./TeamSecurityContents/AddNewUsers";
import AssignPermissions from "./TeamSecurityContents/AssignPermissions";

const TeamSecurity = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box display='flex'>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>
                            Team and Security
                        </Typography >
                        <Typography variant='h2'>
                            Manage your team members and their roles to enhance collaboration and productivity.
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                        {/*<Button variant="cancel" startIcon={<FilterListIcon />}>*/}
                        {/*    Filter*/}
                        {/*</Button>*/}
                        {/*<Button variant="cancel" startIcon={<GetAppIcon />}>*/}
                        {/*    Export*/}
                        {/*</Button>*/}
                        {/*<Button variant="cancel" startIcon={<BarChartIcon />}>*/}
                        {/*    Analyze*/}
                        {/*</Button>*/}
                        {/*<Button variant="save" startIcon={<AddIcon />} sx={{ minWidth: 200 }}>*/}
                        {/*    Create Subscription*/}
                        {/*</Button>*/}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Team" />
                        {/*<Tab label="Security history" />*/}
                        {/*<Tab label="Installed apps" />*/}
                        {/*<Tab label="Data sharing" />*/}
                        <Tab label="Add New Users " />
                        <Tab label="Assign Permissions" />
                        <Tab label="View Role Permissions" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <Team/> }
                    {/*{ value === 1 && <SecurityHistory/> }*/}
                    {/*{ value === 1 &&  <InstalledApps/> }*/}
                    {/*{ value === 2 &&  <DataSharing/> }*/}
                    { value === 1 &&  <AddNewUsers/> }
                    { value === 2 &&  <AssignPermissions/> }
                    { value === 3 &&  <ViewRolePermissions/> }
                </Box>
            </Box>
        </Box>
    );
};

export default TeamSecurity;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunks for each API endpoint
export const getUserOrganizations = createAsyncThunk(
    'userOrganizations/getUserOrganizations',
    async (_, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-organizations?id=5`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createUserOrganization = createAsyncThunk(
    'userOrganizations/createUserOrganization',
    async (organizationData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/user-organizations`, organizationData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const changeUserOrganizationDefault = createAsyncThunk(
    'userOrganizations/changeUserOrganizationDefault',
    async ({ id, isActive }, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/user-organizations/${id}/default/${isActive}`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const getSingleUserOrganization = createAsyncThunk(
    'userOrganizations/getSingleUserOrganization',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.get(`${API_BASE_URL}/user-organizations/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteUserOrganization = createAsyncThunk(
    'userOrganizations/deleteUserOrganization',
    async (id, { rejectWithValue }) => {
        try {
            const token = await getToken();
            await axios.delete(`${API_BASE_URL}/user-organizations/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const userOrganizationsSlice = createSlice({
    name: 'userOrganizations',
    initialState: {
        organizations: [],
        selectedOrganization: null,
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUserOrganizations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserOrganizations.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizations = action.payload;
            })
            .addCase(getUserOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message;
            })
            .addCase(createUserOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizations.push(action.payload);
            })
            .addCase(changeUserOrganizationDefault.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.organizations.findIndex(org => org.id === action.payload.id);
                if (index !== -1) {
                    state.organizations[index] = action.payload;
                }
                if (state.selectedOrganization && state.selectedOrganization.id === action.payload.id) {
                    state.selectedOrganization = action.payload;
                }
            })
            .addCase(getSingleUserOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedOrganization = action.payload;
            })
            .addCase(deleteUserOrganization.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.organizations = state.organizations.filter(org => org.id !== action.payload);
                if (state.selectedOrganization && state.selectedOrganization.id === action.payload) {
                    state.selectedOrganization = null;
                }
            });
    },
});

export default userOrganizationsSlice.reducer;
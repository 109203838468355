import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Header from './components/header/Header';
import Sidebar from './components/Sidebar';
import { CompanyLogo } from './components/header/HeaderSvgImages';
import './App.css';
import AppRoutes from './AppRoutes';
import { FormProvider } from "./components/context/FormContext";
import { fetchOrganizationProfiles } from './redux/organizationProfilesSlice';
import { fetchOnboardingRecords } from "./redux/accountOnboardingSlice";
import keycloak from "./keycloak";

const drawerWidth = 311;

const App = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { status } = useSelector((state) => state.organizationProfiles);
    const { currentStep, status: onboardingStatus } = useSelector((state) => state.accountOnboarding);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Single effect for data fetching
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = keycloak.tokenParsed;
                // Debug method to see the full token structure
                // console.log('Full token:', JSON.stringify(token, null, 2));
                const organizationId = token?.organization_id;

                if (status === 'idle' && organizationId) {
                    await dispatch(fetchOrganizationProfiles(organizationId));
                }

                if (onboardingStatus === 'idle' && !keycloak.hasRealmRole('eventlah-admin')) {
                    await dispatch(fetchOnboardingRecords(token?.email)).unwrap();
                }
            } catch (error) {
                setSnackbar({
                    open: true,
                    message: 'Failed to connect to the server. Please try again later.',
                    severity: 'error'
                });
            }
        };

        if (keycloak.authenticated) {
            fetchData();
        }
    }, [dispatch, status, onboardingStatus]);

    useEffect(() => {
        const isAdmin = keycloak.hasRealmRole('eventlah-admin');

        if (isAdmin) {
            return;
        }

        if (!isAdmin) {
            if (currentStep) {
                if (currentStep === 'completed') {
                    if (location.pathname === '/') {
                        navigate('/dashboard');
                    } else {
                        return;
                    }
                } else {
                    navigate('/' + currentStep);
                }
            }
        }
    }, [currentStep, navigate, location.pathname]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header toggleSidebar={toggleSidebar} />
            <Sidebar isOpen={isSidebarOpen} />
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    transition: 'margin-left 0.3s, width 0.3s',
                    marginLeft: { xs: 0, sm: isSidebarOpen ? 0 : `-${drawerWidth}px`, },
                    width: { xs: '100%', sm: isSidebarOpen ? `calc(100% + ${drawerWidth}px)` : '100%' },
                    position: 'relative',
                }}
            >
                <Toolbar />
                <FormProvider>
                    <AppRoutes />
                </FormProvider>
            </Box>
        </Box>
    );
};

export default App;
import {Box, TextField, Typography, Button, Divider, CircularProgress} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AgentBankDetails = () => {
    const [loading, setLoading] = useState(false);
    const [customerData, setCustomerData] = useState({
        bankName: '',
        accountNumber: '',
        accountName: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Submit form logic here
        console.log("Submitted data: ", customerData);
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            {/* Bank Details Fields */}
            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Bank Name</TextFieldTitle>
                <TextField
                    name="bankName"
                    value={customerData.bankName}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Bank Name"
                    label="Bank Name"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Account Number</TextFieldTitle>
                <TextField
                    name="accountNumber"
                    value={customerData.accountNumber}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Account Number"
                    label="Account Number"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                <TextFieldTitle>Account Name</TextFieldTitle>
                <TextField
                    name="accountName"
                    value={customerData.accountName}
                    onChange={handleChange}
                    fullWidth
                    placeholder="Account Name"
                    label="Account Name"
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px' }}>
                <Button variant='cancel' type="button">Cancel</Button>
                <Button variant='save' type="submit" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </Box>
        </Box>
    );
};

export default AgentBankDetails;

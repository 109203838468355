import {combineReducers, configureStore} from '@reduxjs/toolkit';
import accountOnboardingSlice from "./accountOnboardingSlice";
import accountsSliceReducer from './accountSlice';
import adminEventTypeReducer from './adminEventTypeSlice';
import adminFeatureReducer from './adminFeatureSlice';
import adminOrganizationReducer from './adminOrganizationSlice';
import adminPaymentStructureReducer from './adminPaymentStructureSlice';
import adminPermissionFeatureReducer from './adminPermissionFeatureSlice';
import adminPermissionReducer from './adminPermissionSlice';
import adminReducer from './adminSlice';
import adminRoleDefaultPermissionsReducer from './adminRoleDefaultPermissionsSlice';
import adminRolesReducer from './adminRolesSlice';
import adminStatusOwnershipsReducer from './adminStatusOwnershipsSlice';
import adminUserOrganizationsReducer from './adminUserOrganizationsSlice';
import adminUserRolePermissionFeaturesReducer from './adminUserRolePermissionFeaturesSlice';
import adminUserRolePermissionsReducer from './adminUserRolePermissionsSlice';
import adminUserRolesReducer from './adminUserRolesSlice';
import adminUsersReducer from './adminUsersSlice';
import agreementReducer from './agreementSlice';
import apiMigrationsReducer from './apiMigrationsSlice';
import appointmentsReducer from './appointmentsSlice';
import bookingHasPackagesReducer from './bookingHasPackagesSlice';
import bookingsReducer from './bookingsSlice';
import bridgesReducer from './bridgesSlice';
import currentUserMigrationsReducer from './currentUserMigrationsSlice';
import customerDetailsReducer from './customerDetailsSlice';
import emailReducer from './emailSlice';
import eventHasPackagesReducer from './eventHasPackagesSlice';
import eventTypeReducer from './eventTypeSlice';
import eventsReducer from './eventsSlice';
import featureMigrationsReducer from './featureMigrationsSlice';
import featuresReducer from './featuresSlice';
import migrationsReducer from './migrationsSlice';
import notificationsReducer from './notificationsSlice';
import organizationAddressesReducer from './organizationAddressesSlice';
import organizationProfilesReducer from './organizationProfilesSlice';
import organizationPublicDetailsReducer from './organizationPublicDetailsSlice';
import organizationReducer from './organizationSlice';
import packageHasServicesReducer from './packageHasServicesSlice';
import packagesReducer from './packagesSlice';
import paymentDetailsReducer from './paymentDetailsSlice';
import paymentStructuresReducer from './paymentStructuresSlice';
import permissionHasFeatureMigrationsReducer from './permissionHasFeatureMigrationsSlice';
import permissionsHasFeaturesReducer from './permissionsHasFeaturesSlice';
import permissionsReducer from './permissionsSlice';
import productReducer from './productSlice';
import productCategoryReducer from './productCategorySlice';
import publicEventTypesReducer from './publicEventTypesSlice';
import publicReducer from './publicSlice';
import publicVouchersReducer from './publicVouchersSlice';
import purchasesReducer from './purchasesSlice'; // New import for purchases slice
import referenceCategoriesReducer from './referenceCategoriesSlice';
import referenceCurrenciesReducer from './referenceCurrenciesSlice';
import referenceEventRelationshipsReducer from './referenceEventRelationshipsSlice';
import referenceFlowsReducer from './referenceFlowsSlice';
import referencesReducer from './referencesSlice';
import referenceStatusesReducer from './referenceStatusesSlice';
import roleDefaultPermissionsReducer from './roleDefaultPermissionsSlice';
import roleMigrationsReducer from './roleMigrationsSlice';
import rolesReducer from './rolesSlice';
import servicesReducer from './servicesSlice';
import spaceHasPackagesReducer from './spaceHasPackagesSlice';
import spacesReducer from './spacesSlice';
import statusesReducer from './statusesSlice';
import statusMigrationsReducer from './statusMigrationsSlice';
import statusOwnershipsMigrationsReducer from './statusOwnershipsMigrationsSlice';
import statusOwnershipsReducer from './statusOwnershipsSlice';
import subscriptionFeaturesReducer from './subscriptionFeaturesSlice';
import subscriptionsReducer from './subscriptionsSlice';
import themeComponentsReducer from './themeComponentsSlice';
import transactionsReducer from './transactionsSlice';
import uploadReducer from './uploadSlice';
import userHasBookingsReducer from './userHasBookingsSlice';
import userOrganizationsReducer from './userOrganizationsSlice';
import userRoleHasPermissionsReducer from './userRoleHasPermissionsSlice';
import userRolePermissionFeaturesReducer from './userRolePermissionFeaturesSlice';
import userRolesReducer from './userRolesSlice';
import usersReducer from './usersSlice';
import voucherHasSpacesReducer from './voucherHasSpacesSlice';
import voucherSpaceHasPackagesReducer from './voucherSpaceHasPackagesSlice';
import vouchersReducer from './vouchersSlice';
import { spaceApi } from '../components/api-services/spaceApi';
import keycloak from "../keycloak";

// Create an object with all admin-related reducers
const adminReducers = {
    admin: adminReducer,
    adminEventType: adminEventTypeReducer,
    adminFeature: adminFeatureReducer,
    adminOrganizations: adminOrganizationReducer,
    adminPaymentStructure: adminPaymentStructureReducer,
    adminPermission: adminPermissionReducer,
    adminPermissionFeature: adminPermissionFeatureReducer,
    adminRoleDefaultPermissions: adminRoleDefaultPermissionsReducer,
    adminRoles: adminRolesReducer,
    adminStatusOwnerships: adminStatusOwnershipsReducer,
    adminUserOrganizations: adminUserOrganizationsReducer,
    adminUserRolePermissionFeatures: adminUserRolePermissionFeaturesReducer,
    adminUserRolePermissions: adminUserRolePermissionsReducer,
    adminUserRoles: adminUserRolesReducer,
    adminUsers: adminUsersReducer,
    agreement: agreementReducer,
};

// Create an object with all non-admin reducers
const nonAdminReducers = {
    accountOnboarding: accountOnboardingSlice,
    accounts: accountsSliceReducer,
    apiMigrations: apiMigrationsReducer,
    appointments: appointmentsReducer,
    bookingHasPackages: bookingHasPackagesReducer,
    bookings: bookingsReducer,
    bridges: bridgesReducer,
    currentUserMigrations: currentUserMigrationsReducer,
    customerDetails: customerDetailsReducer,
    email: emailReducer,
    eventHasPackages: eventHasPackagesReducer,
    events: eventsReducer,
    eventType: eventTypeReducer,
    featureMigrations: featureMigrationsReducer,
    features: featuresReducer,
    migrations: migrationsReducer,
    notifications: notificationsReducer,
    organizationAddresses: organizationAddressesReducer,
    organizationProfiles: organizationProfilesReducer,
    organizationPublicDetails: organizationPublicDetailsReducer,
    organizations: organizationReducer,
    packageHasServices: packageHasServicesReducer,
    packages: packagesReducer,
    paymentDetails: paymentDetailsReducer,
    paymentStructures: paymentStructuresReducer,
    permissionHasFeatureMigrations: permissionHasFeatureMigrationsReducer,
    permissions: permissionsReducer,
    permissionsHasFeatures: permissionsHasFeaturesReducer,
    products: productReducer,
    productCategories: productCategoryReducer,
    public: publicReducer,
    publicEventTypes: publicEventTypesReducer,
    publicVouchers: publicVouchersReducer,
    purchases: purchasesReducer,
    referenceCategories: referenceCategoriesReducer,
    referenceCurrencies: referenceCurrenciesReducer,
    referenceEventRelationships: referenceEventRelationshipsReducer,
    referenceFlows: referenceFlowsReducer,
    references: referencesReducer,
    referenceStatuses: referenceStatusesReducer,
    roleDefaultPermissions: roleDefaultPermissionsReducer,
    roleMigrations: roleMigrationsReducer,
    roles: rolesReducer,
    services: servicesReducer,
    spaceHasPackages: spaceHasPackagesReducer,
    // spaces: spacesReducer,
    [spaceApi.reducerPath]: spaceApi.reducer,
    statuses: statusesReducer,
    statusMigrations: statusMigrationsReducer,
    statusOwnerships: statusOwnershipsReducer,
    statusOwnershipsMigrations: statusOwnershipsMigrationsReducer,
    subscriptionFeatures: subscriptionFeaturesReducer,
    subscriptions: subscriptionsReducer,
    themeComponents: themeComponentsReducer,
    transactions: transactionsReducer,
    uploads: uploadReducer,
    userHasBookings: userHasBookingsReducer,
    userOrganizations: userOrganizationsReducer,
    userRoleHasPermissions: userRoleHasPermissionsReducer,
    userRolePermissionFeatures: userRolePermissionFeaturesReducer,
    userRoles: userRolesReducer,
    users: usersReducer,
    voucherHasSpaces: voucherHasSpacesReducer,
    voucherSpaceHasPackages: voucherSpaceHasPackagesReducer,
    vouchers: vouchersReducer,
};

// Combine all reducers
const allReducers = {
    ...nonAdminReducers,
    ...adminReducers,
};

const createStore = (isAdmin) => {
    const reducers = isAdmin ? allReducers : nonAdminReducers;

    // Remove persistence
    const rootReducer = combineReducers(reducers);

    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
                    ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                    ignoredPaths: ['items.dates'],
                },
                immutableCheck: { warnAfter: 128 },
            }).concat(spaceApi.middleware),
        devTools: process.env.NODE_ENV !== 'production',
    });

    return store;
};

// Check if the user is an admin
const isAdmin = keycloak.hasRealmRole('eventlah-admin');

// Create store and persistor
export const store = createStore(isAdmin);

export default store;
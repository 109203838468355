import React from 'react';
import { Box, Typography, Autocomplete, Chip, TextField } from '@mui/material';
import { styled } from '@mui/system';
import WifiIcon from '@mui/icons-material/Wifi';
import AccessibleIcon from '@mui/icons-material/Accessible';
import MonitorIcon from '@mui/icons-material/Monitor';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import CoffeeIcon from '@mui/icons-material/Coffee';
import MicIcon from '@mui/icons-material/Mic';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VideocamIcon from '@mui/icons-material/Videocam';
import PowerIcon from '@mui/icons-material/Power';
import ChairIcon from '@mui/icons-material/Chair';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SecurityIcon from '@mui/icons-material/Security';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import EventSeatIcon from '@mui/icons-material/EventSeat';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')({
    color: 'red',
    marginLeft: '4px',
});

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: '4px',
    backgroundColor: '#fff',
    border: '1px solid var(--Gray-300, #D0D5DD)',
    '& .MuiChip-label': {
        color: 'var(--Gray-700, #344054)',
        fontSize: '14px',
        fontWeight: 500,
    },
    '& .MuiChip-icon': {
        color: 'var(--Gray-500, #667085)',
    },
    '& .MuiChip-deleteIcon': {
        color: 'var(--Gray-400, #98A2B3)',
        '&:hover': {
            color: 'var(--Gray-500, #667085)',
        },
    },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        padding: '6px',
        '& .MuiAutocomplete-input': {
            padding: '4px 8px !important',
        },
    },
    '& .MuiInputLabel-root': {
        transform: 'translate(14px, 12px) scale(1)',
        '&.MuiInputLabel-shrink': {
            transform: 'translate(20px, -7px) scale(0.60)',
            color: 'var(--gray-300, #B0B0B0)',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--Gray-300, #D0D5DD)',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--Gray-300, #D0D5DD)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--Gray-300, #D0D5DD)',
    },
}));

// Static amenity data with MUI icons
const amenityOptions = [
    { id: 1, label: 'Wi-Fi', icon: WifiIcon },
    { id: 2, label: 'Wheelchair Accessible', icon: AccessibleIcon },
    { id: 3, label: 'Projector/Display', icon: MonitorIcon },
    { id: 4, label: 'Sound System', icon: MusicNoteIcon },
    { id: 5, label: 'Parking Available', icon: LocalParkingIcon },
    { id: 6, label: 'Refreshments', icon: CoffeeIcon },
    { id: 7, label: 'Microphone', icon: MicIcon },
    { id: 8, label: 'Air Conditioning', icon: AcUnitIcon },
    { id: 9, label: 'Recording Equipment', icon: VideocamIcon },
    { id: 10, label: 'Power Outlets', icon: PowerIcon },
    { id: 11, label: 'Furniture', icon: ChairIcon },
    { id: 12, label: 'Catering Service', icon: RestaurantIcon },
    { id: 13, label: 'Security', icon: SecurityIcon },
    { id: 14, label: 'Cleaning Service', icon: CleaningServicesIcon },
    { id: 15, label: 'Theater Seating', icon: EventSeatIcon },
];

const AmenitySettings = ({ amenities, onInputChange }) => {
    const selectedAmenities = amenities
        ? amenityOptions.filter(option =>
            amenities.includes(option.label))
        : [];

    const handleChange = (event, newValue) => {
        const amenityString = newValue
            .map(item => item.label)
            .join(', ');

        onInputChange({
            target: {
                name: 'amenities',
                value: amenityString
            }
        });
    };

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}
             sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Amenities and accessibility <RequiredIndicator>*</RequiredIndicator>
            </TextFieldTitle>
            <StyledAutocomplete
                multiple
                options={amenityOptions}
                value={selectedAmenities}
                onChange={handleChange}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" {...props} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        padding: '8px 16px',
                        '&:hover': {
                            backgroundColor: 'var(--Gray-50, #F9FAFB)',
                        }
                    }}>
                        {option.icon && <option.icon sx={{
                            fontSize: 20,
                            color: 'var(--Gray-500, #667085)'
                        }} />}
                        <Typography sx={{
                            fontSize: '14px',
                            color: 'var(--Gray-900, #101828)',
                        }}>
                            {option.label}
                        </Typography>
                    </Box>
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <StyledChip
                            icon={<option.icon sx={{ fontSize: 18 }} />}
                            label={option.label}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select amenities"
                        placeholder="Choose available amenities"
                        required
                    />
                )}
            />
        </Box>
    );
};

export default AmenitySettings;
import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    Button,
    CircularProgress
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { createShippingRate } from './ShippingRateAPI';

const AddShippingRate = ({ onCancel,onSnackbarChange  }) => {
    const currencies = [
        { id: 2, code: 'MYR' },
        // Add other currencies here if needed
    ];

    const [shippingRateData, setShippingRateData] = useState({
        amount: '',
        currency_id: '2', // Default to MYR
        description: '',
        estimated_shipping_time_min: '',
        estimated_shipping_time_max: '',
        estimated_shipping_time_unit: 'Business Days'
    });

    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setShippingRateData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const dataToSend = {
                ...shippingRateData,
                amount: parseFloat(shippingRateData.amount),
                currency_id: parseInt(shippingRateData.currency_id, 10),
                estimated_shipping_time_min: shippingRateData.estimated_shipping_time_min ? parseInt(shippingRateData.estimated_shipping_time_min, 10) : null,
                estimated_shipping_time_max: shippingRateData.estimated_shipping_time_max ? parseInt(shippingRateData.estimated_shipping_time_max, 10) : null
            };
            delete dataToSend.estimated_shipping_time_unit;

            const response = await createShippingRate(dataToSend);
            console.log('Shipping rate created successfully:', response);
            onSnackbarChange({
                open: true,
                message: 'Shipping rate created successfully!',
                severity: 'success'
            });
            onCancel(); // Close the dialog after successful creation
        } catch (error) {
            console.error('Error creating shipping rate:', error);
            let errorMessage = 'Failed to create shipping rate. Please try again.';
            if (error.response && error.response.data) {
                errorMessage = error.response.data.description || error.response.data.message || errorMessage;
            }
            onSnackbarChange({
                open: true,
                message: errorMessage,
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{display:'flex', justifyContent:'space-between',marginTop:'15px'}}>
                <Box sx={{flexGrow:1,}}>
                    <Typography variant="h6">Amount</Typography>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                required
                                name="amount"
                                value={shippingRateData.amount}
                                onChange={handleInputChange}
                                placeholder="0.00"
                                type="number"
                                inputProps={{ step: "0.01", min: "0" }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth required>
                                <Select
                                    value={shippingRateData.currency_id}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        const currencyId = value === 'MYR' ? '2' : value;
                                        handleInputChange({
                                            target: {
                                                name: 'currency_id',
                                                value: currencyId
                                            }
                                        });
                                    }}
                                    name="currency_id"
                                >
                                    <MenuItem value="MYR">MYR</MenuItem>
                                    {currencies.map((currency) => (
                                        <MenuItem key={currency.id} value={currency.id.toString()}>
                                            {currency.code}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        <Link href="#" color="#f5c542" underline="none">
                            + Add additional currencies
                        </Link>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 5 }}>
                        Use the <Link href="#" color="#f5c542" underline="none">Checkout API</Link> to create custom shipping rates
                    </Typography>
                </Box>

                <Box>
                    <Card sx={{ maxWidth: 300, position: 'relative', overflow: 'visible', boxShadow:'none', borderRadius:'8px 8px 0 0', padding:'15px 10px' }}>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" fontSize='12px' fontWeight='600' color="textSecondary">Subtotal</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography variant="body2" color="textSecondary">--</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{margin:'8px 0 0 0'}}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">Shipping</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography variant="body1">Free</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" fontSize='12px' fontWeight='600' color="textSecondary">Tax</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography variant="body2" color="textSecondary">--</Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{margin:'8px 0 0 0'}}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" fontSize='12px' fontWeight='600' color="textSecondary">Total</Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                            <Typography variant="body2" color="textSecondary">--</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -9,
                                left: 0,
                                width: '100%',
                                height: '10px',
                                background: 'url("data:image/svg+xml;utf8,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%22800%22 height=%2210%22 viewBox=%220 0 800 10%22><polygon points=%220,0 10,10 20,0 30,10 40,0 50,10 60,0 70,10 80,0 90,10 100,0 110,10 120,0 130,10 140,0 150,10 160,0 170,10 180,0 190,10 200,0 210,10 220,0 230,10 240,0 250,10 260,0 270,10 280,0 290,10 300,0 310,10 320,0 330,10 340,0 350,10 360,0 370,10 380,0 390,10 400,0 410,10 420,0 430,10 440,0 450,10 460,0 470,10 480,0 490,10 500,0 510,10 520,0 530,10 540,0 550,10 560,0 570,10 580,0 590,10 600,0 610,10 620,0 630,10 640,0 650,10 660,0 670,10 680,0 690,10 700,0 710,10 720,0 730,10 740,0 750,10 760,0 770,10 780,0 790,10 800,0%22 fill=%22%23ffffff%22/></svg>")',
                                backgroundSize: 'cover',
                            }}
                        />
                    </Card>
                    <Box sx={{ display:'flex', justifyContent: 'center', mt: 3 }}>
                        <Typography variant="body2" color="textSecondary">
                            Customer preview
                            <Tooltip title="Preview of customer order details">
                                <IconButton size="small" sx={{ ml: 1 }}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box sx={{maxWidth:'450px'}}>
                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>Description</Typography>
                <Tooltip title="e.g. Ground shipping">
                    <TextField
                        fullWidth
                        name="description"
                        value={shippingRateData.description}
                        onChange={handleInputChange}
                        placeholder="e.g. Ground shipping"
                    />
                </Tooltip>

                <Box sx={{ mt: 6 }}>
                    <Typography variant="h6" gutterBottom>
                        Estimated shipping time
                        <Typography
                            variant="body2"
                            component="span"
                            sx={{
                                ml: 1,
                                color: 'textSecondary',
                                backgroundColor:'#d9d9d9',
                                padding:'2px 5px',
                                borderRadius:'4px'
                            }}
                        >
                            Optional
                        </Typography>
                    </Typography>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2}>
                            <Typography variant="body1">Between</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <TextField
                                fullWidth
                                name="estimated_shipping_time_min"
                                value={shippingRateData.estimated_shipping_time_min}
                                onChange={handleInputChange}
                                placeholder="min"
                                type="number"
                                inputProps={{ min: "0" }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <FormControl fullWidth>
                                <Select
                                    value={shippingRateData.estimated_shipping_time_unit}
                                    onChange={handleInputChange}
                                    name="estimated_shipping_time_unit"
                                >
                                    <MenuItem value="Business Days">Business Days</MenuItem>
                                    <MenuItem value="Calendar Days">Calendar Days</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Typography variant="body1">and</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                name="estimated_shipping_time_max"
                                value={shippingRateData.estimated_shipping_time_max}
                                onChange={handleInputChange}
                                placeholder="max"
                                type="number"
                                inputProps={{ min: "0" }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Select
                                    value={shippingRateData.estimated_shipping_time_unit}
                                    onChange={handleInputChange}
                                    name="estimated_shipping_time_unit"
                                >
                                    <MenuItem value="Business Days">Business Days</MenuItem>
                                    <MenuItem value="Calendar Days">Calendar Days</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 3 }}>
                <Button variant="cancel" onClick={onCancel} disabled={loading}>
                    Cancel
                </Button>
                <Button type="submit" variant="save" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Create Shipping Rate'}
                </Button>
            </Box>
        </Box>
    );
};

export default AddShippingRate;
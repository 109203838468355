import React from "react";
import CompanyProfile from "../../../shared/profiles/CompanyProfile";
import { Box, Divider, Typography } from "@mui/material";

const BusinessDetail = () => {
    const requiredFields = ['companyName', 'registrationNo', 'companyAddress_1', 'postcode', 'state', 'country'];

    const handleFormSubmitSuccess = () => {
        // Handle successful form submission if needed
        console.log("Form submitted successfully");
    };

    return (
        <>
            <CompanyProfile
                requiredFields={requiredFields}
                onFormSubmitSuccess={handleFormSubmitSuccess}
            />
        </>
    );
};

export default BusinessDetail;
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    Divider,
} from '@mui/material';
import { styled } from "@mui/system";
import { updatePackage } from "../../../redux/packagesSlice";

// Styled components
const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const EditPackageDialog = ({ open, handleClose, packageItem }) => {
    const dispatch = useDispatch();
    const [packageData, setPackageData] = useState({
        product_variant_id: 20,
        name: '',
        description: '',
        amount: '',
        promotion_amount: '',
        currency_id: '',
        organization_id: 1,
    });

    useEffect(() => {
        if (packageItem) {
            setPackageData({
                ...packageData,
                name: packageItem.name || '',
                description: packageItem.description || '',
                amount: packageItem.amount || '',
                promotion_amount: packageItem.promotion_amount || '',
                currency_id: packageItem.currency_id || '',
            });
        }
    }, [packageItem]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPackageData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSave = async () => {
        const id = packageItem ? packageItem.id : null;
        try {
            const resultAction = await dispatch(updatePackage({ id, ...packageData }));
            if (updatePackage.fulfilled.match(resultAction)) {
                handleClose();
            } else {
                console.error('Failed to save package:', resultAction.payload);
            }
        } catch (error) {
            console.error('Failed to save package:', error);
        }
    };


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Edit Package</DialogTitle>
            <DialogContent>
                <Box component="form" noValidate autoComplete="off">
                    {/* Package Name */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Package Name <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter package name"
                            placeholder="Package name"
                            name="name"
                            value={packageData.name}
                            onChange={handleInputChange}
                            required
                            inputProps={{ maxLength: 255 }}
                        />
                    </Box>

                    <Divider />

                    {/* Description */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Description
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter package description"
                            multiline
                            rows={7}
                            name="description"
                            value={packageData.description}
                            onChange={handleInputChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '15px',
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0,
                                    },
                                },
                            }}
                        />
                    </Box>

                    <Divider />

                    {/* Amount */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Amount <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter amount"
                            placeholder="0.00"
                            name="amount"
                            type="number"
                            value={packageData.amount}
                            onChange={handleInputChange}
                            required
                            inputProps={{ min: 0 }}
                        />
                    </Box>

                    <Divider />

                    {/* Promotion Amount */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Promotion Amount
                        </TextFieldTitle>
                        <TextField
                            fullWidth
                            label="Enter promotion amount"
                            placeholder="0.00"
                            name="promotion_amount"
                            type="number"
                            value={packageData.promotion_amount}
                            onChange={handleInputChange}
                            inputProps={{ min: 0 }}
                        />
                    </Box>

                    <Divider />

                    {/* Currency */}
                    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                        <TextFieldTitle>
                            Currency <RequiredIndicator>*</RequiredIndicator>
                        </TextFieldTitle>
                        <Select
                            fullWidth
                            name="currency_id"
                            value={packageData.currency_id}
                            onChange={handleInputChange}
                            required
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select currency</MenuItem>
                            <MenuItem value={1}>USD</MenuItem>
                            <MenuItem value={2}>MYR</MenuItem>
                            {/* Add more currency options as needed */}
                        </Select>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="cancel">
                    Cancel
                </Button>
                <Button onClick={handleSave} variant="save">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPackageDialog;
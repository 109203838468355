import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Button, Grid, Paper, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { fetchCoupons } from '../../../redux/couponSlice';

const VoucherCard = () => {
    const dispatch = useDispatch();
    const couponState = useSelector(state => state.coupons);
    const [expanded, setExpanded] = useState(false);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [page, setPage] = useState(0);

    const pageSize = 10; // You can adjust this value as needed

    // Safely access the coupon state with fallback values
    const coupons = couponState?.coupons.coupons || [];
    const status = couponState?.status || 'idle';
    const error = couponState?.error || null;
    const totalCount = couponState?.totalCount || 0;

    useEffect(() => {
        loadCoupons();
    }, []);

    useEffect(() => {
        if (page > 0) {
            loadCoupons();
        }
    }, [page]);

    const loadCoupons = () => {
        dispatch(fetchCoupons({ page, pageSize, searchQuery: '' }));
    };

    const handleExpand = () => {
        setExpanded(!expanded);
    };

    const handleSelectCoupon = (id) => {
        if (selectedCoupons.includes(id)) {
            setSelectedCoupons(selectedCoupons.filter(couponId => couponId !== id));
        } else {
            setSelectedCoupons([...selectedCoupons, id]);
        }
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const hasMore = coupons.length < totalCount;

    return (
        <Box sx={{ padding: { xs: 0, sm: '0 32px' } }}>
            <Accordion
                expanded={expanded}
                onChange={handleExpand}
                sx={{ borderRadius: '12px', overflow: 'hidden' }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <LocalOfferIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body1">Select Coupon</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    {error && <Typography color="error">{error}</Typography>}
                    {coupons.map((coupon) => (
                        <Paper
                            key={coupon.id}
                            onClick={() => handleSelectCoupon(coupon.id)}
                            sx={{
                                marginBottom: '5px',
                                padding: { xs: '8px', sm: '10px' },
                                borderRadius: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                backgroundColor: selectedCoupons.includes(coupon.id) ? '#ffeb3b' : '',
                            }}
                        >
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body2" fontWeight="bold">
                                        {coupon.discount}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {coupon.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Button
                                        variant={selectedCoupons.includes(coupon.id) ? "outlined" : "contained"}
                                        color={selectedCoupons.includes(coupon.id) ? "primary" : "error"}
                                        sx={{ borderRadius: '20px', padding: { xs: '4px 8px', sm: '6px 12px' } }}
                                    >
                                        <Typography variant="caption">
                                            {selectedCoupons.includes(coupon.id) ? "SELECTED" : "Claim"}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                    {status === 'loading' && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}
                    {hasMore && status !== 'loading' && (
                        <Button onClick={handleLoadMore} fullWidth sx={{ mt: 2 }}>
                            Load More
                        </Button>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default VoucherCard;
import { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    FormControlLabel,
    Checkbox,
    TextField,
    FormGroup,
    FormControl,
    styled
} from '@mui/material';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0,
    marginBottom: '24px',
    '& .MuiSwitch-root': {
        marginLeft: 8,
    }
});

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const SectionTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#101828',
    marginTop: '24px',
    marginBottom: '16px'
});

const StyledCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    }
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#F9FAFB',
    }
});

const SpecialOccasions = () => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [eventTypes, setEventTypes] = useState({
        birthday: false,
        corporate: false,
        anniversaries: false,
        association: false
    });
    const [formFields, setFormFields] = useState({
        eventType: true,
        guestCount: true,
        preferredDate: true,
        budgetRange: false,
        catering: false,
        setup: false
    });
    const [autoReplyMessage, setAutoReplyMessage] = useState(
        "Thank you for your interest. We'll get back to you within 24 hours to discuss your event details."
    );

    const handleEventTypeChange = (event) => {
        setEventTypes({
            ...eventTypes,
            [event.target.name]: event.target.checked
        });
    };

    const handleFormFieldChange = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.checked
        });
    };

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Special Occasions
            </TextFieldTitle>

            <Box sx={{ width: '100%' }}>
                <StyledFormControlLabel
                    control={
                        <Switch
                            checked={isEnabled}
                            onChange={(e) => setIsEnabled(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={
                        <LabelContent>
                            <Typography className="heading">Special Occasions</Typography>
                            <Typography className="subtext">Allow customers to request private events and special occasions</Typography>
                        </LabelContent>
                    }
                />

                {isEnabled && (
                    <>
                        <SectionTitle>Available Event Types</SectionTitle>
                        <FormControl component="fieldset" sx={{ width: '100%' }}>
                            <FormGroup>
                                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                                    <FormControlLabel
                                        control={<StyledCheckbox checked={eventTypes.birthday} onChange={handleEventTypeChange} name="birthday" />}
                                        label="Birthday Parties"
                                    />
                                    <FormControlLabel
                                        control={<StyledCheckbox checked={eventTypes.anniversaries} onChange={handleEventTypeChange} name="anniversaries" />}
                                        label="Anniversaries"
                                    />
                                    <FormControlLabel
                                        control={<StyledCheckbox checked={eventTypes.corporate} onChange={handleEventTypeChange} name="corporate" />}
                                        label="Corporate Events"
                                    />
                                    <FormControlLabel
                                        control={<StyledCheckbox checked={eventTypes.association} onChange={handleEventTypeChange} name="association" />}
                                        label="Association Meetings"
                                    />
                                </Box>
                            </FormGroup>
                        </FormControl>

                        <SectionTitle>Request Form Fields</SectionTitle>
                        <FormGroup>
                            <FormControlLabel
                                control={<StyledCheckbox checked={formFields.eventType} onChange={handleFormFieldChange} name="eventType" disabled />}
                                label="Event Type (Required)"
                            />
                            <FormControlLabel
                                control={<StyledCheckbox checked={formFields.guestCount} onChange={handleFormFieldChange} name="guestCount" disabled />}
                                label="Expected Guest Count (Required)"
                            />
                            <FormControlLabel
                                control={<StyledCheckbox checked={formFields.preferredDate} onChange={handleFormFieldChange} name="preferredDate" disabled />}
                                label="Preferred Date (Required)"
                            />
                            <FormControlLabel
                                control={<StyledCheckbox checked={formFields.budgetRange} onChange={handleFormFieldChange} name="budgetRange" />}
                                label="Budget Range"
                            />
                            <FormControlLabel
                                control={<StyledCheckbox checked={formFields.catering} onChange={handleFormFieldChange} name="catering" />}
                                label="Catering Requirements"
                            />
                            <FormControlLabel
                                control={<StyledCheckbox checked={formFields.setup} onChange={handleFormFieldChange} name="setup" />}
                                label="Setup Preferences"
                            />
                        </FormGroup>

                        <SectionTitle>Response Settings</SectionTitle>
                        <Typography sx={{ fontSize: '14px', color: '#344054', mb: 1 }}>
                            Auto-reply Message
                        </Typography>
                        <StyledTextField
                            fullWidth
                            multiline
                            rows={3}
                            value={autoReplyMessage}
                            onChange={(e) => setAutoReplyMessage(e.target.value)}
                            placeholder="Enter auto-reply message"
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default SpecialOccasions;
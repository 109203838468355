import { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Button,
    TextField,
    IconButton,
    Modal,
} from '@mui/material';
import {
    KeyboardArrowLeft as ArrowLeftIcon,
    KeyboardArrowRight as ArrowRightIcon,
    ZoomIn as ZoomInIcon,
    Close as CloseIcon,
    CameraAlt as CameraIcon,
} from '@mui/icons-material';

const VariantPreview = ({
                            variants,
                            selectedVariantIndexes,
                            handleOptionSelect,
                            quantity,
                            handleQuantityChange,
                            calculatePrice,
                            product
                        }) => {
    const defaultImage = product?.product_images?.[0]?.image_url || '/1.jpeg';
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [allImages, setAllImages] = useState([]);
    const [showZoomHint, setShowZoomHint] = useState(false);
    const [zoomOpen, setZoomOpen] = useState(false);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        const getAllImages = () => {
            const uniqueImages = new Set();

            product?.product_images?.forEach(img => {
                if (img.image_url) {
                    uniqueImages.add(img.image_url);
                }
            });

            product?.product_variants?.forEach(variant => {
                if (variant.variant_image) {
                    uniqueImages.add(variant.variant_image);
                }
            });

            return Array.from(uniqueImages);
        };

        const images = getAllImages();
        setAllImages(images);
    }, [product]);

    const getCurrentVariantImage = () => {
        return allImages[selectedImageIndex] || defaultImage;
    };

    const findVariantByImage = (imageUrl) => {
        return product?.product_variants?.find(variant => variant.variant_image === imageUrl);
    };

    const handleImageSelect = (index) => {
        setSelectedImageIndex(index);
        const selectedImage = allImages[index];
        const matchingVariant = findVariantByImage(selectedImage);

        if (matchingVariant) {
            const variantIndex = variants.findIndex(v => v.optionName === matchingVariant.variant_name);
            if (variantIndex !== -1) {
                const optionIndex = variants[variantIndex].optionValues.findIndex(
                    value => value === matchingVariant.variant_value
                );
                if (optionIndex !== -1) {
                    handleOptionSelect(variantIndex, optionIndex);
                }
            }
        }
    };

    const handleVariantButtonClick = (variantIndex, optionIndex, value) => {
        handleOptionSelect(variantIndex, optionIndex);

        const matchingVariant = product.product_variants.find(v =>
            v.variant_name === variants[variantIndex].optionName &&
            v.variant_value === value
        );

        if (matchingVariant?.variant_image) {
            const imageIndex = allImages.findIndex(img => img === matchingVariant.variant_image);
            if (imageIndex !== -1) {
                setSelectedImageIndex(imageIndex);
            }
        }
    };

    const handleZoomOpen = () => {
        setZoomOpen(true);
    };

    const handleZoomClose = () => {
        setZoomOpen(false);
    };

    const visibleThumbnails = allImages.slice(startIndex, startIndex + 5);
    const paddedThumbnails = [...visibleThumbnails];
    while (paddedThumbnails.length < 5) {
        paddedThumbnails.push(null);
    }

    const ProductPlaceholder = () => (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f5f5f5',
                border: '1px dashed #bdbdbd',
                borderRadius: '4px',
                gap: 1,
            }}
        >
            <CameraIcon sx={{ color: '#bdbdbd', fontSize: '24px' }} />
            <Typography variant="caption" sx={{ color: '#757575' }}>
                No image
            </Typography>
        </Box>
    );

    return (
        <Box sx={{ width: '50%', maxWidth: '700px', paddingLeft: 10 }}>
            <Typography sx={{ fontSize: '22px', fontWeight: '500', mb: 1 }}>
                Preview
            </Typography>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: '12px' }}>
                <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {product?.name || 'Product Name'}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {/* Main Image */}
                            <Box
                                sx={{
                                    position: 'relative',
                                    '&:hover .zoom-hint': {
                                        opacity: 1,
                                    },
                                }}
                                onMouseEnter={() => setShowZoomHint(true)}
                                onMouseLeave={() => setShowZoomHint(false)}
                            >
                                <Box
                                    component="img"
                                    src={getCurrentVariantImage()}
                                    alt="Product Variant"
                                    onClick={handleZoomOpen}
                                    sx={{
                                        width: '100%',
                                        height: '400px',
                                        objectFit: 'contain',
                                        borderRadius: '8px',
                                        backgroundColor: '#fafafa',
                                        cursor: 'zoom-in',
                                    }}
                                    onError={(e) => {
                                        e.target.src = defaultImage;
                                    }}
                                />
                                <Box
                                    className="zoom-hint"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        color: 'white',
                                        padding: '8px 16px',
                                        borderRadius: '20px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <ZoomInIcon sx={{ fontSize: 20 }} />
                                    <Typography variant="body2">Click to zoom</Typography>
                                </Box>
                            </Box>

                            {/* Horizontal Thumbnail Carousel */}
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                width: '100%',
                            }}>
                                <IconButton
                                    size="small"
                                    onClick={() => setStartIndex(prev => Math.max(0, prev - 1))}
                                    disabled={startIndex === 0}
                                    sx={{
                                        backgroundColor: 'white',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                        },
                                    }}
                                >
                                    <ArrowLeftIcon />
                                </IconButton>

                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(5, 1fr)',
                                    gap: 1,
                                    flex: 1,
                                }}>
                                    {paddedThumbnails.map((image, index) => (
                                        <Box
                                            key={`${startIndex + index}-${image || 'empty'}`}
                                            sx={{
                                                position: 'relative',
                                                aspectRatio: '1',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                                cursor: image ? 'pointer' : 'default',
                                                border: selectedImageIndex === (startIndex + index)
                                                    ? '2px solid #1976d2'
                                                    : '1px solid #e0e0e0',
                                                transition: 'all 0.2s ease',
                                                '&:hover': image ? {
                                                    borderColor: '#1976d2',
                                                    transform: 'translateY(-1px)',
                                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                                } : {},
                                            }}
                                            onClick={() => image && handleImageSelect(startIndex + index)}
                                        >
                                            {image ? (
                                                <Box
                                                    component="img"
                                                    src={image}
                                                    alt={`Product ${startIndex + index + 1}`}
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                    onError={(e) => {
                                                        e.target.src = defaultImage;
                                                    }}
                                                />
                                            ) : (
                                                <ProductPlaceholder />
                                            )}
                                        </Box>
                                    ))}
                                </Box>

                                <IconButton
                                    size="small"
                                    onClick={() => setStartIndex(prev => Math.min(prev + 1, Math.max(0, allImages.length - 5)))}
                                    disabled={startIndex >= Math.max(0, allImages.length - 5)}
                                    sx={{
                                        backgroundColor: 'white',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                        },
                                    }}
                                >
                                    <ArrowRightIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
                            RM {calculatePrice()}
                        </Typography>
                        {variants.map((variant, variantIndex) => (
                            <Box key={variant.id} sx={{ mb: 2 }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {variant.optionName}
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 1,
                                    mb: 2,
                                    pb: 1,
                                }}>
                                    {variant.optionValues.map((value, optionIndex) => {
                                        const isSelected = optionIndex === (selectedVariantIndexes[variantIndex] || 0);
                                        const matchingVariant = product.product_variants.find(v =>
                                            v.variant_name === variant.optionName &&
                                            v.variant_value === value
                                        );

                                        return (
                                            <Button
                                                key={optionIndex}
                                                variant={isSelected ? 'save' : 'cancel'}
                                                sx={{
                                                    borderRadius: '20px',
                                                    whiteSpace: 'nowrap',
                                                    minWidth: 'fit-content',
                                                    px: 2,
                                                    py: 0.5,
                                                    marginBottom: '8px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                }}
                                                onClick={() => handleVariantButtonClick(variantIndex, optionIndex, value)}
                                            >
                                                {matchingVariant?.variant_image && (
                                                    <Box
                                                        sx={{
                                                            width: '24px',
                                                            height: '24px',
                                                            borderRadius: '50%',
                                                            overflow: 'hidden',
                                                            border: '1px solid rgba(0, 0, 0, 0.12)',
                                                        }}
                                                    >
                                                        <Box
                                                            component="img"
                                                            src={matchingVariant.variant_image}
                                                            alt={value}
                                                            sx={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                {value}
                                            </Button>
                                        );
                                    })}
                                </Box>
                            </Box>
                        ))}
                        <Typography variant="subtitle1" gutterBottom>
                            Quantity
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Button
                                variant="cancel"
                                sx={{ minWidth: '40px' }}
                                onClick={() => handleQuantityChange(-1)}
                            >
                                -
                            </Button>
                            <TextField
                                value={quantity}
                                inputProps={{
                                    style: { textAlign: 'center' },
                                    min: 1
                                }}
                                sx={{ mx: 1, width: '60px' }}
                                onChange={(e) => handleQuantityChange(parseInt(e.target.value) || 1)}
                            />
                            <Button
                                variant="cancel"
                                sx={{ minWidth: '40px' }}
                                onClick={() => handleQuantityChange(1)}
                            >
                                +
                            </Button>
                        </Box>
                        <Button variant="save" fullWidth sx={{ mb: 2 }}>
                            Add to cart
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Zoom Modal */}
            <Modal
                open={zoomOpen}
                onClose={handleZoomClose}
                aria-labelledby="zoom-modal"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                }}
            >
                <Box sx={{ position: 'relative', maxWidth: '90vw', maxHeight: '90vh' }}>
                    <IconButton
                        onClick={handleZoomClose}
                        sx={{
                            position: 'absolute',
                            top: -40,
                            right: 0,
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box
                        component="img"
                        src={getCurrentVariantImage()}
                        alt={product?.name}
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            maxHeight: '90vh',
                            backgroundColor: 'transparent',
                        }}
                    />
                </Box>
            </Modal>
        </Box>
    );
};

export default VariantPreview;
import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    LinearProgress,
    Alert,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Dialog,
    InputAdornment,
    IconButton
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordStrengthIndicator = ({ password }) => {
    const getPasswordStrength = (password) => {
        if (password.length === 0) return 0;
        let strength = 0;

        // Length check
        if (password.length >= 8) strength += 20;
        if (password.length >= 12) strength += 10;

        // Character type checks
        if (password.match(/[a-z]+/)) strength += 10;
        if (password.match(/[A-Z]+/)) strength += 20;
        if (password.match(/[0-9]+/)) strength += 20;
        if (password.match(/[^a-zA-Z0-9]+/)) strength += 20; // Special characters

        return Math.min(strength, 100); // Ensure the strength doesn't exceed 100
    };

    const strength = getPasswordStrength(password);

    const getStrengthLabel = (strength) => {
        if (strength === 0) return 'Very Weak';
        if (strength <= 20) return 'Weak';
        if (strength <= 40) return 'Fair';
        if (strength <= 60) return 'Good';
        if (strength <= 80) return 'Strong';
        return 'Very Strong';
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
                Password Strength: {getStrengthLabel(strength)}
            </Typography>
            <LinearProgress
                variant="determinate"
                value={strength}
                sx={{
                    height: 10,
                    borderRadius: 5,
                    '& .MuiLinearProgress-bar': {
                        backgroundColor:
                            strength <= 20 ? '#f44336' :
                                strength <= 40 ? '#ff9800' :
                                    strength <= 60 ? '#ffc107' :
                                        strength <= 80 ? '#8bc34a' : '#4caf50'
                    }
                }}
            />
            <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Use 8+ characters with a mix of letters, numbers & symbols
            </Typography>
        </Box>
    );
};

const ResetPassword = ({ open, onClose }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        if (password !== confirmPassword) {
            setError("Passwords don't match.");
            setSnackbar({
                open: true,
                message: "Passwords don't match.",
                severity: 'error'
            });
            return;
        }

        if (password.length < 8) {
            setError("Password must be at least 8 characters long.");
            setSnackbar({
                open: true,
                message: "Password must be at least 8 characters long.",
                severity: 'error'
            });
            return;
        }

        // Here you would typically make an API call to reset the password
        // we'll just simulate a successful reset
        setSuccess(true);
        setSnackbar({
            open: true,
            message: "Password successfully reset. You can now log in with your new password.",
            severity: 'success'
        });
        setTimeout(() => {
            onClose();
        }, 2000);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '500px',
                }
            }}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <LockResetIcon sx={{ mr: 1, fontSize: 40, color: 'primary.main' }} />
                <Typography variant="h5">
                    Reset Your Password
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <PasswordStrengthIndicator password={password} />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Confirm New Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
                    {success && (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Password successfully reset. You can now log in with your new password.
                        </Alert>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ mt: 2,}}>
                <Button onClick={onClose} variant='cancel' sx={{ mr: 1 }}>Cancel</Button>
                <Button onClick={handleSubmit} variant="save">
                    Reset Password
                </Button>
            </DialogActions>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default ResetPassword;
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Skeleton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const LoadingContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    height: '56px', // Standard height of Material-UI select
});

const PulsatingBackground = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '4px',
    overflow: 'hidden',
    '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `linear-gradient(
            90deg,
            ${theme.palette.background.paper} 0%,
            ${theme.palette.action.hover} 50%,
            ${theme.palette.background.paper} 100%
        )`,
        animation: 'pulse 1.5s infinite',
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'translateX(-100%)',
        },
        '100%': {
            transform: 'translateX(100%)',
        },
    },
}));

const LoadingSelect = () => (
    <LoadingContainer>
        <PulsatingBackground />
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, padding: '8px' }}>
            <Skeleton
                variant="text"
                width="30%"
                height={16}
                sx={{ position: 'absolute', top: '4px', left: '8px' }}
            />
            <Skeleton
                variant="text"
                width="90%"
                height={24}
                sx={{ position: 'absolute', bottom: '8px', left: '8px' }}
            />
        </Box>
    </LoadingContainer>
);

const SharedSelect = ({
                          label,
                          value,
                          onChange,
                          options,
                          createNewOption,
                          fetchOptions,
                          selectSelector,
                          createSelector,
                          errorSelector,
                          clearNewlyCreatedSelector,
                          sx
                      }) => {
    const dispatch = useDispatch();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newItemData, setNewItemData] = useState({});
    const [loadingNewItem, setLoadingNewItem] = useState(false);

    const selectedOptions = useSelector(selectSelector) || [];
    const newlyCreatedItem = useSelector(createSelector);
    const error = useSelector(errorSelector);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (fetchOptions) {
            setIsLoading(true);
            dispatch(fetchOptions())
                .then(() => {
                    setTimeout(() => setIsLoading(false), 300); // Minimum loading time for smooth UX
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error("Error fetching options:", error);
                });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, fetchOptions, label]);

    useEffect(() => {
        if (newlyCreatedItem) {
            setLoadingNewItem(false);
            onChange(newlyCreatedItem.id);
            setIsDialogOpen(false);
            dispatch(clearNewlyCreatedSelector());
        }
    }, [newlyCreatedItem, onChange, dispatch, clearNewlyCreatedSelector]);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setNewItemData({});
    };

    const handleCreateNewItem = () => {
        setLoadingNewItem(true);
        dispatch(createNewOption(newItemData));
    };

    const handleNewItemDataChange = (e) => {
        const { name, value } = e.target;
        setNewItemData(prev => ({ ...prev, [name]: value }));
    };

    if (isLoading) {
        return <LoadingSelect />;
    }

    return (
        <FormControl fullWidth sx={sx}>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(e) => onChange(e.target.value)}
                label={label}
            >
                {selectedOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
                <MenuItem
                    value="create_new"
                    onClick={handleOpenDialog}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'primary.lighter',
                        }
                    }}
                >
                    <AddIcon sx={{ mr: 1 }} /> Create New {label}
                </MenuItem>
            </Select>

            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Create New {label}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        {options.map((option) => (
                            <TextField
                                key={option.name}
                                label={option.label}
                                name={option.name}
                                value={newItemData[option.name] || ''}
                                onChange={handleNewItemDataChange}
                                fullWidth
                                disabled={loadingNewItem}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 2.5 }}>
                    <Button
                        onClick={handleCloseDialog}
                        disabled={loadingNewItem}
                        sx={{ mr: 1 }}
                        variant="cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateNewItem}
                        variant="save"
                        disabled={loadingNewItem}
                        sx={{ minWidth: 100 }}
                    >
                        {loadingNewItem ? 'Creating...' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormControl>
    );
};

export default SharedSelect;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    IconButton,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    Skeleton
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Delete as DeleteIcon, Edit as EditIcon, Visibility as ViewIcon } from '@mui/icons-material';
import AddIcon from "@mui/icons-material/Add";
import {
    fetchProducts,
    deleteProduct
} from '../../../../redux/productSlice';
import SelectedProductsDialog from "./CreatePackagesDialog";

const images = [
    "/1.jpeg",
    "/2.jpeg",
    "/3.jpeg",
    "/4.jpeg",
    "/5.jpeg",
    "/6.jpeg",
];

const ProductCatalog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { products, status, error, totalCount } = useSelector(state => state.products);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const [confirmDialog, setConfirmDialog] = useState({ open: false, productId: null });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedProductsDialogOpen, setSelectedProductsDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchProducts({ ...paginationModel, searchQuery }));
    }, [dispatch, paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleAddClick = () => {
        navigate('/settings/product-catalog/add-product');
    };

    const handleView = (id) => {
        navigate(`/settings/product-catalog/${id}/details`);
    };

    const handleEdit = (product) => {
        navigate('/settings/product-catalog/add-product', { state: { product } });
    };

    const handleDeleteClick = (id) => {
        setConfirmDialog({ open: true, productId: id });
    };

    const handleConfirmDelete = async () => {
        try {
            await dispatch(deleteProduct(confirmDialog.productId)).unwrap();
            setSnackbar({ open: true, message: 'Product deleted successfully', severity: 'success' });
            dispatch(fetchProducts(paginationModel));
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to delete product: ' + error.message, severity: 'error' });
        } finally {
            setConfirmDialog({ open: false, productId: null });
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectedRows(newSelectionModel);
    };

    const handleMultiSelectAction = () => {
        setSelectedProductsDialogOpen(true);
    };

    const handleCloseSelectedProductsDialog = () => {
        setSelectedProductsDialogOpen(false);
    };

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const columns = [
        {
            field: 'image',
            headerName: '',
            flex: 0.4,
            minWidth: 80,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '60px',
                            height: '45px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            objectFit: 'cover',
                        }}
                        alt="Product Image"
                        src={
                            params.row.product_images && params.row.product_images.length > 0
                                ? params.row.product_images[0].image_url
                                : images[0]
                        }
                    />
                </Box>

            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2" noWrap>{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'price',
            headerName: 'Pricing',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">RM{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => handleView(params.row.id)}>
                        <ViewIcon/>
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            )
        }
    ];

    return (
        <Box sx={{ display:'flex' }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Product Catalog</Typography>
                        <Typography variant='h2'>Manage your products, categories, and inventory.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button variant="save" onClick={handleAddClick} startIcon={<AddIcon />}>
                            Add New Product
                        </Button>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 24px' }}>
                        <Typography
                            sx={{
                                color: "var(--Gray-900, #101828)",
                                fontFamily: "Inter",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "28px",
                            }}
                        >
                            All Products
                        </Typography>
                        {selectedRows.length > 0 && (
                            <Button
                                variant="save"
                                onClick={handleMultiSelectAction}
                            >
                                Create Package
                            </Button>
                        )}
                    </Box>

                    {status === 'loading' ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={products}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={status === 'loading'}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            onRowSelectionModelChange={handleSelectionModelChange}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            {/* Confirm Delete Dialog */}
            <Dialog
                open={confirmDialog.open}
                onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this product? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} variant="cancel">Cancel</Button>
                    <Button onClick={handleConfirmDelete} variant="save" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* SelectedProductsDialog */}
            <SelectedProductsDialog
                open={selectedProductsDialogOpen}
                onClose={handleCloseSelectedProductsDialog}
                selectedProducts={products.filter(product => selectedRows.includes(product.id))}
            />

            {/* Snackbar for notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductCatalog;
import React from 'react';
import { Grid, Typography, TextField, Button, Box } from '@mui/material';

const CustomerInfoForm = ({ customerInfo, handleCustomerInfoChange, handleSaveCustomerInfo }) => {
    const formFields = [
        { label: "Your First Name", name: "firstName" },
        { label: "Your Last Name", name: "lastName" },
        { label: "Mobile Phone", name: "mobilePhone" },
        { label: "Email", name: "email" },
    ];

    return (
        <Box sx={{ width: '100%' }}>
            {formFields.map((field) => (
                <Grid container spacing={2} alignItems="center" key={field.name} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={3}>
                        <Typography variant="body1" align="right">{field.label}:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            fullWidth
                            name={field.name}
                            value={customerInfo[field.name]}
                            onChange={handleCustomerInfoChange}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                </Grid>
            ))}
            {/*<Grid container justifyContent="flex-end">*/}
            {/*    <Grid item xs={12} sm={9}>*/}
            {/*        <Button*/}
            {/*            fullWidth*/}
            {/*            variant="save"*/}
            {/*            onClick={handleSaveCustomerInfo}*/}
            {/*        >*/}
            {/*            Save*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Box>
    );
};

export default CustomerInfoForm;
import { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    Button,
    Divider,
    styled,
    TextField,
    MenuItem,
    FormControl,
    InputAdornment,
} from '@mui/material';

const SettingRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: '16px 0',
    width: '100%',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ConstrainedContainer = styled(Box)({
    maxWidth: '320px',
    width: '100%'
});

const FieldContainer = styled(Box)({
    width: '100%'
});

const PayoutSettings = () => {
    const [settings, setSettings] = useState({
        payoutFrequency: 'monthly',
        minimumPayout: '100',
        paymentMethod: 'bank-transfer',
        autoApprove: false,
    });

    const [hasChanges, setHasChanges] = useState(false);

    const handleChange = (field) => (event) => {
        setSettings(prev => ({
            ...prev,
            [field]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        }));
        setHasChanges(true);
    };

    return (
        <Box>
            <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6" fontWeight={600} gutterBottom>
                    Payout Settings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Configure how and when agents receive their payments
                </Typography>
            </Box>

            <Divider />

            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Payout Frequency
                    </Typography>
                    <ConstrainedContainer>
                        <FormControl fullWidth>
                            <TextField
                                select
                                value={settings.payoutFrequency}
                                onChange={handleChange('payoutFrequency')}
                                variant="outlined"
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="weekly">Weekly</MenuItem>
                                <MenuItem value="biweekly">Bi-weekly</MenuItem>
                                <MenuItem value="monthly">Monthly</MenuItem>
                                <MenuItem value="quarterly">Quarterly</MenuItem>
                            </TextField>
                        </FormControl>
                    </ConstrainedContainer>
                </FieldContainer>
            </SettingRow>

            <Divider />

            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Minimum Payout Amount
                    </Typography>
                    <ConstrainedContainer>
                        <FormControl fullWidth>
                            <TextField
                                value={settings.minimumPayout}
                                onChange={handleChange('minimumPayout')}
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                }}
                            />
                        </FormControl>
                    </ConstrainedContainer>
                </FieldContainer>
            </SettingRow>

            <Divider />

            <SettingRow>
                <FieldContainer>
                    <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                        Payment Method
                    </Typography>
                    <ConstrainedContainer>
                        <FormControl fullWidth>
                            <TextField
                                select
                                value={settings.paymentMethod}
                                onChange={handleChange('paymentMethod')}
                                variant="outlined"
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="bank-transfer">Bank Transfer</MenuItem>
                                <MenuItem value="senangPay">senangPay</MenuItem>
                                <MenuItem value="paypal">PayPal</MenuItem>
                                <MenuItem value="stripe">Stripe</MenuItem>
                                <MenuItem value="wise">Wise</MenuItem>
                            </TextField>
                        </FormControl>
                    </ConstrainedContainer>
                </FieldContainer>
            </SettingRow>

            <Divider />

            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '16px 0',
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!hasChanges}
                >
                    Save Settings
                </Button>
            </Box>
        </Box>
    );
};

export default PayoutSettings;
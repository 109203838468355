import { useState } from 'react';
import { Box, Typography, Switch, styled } from '@mui/material';
import PeakHoursSection from './PeakHoursSection';
import BulkBookingDiscounts from './BulkBookingDiscounts';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const StyledFormControlLabel = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '24px',
});

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const PricingRules = ({ onChange }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [peakHourRules, setPeakHourRules] = useState([
        {
            id: '1',
            days: 'weekdays',
            timeStart: '',
            timeEnd: '',
            rateAdjustment: '20'
        }
    ]);
    const [discountRules, setDiscountRules] = useState([
        {
            id: '1',
            comparison: 'more-than',
            duration: '4',
            discount: '10'
        }
    ]);

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Pricing Rules
            </TextFieldTitle>

            <Box sx={{ width: '100%' }}>
                <StyledFormControlLabel>
                    <LabelContent>
                        <Typography className="heading">Pricing Rules</Typography>
                        <Typography className="subtext">Configure special pricing rules and bulk booking discounts</Typography>
                    </LabelContent>
                    <Switch
                        checked={isEnabled}
                        onChange={(e) => setIsEnabled(e.target.checked)}
                        color="primary"
                    />
                </StyledFormControlLabel>

                {isEnabled && (
                    <>
                        <PeakHoursSection
                            peakHourRules={peakHourRules}
                            setPeakHourRules={setPeakHourRules}
                        />
                        <BulkBookingDiscounts
                            discountRules={discountRules}
                            setDiscountRules={setDiscountRules}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default PricingRules;
import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stepper,
    Step,
    StepLabel,
    MenuItem,
    Select,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import  { PackageList, PackageServices1 } from "./PackageList";
import {packages } from "./PackageList";

const steps = ['Package Details', 'Package Services', 'Package PricingTable'];
const subSteps = [
    'Setup your details for your package',
    'Setup preferred services into your package',
    'Setup pricing for your package',
];

const SearchBar = ({ handleDialogOpen }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow:1 }}>
        <Box sx={{display: 'flex'}}>
            <Button
                variant='cancel'
                sx={{
                    borderTopRightRadius: '0px', // optional to make sure the right side remains as desired
                    borderBottomRightRadius: '0px', // optional to make sure the right side remains as desired
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    height: '34px',
                }}
            >
                Active Packages
            </Button>
            <Button
                variant='cancel'
                sx={{
                    borderTopLeftRadius: '0', // or any desired value
                    borderBottomLeftRadius: '0', // or any desired value
                    color: 'var(--dark-50, #71717A)', // Text color
                    fontSize: '14px', // Font size
                    fontStyle: 'normal', // Font style
                    fontWeight: 500, // Font weight
                    whiteSpace: 'nowrap',
                    lineHeight: '20px', // Line height
                    background: 'var(--gray-30, #F7F7F7)',
                }}
            >
                Archived Packages
            </Button>
        </Box>
        <TextField
            variant="outlined"
            placeholder="Search for package"
            InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: 1 }} />,
            }}
            sx={{ mx:'48px', flexGrow:1 }}
        />
        <Box display='flex'>
            <Button variant="cancel" startIcon={<FilterListIcon />} sx={{ marginRight: 2, height: '42px', }}>
                Filters
            </Button>
            <Button variant="cancel" startIcon={<AddIcon />} onClick={handleDialogOpen} sx={{whiteSpace: 'nowrap', height: '42px',}}>
                Add Packages
            </Button>
        </Box>
    </Box>
);

const StepContent = ({ activeStep, serviceOption, setServiceOption, selectedServices, totalServicesCost, profitMargin, setProfitMargin, packagePrice }) => {
    switch (activeStep) {
        case 0:
            return (
                <Box >
                    <Box sx={{paddingTop:'24px'}}>
                        <Typography
                            sx={{
                                color: 'var(--Gray-700, #344054)', // Text color
                                fontFamily: 'Inter', // Font family
                                fontSize: '14px', // Font size
                                fontStyle: 'normal', // Font style
                                fontWeight: 500, // Font weight
                                lineHeight: '20px', // Line height
                                paddingBottom: '8px',
                            }}
                        >
                            Title of Package
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            defaultValue="Package Platinum"
                        />
                    </Box>

                    <Box sx={{paddingTop:'24px'}}>
                        <Typography
                            sx={{
                                color: 'var(--Gray-700, #344054)', // Text color
                                fontFamily: 'Inter', // Font family
                                fontSize: '14px', // Font size
                                fontStyle: 'normal', // Font style
                                fontWeight: 500, // Font weight
                                lineHeight: '20px', // Line height
                                paddingBottom: '8px',
                            }}
                        >
                            Description of package
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={7}
                            variant="outlined"
                            defaultValue="When it comes to the best hi-tea catering services in Kuala Lumpur, look no further than our company. We specialize in catering for both corporate and private events, offering a wide range of options to suit every taste and preference."
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    padding: '8px', // Remove padding from the input container
                                    '& .MuiOutlinedInput-input': {
                                        padding: 0, // Remove padding from the input text
                                    },
                                },
                            }}
                        />
                        <Typography variant="caption" color="textSecondary">
                            Limit to 250 characters
                        </Typography>
                    </Box>

                    <Box sx={{paddingTop:'24px'}}>
                        <Typography
                            sx={{
                                color: 'var(--Gray-700, #344054)', // Text color
                                fontFamily: 'Inter', // Font family
                                fontSize: '14px', // Font size
                                fontStyle: 'normal', // Font style
                                fontWeight: 500, // Font weight
                                lineHeight: '20px', // Line height
                                paddingBottom: '8px',
                            }}
                        >
                            Number of pax
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            defaultValue="100"
                        />
                    </Box>
                </Box>
            );
        case 1:
            return (
                <Box display="flex" flexDirection="column" sx={{height:'100%', minHeight:'500px'}}>
                    <Box sx={{paddingTop:'24px'}}>
                        <FormControl fullWidth variant="outlined" >
                            <Select
                                value={serviceOption}
                                onChange={(event) => setServiceOption(event.target.value)}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: '12px', // Add top margin to the menu
                                        },
                                    },
                                }}
                                sx={{height:'70px', padding:'16px'}}
                            >
                                <MenuItem value="" sx={{height:'70px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', padding:'16px', marginTop:'-8px',}}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '20px', // 142.857%
                                            }}
                                        >
                                            I don't want any additional services at my spaces
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '18px', // 150%
                                            }}
                                        >
                                            No services allowed at my space
                                        </Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value="service1" sx={{height:'70px', padding:'16px', borderTop:"none"}}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '20px', // 142.857%
                                            }}
                                        >
                                            I am open to have additional service from Eventlah
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '18px', // 150%
                                            }}
                                        >
                                            Automatically display services around 10km radius from my space as Add-Ons
                                        </Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value="service2" sx={{height:'70px', padding:'16px', borderTop:"none"}}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '20px', // 142.857%
                                            }}
                                        >
                                            I like to choose my own preferred services from Eventlah
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '18px', // 150%
                                            }}
                                        >
                                            Find & select preferred services for my space
                                        </Typography>
                                    </Box>
                                </MenuItem>
                                <MenuItem value="service3" sx={{height:'62px', borderBottom:'none', padding:'20px' +
                                        ' 16px 16px 16px', borderTop:"none"}}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)',
                                                fontFamily: 'Inter',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '20px', // 142.857%
                                            }}
                                        >
                                            I have my own services
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                lineHeight: '18px', // 150%
                                            }}
                                        >
                                            Selecting my own services for my space
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ marginTop: '24px' }}>
                        {serviceOption === "service1" && <PackageServices1 />}
                        {serviceOption === "service2" && <PackageServices1 />}
                        {serviceOption === "service3" && <PackageServices1 />}
                    </Box>
                </Box>
            );
        case 2:
            return (
                <Box display="flex" flexDirection="column">
                    <Box sx={{ paddingTop:'24px' }}>
                        <TableContainer component={Paper}>
                            <Typography
                                sx={{
                                    color: 'var(--Gray-900, #101828)',
                                    width:'100%',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    lineHeight: '18px',
                                    padding: '16px',
                                    borderBottom: '1px solid var(--Gray-200, #EAECF0)',
                                }}
                            >
                                Selected Services
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor: 'var(--Gray-50, #F9FAFB)'}}>
                                        <TableCell
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '18px',
                                                padding: '8px 16px'
                                            }}
                                        >
                                            Services
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '18px',
                                                padding: '8px 16px'
                                            }}
                                        >
                                            Provider
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                color: 'var(--Gray-500, #667085)',
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: 500,
                                                lineHeight: '18px',
                                                padding: '8px 16px',
                                                textAlign: 'right',
                                            }}
                                        >
                                            Price
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedServices.map((row) => (
                                        <TableRow key={row.service}>
                                            <TableCell>{row.service}</TableCell>
                                            <TableCell>{row.provider}</TableCell>
                                            <TableCell align="right">{row.price}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={2}>Total Services Cost</TableCell>
                                        <TableCell align="right">{totalServicesCost}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box sx={{paddingTop:'24px'}}>
                            <Typography
                                sx={{
                                    color: 'var(--Gray-700, #344054)', // Text color
                                    fontFamily: 'Inter', // Font family
                                    fontSize: '14px', // Font size
                                    fontStyle: 'normal', // Font style
                                    fontWeight: 500, // Font weight
                                    lineHeight: '20px', // Line height
                                    paddingBottom:'4px'
                                }}
                            >
                                Profit margin
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={profitMargin}
                                onChange={(event) => setProfitMargin(event.target.value)}
                            />
                        </Box>

                        <Divider
                            orientation="vertical"
                            sx={{
                                height: '1px',
                                alignSelf: 'stretch',
                                backgroundColor: 'var(--Gray-200, #EAECF0)',
                                margin: '24px 0 24px 0'
                            }}
                        />

                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             sx={{
                                 height:'68px',
                                 background: 'var(--gray-30, #F7F7F7)',
                                 px:'16px',
                                 borderRadius:'8px'
                            }}
                        >
                            <Typography variant="h6" sx={{
                                color: 'var(--Gray-700, #344054)', // Text color
                                fontFamily: 'Inter', // Font family
                                fontSize: '18px', // Font size
                                fontStyle: 'normal', // Font style
                                fontWeight: 600, // Font weight
                                lineHeight: '20px', // Line height
                                }}
                            >
                                Package Price
                            </Typography>
                            <Typography variant="h6" sx={{
                                color: 'var(--Gray-700, #344054)', // Text color
                                fontFamily: 'Inter', // Font family
                                fontSize: '18px', // Font size
                                fontStyle: 'normal', // Font style
                                fontWeight: 600, // Font weight
                                lineHeight: '20px', // Line height
                                }}
                            >
                                {packagePrice}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            );
        default:
            return 'Unknown step';
    }
};

const SpaceFormPackages = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [serviceOption, setServiceOption] = useState('');
    const [profitMargin, setProfitMargin] = useState('RM 3,000.00');
    const [showPackagesPage, setShowPackagesPage] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleCreatePackage = () => {
        setDialogOpen(false);
        setShowPackagesPage(true);
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const selectedServices = [
        { service: 'Annual Dinner Package', provider: 'Delicious Gourmet Kitchen', price: 'RM 4,000.00' },
        { service: 'Modern Photo Booth', provider: 'Happy Moment Studio', price: 'RM 2,100.00' },
        { service: 'Annual Dinner Emcee', provider: 'Awal Ashaari & Co', price: 'RM 6,000.00' },
        { service: 'Live Band', provider: 'KL Band Club', price: 'RM 6,000.00' },
    ];

    const totalServicesCost = 'RM 18,100.00';
    const packagePrice = 'RM 21,100.00';

    if (showPackagesPage) {
        return (
            <Box sx={{ minHeight: '100vh' }}>
                <Box sx={{ display: 'flex'}}>
                    <SearchBar handleDialogOpen={handleDialogOpen} />
                </Box>

                <Box>
                    <PackageList
                        packages={packages}
                        handleMenuClick={handleMenuClick}
                        anchorEl={anchorEl}
                        handleMenuClose={handleMenuClose}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <Box>
            <Box handleDialogOpen={handleDialogOpen} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <SearchBar handleDialogOpen={handleDialogOpen} />
            </Box>

            <Box sx={{padding:'8px', backgroundColor:'#FFF', borderRadius: '5px', marginTop:'24px'}}>
                <Card sx={{ border: '1px dashed #D0D5DD', backgroundColor: 'var(--gray-30, #F7F7F7)', textAlign: 'center', boxShadow:'none' }}>
                    <CardContent sx={{padding:0}}>
                        <Box sx={{ height: '88px', paddingTop:'24px'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 42 42"
                                 fill="none">
                                <path
                                    d="M15 8.84863C15 7.74406 15.8954 6.84863 17 6.84863H38C39.1046 6.84863 40 7.74406 40 8.84863V16.3183V35.1517C40 36.2562 39.1046 37.1517 38 37.1517H27.5H17C15.8954 37.1517 15 36.2562 15 35.1517V8.84863Z"
                                    fill="white"/>
                                <path
                                    d="M40 27.5V8.84863C40 7.74406 39.1046 6.84863 38 6.84863H17C15.8954 6.84863 15 7.74406 15 8.84863V35.1517C15 36.2562 15.8954 37.1517 17 37.1517H27.5"
                                    stroke="#00303E"/>
                                <rect x="21" y="11" width="13" height="13" rx="6.5" fill="#E7E7E7" stroke="#00303E"/>
                                <line x1="17.7725" y1="26.0454" x2="37.227" y2="26.0454" stroke="#00303E"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <line x1="17.7725" y1="28.3184" x2="37.227" y2="28.3184" stroke="#00303E"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M1 3C1 1.89543 1.89543 1 3 1H30C31.1046 1 32 1.89543 32 3V21V39C32 40.1046 31.1046 41 30 41H16.5H3C1.89543 41 1 40.1046 1 39V3Z"
                                    fill="white"/>
                                <path
                                    d="M32 21V3C32 1.89543 31.1046 1 30 1H3C1.89543 1 1 1.89543 1 3V39C1 40.1046 1.89543 41 3 41H16.5"
                                    stroke="#00303E"/>
                                <rect x="10" y="5" width="13" height="13" rx="6.5" fill="#E7E7E7" stroke="#00303E"/>
                                <rect x="5" y="22" width="23" height="3" rx="1.5" fill="white" stroke="#00303E"/>
                                <line x1="5.5" y1="28.5" x2="28.5" y2="28.5" stroke="#00303E" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <line x1="5.5" y1="31.5" x2="28.5" y2="31.5" stroke="#00303E" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path
                                    d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                    fill="#E7E7E7"/>
                                <path
                                    d="M30.5656 40.9804C32.6294 40.9804 34.6469 40.3684 36.3629 39.2218C38.0789 38.0752 39.4163 36.4456 40.2061 34.5388C40.9959 32.6321 41.2026 30.5341 40.7999 28.5099C40.3973 26.4858 39.4035 24.6265 37.9441 23.1671C36.4848 21.7078 34.6255 20.714 32.6014 20.3113C30.5772 19.9087 28.4791 20.1154 26.5724 20.9051C24.6657 21.6949 23.036 23.0324 21.8894 24.7484C20.7428 26.4644 20.1309 28.4818 20.1309 30.5456C20.1309 33.3131 21.2302 35.9672 23.1871 37.9241C25.144 39.881 27.7982 40.9804 30.5656 40.9804Z"
                                    stroke="#00303E" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M30.5654 25.3281V35.7629" stroke="#00303E" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M35.7824 30.5459H25.3477" stroke="#00303E" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </Box>
                        <Typography sx={{
                            color: 'var(--dark-500, #09090B)', // Text color
                            fontFamily: 'Inter', // Font family
                            fontSize: '14px', // Font size
                            fontStyle: 'normal', // Font style
                            fontWeight: 600, // Font weight
                            lineHeight: '20px', // Line height
                        }}>
                            Setup package
                        </Typography>
                        <Typography sx={{
                            color: 'var(--Gray-500, #667085)', // Text color
                            fontFamily: 'Inter', // Font family
                            fontSize: '12px', // Font size
                            fontStyle: 'normal', // Font style
                            fontWeight: 400, // Font weight
                            lineHeight: '18px', // Line height
                        }}>
                            Setup packages to each of your spaces
                        </Typography>
                        <Button variant="cancel" startIcon={<AddIcon/>} onClick={handleDialogOpen} sx={{marginTop:'24px'}}>
                            Add Packages
                        </Button>
                    </CardContent>
                </Card>
            </Box>

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth='lg'
                sx={{borderRadius:'10px'}}
                PaperProps={{
                    sx: {
                        borderRadius: '15px',
                    },
                }}
            >
                <Box display="flex">
                    <Box sx={{width: '264px', borderRight: '1px solid #e0e0e0', padding:'32px'}}>
                        <Typography sx={{
                            color: 'var(--Gray-900, #101828)', // Text color
                            fontFamily: 'Inter', // Font family
                            fontSize: '18px', // Font size
                            fontStyle: 'normal', // Font style
                            fontWeight: 600, // Font weight
                            lineHeight: '24px', // Line height
                            paddingBottom: '32px',
                        }}>
                            Create New Package
                        </Typography>

                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, i) => (
                                <Step key={label} >
                                    <StepLabel >
                                        <Box sx={{display: 'inline-flex', backgroundColor:'primary'}}>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Inter', // Font family
                                                    fontSize: '10px', // Font size
                                                    fontStyle: 'normal', // Font style
                                                    fontWeight: 600, // Font weight
                                                    lineHeight: '10px', // Line height
                                                }}
                                            >
                                                STEP {i+1}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                            sx={{
                                                color: 'var(--dark-500, #09090B)', // Text color
                                                fontFamily: 'Inter', // Font family
                                                fontSize: '14px', // Font size
                                                fontStyle: 'normal', // Font style
                                                fontWeight: 600, // Font weight
                                                lineHeight: '18px', // Line height
                                            }}
                                            >
                                                {label}
                                            </Typography>
                                        </Box>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <Box sx={{width: '100%'}}>
                        <DialogTitle sx={{padding:'32px 32px 0 32px'}}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <Typography sx={{
                                        color: 'var(--Gray-900, #101828)', // Text color
                                        fontFamily: 'Inter', // Font family
                                        fontSize: '18px', // Font size
                                        fontStyle: 'normal', // Font style
                                        fontWeight: 600, // Font weight
                                        lineHeight: '24px', // Line height
                                    }}>
                                        {steps[activeStep]}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'var(--gray-500, #6D6D6D)', // Text color
                                            fontFamily: 'Inter', // Font family
                                            fontSize: '14px', // Font size
                                            fontStyle: 'normal', // Font style
                                            fontWeight: 400, // Font weight
                                            lineHeight: '24px', // Line height
                                        }}
                                    >
                                        {subSteps[activeStep]}
                                    </Typography>
                                </Box>
                                <IconButton onClick={handleDialogClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent sx={{padding:'32px'}}>
                            <StepContent
                                activeStep={activeStep}
                                serviceOption={serviceOption}
                                setServiceOption={setServiceOption}
                                selectedServices={selectedServices}
                                totalServicesCost={totalServicesCost}
                                profitMargin={profitMargin}
                                setProfitMargin={setProfitMargin}
                                packagePrice={packagePrice}
                            />
                        </DialogContent>
                        <DialogActions sx={{padding:'32px', gap:'12px'}}>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} variant="cancel" sx={{width:'50%', height:'44px'}}>
                                    Previous
                                </Button>
                            )}
                            {activeStep < steps.length - 1 ? (
                                <Button onClick={handleNext} variant="save" sx={{width:'50%', height:'44px'}}>
                                    Next
                                </Button>
                            ) : (
                                <Button onClick={handleCreatePackage} variant="save" sx={{width:'50%', height:'44px'}}>
                                    Create Package
                                </Button>
                            )}
                        </DialogActions>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );
};

export default SpaceFormPackages;

import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fetchDailyEvents } from './DailyEventsAPI';

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const DailyEvents = () => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);

    const loadEvents = async () => {
        setLoading(true);
        try {
            const data = await fetchDailyEvents(paginationModel.page, paginationModel.pageSize, searchQuery);
            const eventsDaily = data.data.eventsDaily;

            // Map eventItem and include the name at the top level
            const eventData = eventsDaily.map(eventItem => ({
                ...eventItem,
                name: eventItem.event.name // Adding the event name to the top level
            }));

            setEvents(eventData); // Set the modified data to the state
            setTotalCount(data.data.pagination.totalCount || 0);
        } catch (error) {
            console.error('Error loading events:', error);
            setError('Failed to load events');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadEvents();
    }, [paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5, minWidth: 80 },
        { field: 'name', headerName: 'Event Name', flex: 2, minWidth: 150},
        { field: 'event_date', headerName: 'Event Date', flex: 1.5, minWidth: 130 },
        { field: 'status_id', headerName: 'Status', flex: 0.8, minWidth: 110 },
        { field: 'minimum_pax', headerName: 'Min Pax', flex: 0.8, minWidth: 110 },
        { field: 'maximum_pax', headerName: 'Max Pax', flex: 0.8, minWidth: 110 },
        { field: 'current_pax', headerName: 'Current Pax', flex: 0.8, minWidth: 140 },
        { field: '', headerName: 'Packages', flex: 0.8, minWidth: 130 },
    ];

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box />
                <Box display="flex" gap="12px">
                    <TextField
                        variant="outlined"
                        placeholder="Search events"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': {
                                    height: '34px',
                                    padding: '0 14px',
                                },
                                '& .MuiInputBase-input': {
                                    height: '34px',
                                    padding: '0 14px',
                                }
                            }
                        }}
                    />
                </Box>
            </Box>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Daily Events
                </Typography>

                {loading ? (
                    <Box>
                        <Box sx={{ px: 2, py: 1 }}>
                            <Skeleton variant="rectangular" height={52} />
                        </Box>
                        {[...Array(5)].map((_, index) => (
                            <SkeletonRow key={index} />
                        ))}
                    </Box>
                ) : error ? (
                    <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                ) : (
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={events}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[5, 10, 20]}
                        rowCount={totalCount}
                        paginationMode="server"
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        loading={loading}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                backgroundColor: '#737373',
                                color: '#fff',
                                height: '34px',
                                my: 1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: '#404040',
                                },
                            },
                        }}
                    />
                )}
            </Paper>
        </Box>
    );
};

export default DailyEvents;

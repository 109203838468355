import { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Divider,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const AgentPayouts = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const data = [
        {id: 1, payout_id: "P001", period: "Jan 2024", earnings: "RM 2,850.00", bookings: 12, tickets_sold: 45, processing_date: "2024-02-01", payout_date: "2024-02-03", bank_account: "CIMB **** 1234", status: "Completed", transaction_ref: "TRX78901234"},
        {id: 2, payout_id: "P002", period: "Feb 2024", earnings: "RM 3,120.50", bookings: 15, tickets_sold: 52, processing_date: "2024-03-01", payout_date: "2024-03-03", bank_account: "CIMB **** 1234", status: "Completed", transaction_ref: "TRX78901235"},
        {id: 3, payout_id: "P003", period: "Mar 2024 (W1)", earnings: "RM 1,250.75", bookings: 6, tickets_sold: 18, processing_date: "2024-03-08", payout_date: "2024-03-10", bank_account: "CIMB **** 1234", status: "Completed", transaction_ref: "TRX78901236"},
        {id: 4, payout_id: "P004", period: "Mar 2024 (W2)", earnings: "RM 980.25", bookings: 4, tickets_sold: 12, processing_date: "2024-03-15", payout_date: "2024-03-17", bank_account: "CIMB **** 1234", status: "Completed", transaction_ref: "TRX78901237"},
        {id: 5, payout_id: "P005", period: "Mar 2024 (W3)", earnings: "RM 1,875.00", bookings: 8, tickets_sold: 25, processing_date: "2024-03-22", payout_date: "2024-03-24", bank_account: "CIMB **** 1234", status: "Completed", transaction_ref: "TRX78901238"},
        {id: 6, payout_id: "P006", period: "Mar 2024 (W4)", earnings: "RM 2,340.00", bookings: 10, tickets_sold: 35, processing_date: "2024-03-29", payout_date: "-", bank_account: "CIMB **** 1234", status: "Processing", transaction_ref: "TRX78901239"},
        {id: 7, payout_id: "P007", period: "Apr 2024 (W1)", earnings: "RM 1,650.25", bookings: 7, tickets_sold: 22, processing_date: "2024-04-05", payout_date: "-", bank_account: "CIMB **** 1234", status: "Pending", transaction_ref: "Pending"},
        {id: 8, payout_id: "P008", period: "Apr 2024 (W2)", earnings: "RM 2,100.75", bookings: 9, tickets_sold: 28, processing_date: "-", payout_date: "-", bank_account: "CIMB **** 1234", status: "Processing", transaction_ref: "Pending"}
    ];

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const columns = [
        {
            field: 'payout_id',
            headerName: 'Payout ID',
            flex: 1,
            minWidth: 130
        },
        {
            field: 'period',
            headerName: 'Period',
            flex: 1.2,
            minWidth: 130
        },
        {
            field: 'earnings',
            headerName: 'Total Earnings',
            flex: 1.2,
            minWidth: 150,
        },
        {
            field: 'bookings',
            headerName: 'Total Bookings',
            flex: 1,
            minWidth: 150,
        },
        {
            field: 'tickets_sold',
            headerName: 'Tickets Sold',
            flex: 1,
            minWidth: 150
        },
        {
            field: 'processing_date',
            headerName: 'Processing Date',
            flex: 1.2,
            minWidth: 160
        },
        {
            field: 'payout_date',
            headerName: 'Payout Date',
            flex: 1.2,
            minWidth: 140
        },
        {
            field: 'bank_account',
            headerName: 'Bank Account',
            flex: 1.3,
            minWidth: 150
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 120,
        },
        {
            field: 'transaction_ref',
            headerName: 'Transaction Ref',
            flex: 1.2,
            minWidth: 160
        },
    ];

    return (
        <Box sx={{ padding:{xs:1,md:'32px'}, height: '100%', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Agent Payouts</Typography>
                        <Typography variant='h2'>Track your earnings and payment status</Typography>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search payouts"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        Payout Details
                    </Typography>

                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={data}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[5, 10, 20]}
                        paginationMode="client"
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                backgroundColor: '#737373',
                                color: '#fff',
                                height: '34px',
                                my:1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: '#404040',
                                },
                            },
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    );
};

export default AgentPayouts;
import React, {useEffect, useMemo, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Divider,
    CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { fetchSubscriptions } from '../../redux/subscriptionsSlice';

const SubscriptionList = () => {
    const dispatch = useDispatch();
    const subscriptionsData = useSelector((state) => {
        console.log("Full state:", state);
        console.log("Sub state:", state.subscriptions.subscriptions.subscriptions);
        return state.subscriptions.subscriptions;
    });
    const totalCount = subscriptionsData?.pagination?.totalCount;
    const subscriptionStatus = useSelector((state) => state.events.status);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    useEffect(() => {
        if (subscriptionStatus === 'idle') {
            dispatch(fetchSubscriptions());
        }
    }, [subscriptionStatus, dispatch]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const filteredRows = () => {
        if (!subscriptionsData || !Array.isArray(subscriptionsData.subscriptions)) {
            return [];
        }

        return subscriptionsData.subscriptions
            .map(subscription => ({
                ...subscription,
                id: subscription.id,
                name: subscription.name,
                description: subscription.description || '',
                code: subscription.code,
                priceMonthly: subscription.price_monthly,
                promoMonthly: subscription.promo_price_monthly,
                priceYearly: subscription.price_yearly,
                promoYearly: subscription.promo_price_yearly,
                createdAt: subscription.created_at,
                updatedAt: subscription.updated_at
            }))
            .filter((row) =>
                row.name && row.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
    };

    const memoizedFilteredRows = useMemo(() => filteredRows(), [subscriptionsData, searchQuery]);

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'code',
            headerName: 'Code',
            flex: 0.4,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'priceMonthly',
            headerName: 'Monthly Price',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">RM {params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'priceYearly',
            headerName: 'Yearly Price',
            flex: 0.6,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">RM {params.value}</Typography>
                </Box>
            ),
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            ),
        },
    ];

    return (
        <Box sx={{padding:'32px'}}>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                        <Typography variant='h1'>Admin Subscriptions</Typography>
                        <Typography variant='h2'>View, edit, and manage all your scheduled subscriptions in one place.</Typography>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Subscriptions
                    </Typography>

                    {subscriptionStatus === 'loading' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={memoizedFilteredRows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 6,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default SubscriptionList;
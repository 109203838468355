import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api';

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchCustomerDetails = createAsyncThunk(
    'customerDetails/fetchCustomerDetails',
    async ({ page, limit, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/customer-details`, {
                headers,
                params: {
                    page,
                    limit,
                    search: searchQuery,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createCustomer = createAsyncThunk(
    'customerDetails/createCustomer',
    async (customerData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/customer-details`, customerData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateCustomer = createAsyncThunk(
    'customerDetails/updateCustomer',
    async ({ id, customerData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/customer-details/${id}`, customerData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteCustomer = createAsyncThunk(
    'customerDetails/deleteCustomer',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/customer-details/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const customerDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState: {
        customerDetails: [],
        pagination: null,
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customerDetails = action.payload.data.customerDetails;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchCustomerDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch customer details';
            })
            .addCase(createCustomer.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCustomer.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customerDetails.push(action.payload.data);
            })
            .addCase(createCustomer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create customer';
            })
            .addCase(updateCustomer.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCustomer.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.customerDetails.findIndex(customer => customer.id === action.payload.data.id);
                if (index !== -1) state.customerDetails[index] = action.payload.data;
            })
            .addCase(updateCustomer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update customer';
            })
            .addCase(deleteCustomer.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customerDetails = state.customerDetails.filter(customer => customer.id !== action.payload);
            })
            .addCase(deleteCustomer.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete customer';
            });
    },
});

export default customerDetailsSlice.reducer;
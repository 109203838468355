import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress
} from '@mui/material';

const EditTicketingDialog = ({ open, onClose, onConfirm, event, isEditing }) => {
    const [editedEvent, setEditedEvent] = useState(event);

    useEffect(() => {
        setEditedEvent(event);
    }, [event]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedEvent(prev => ({ ...prev, [name]: value }));
    };

    const handleConfirm = () => {
        onConfirm(editedEvent);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Event</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Event Name"
                    type="text"
                    fullWidth
                    value={editedEvent?.name || ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="description"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    value={editedEvent?.description || ''}
                    onChange={handleChange}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            padding: '15px',
                            '& .MuiOutlinedInput-input': {
                                padding: 0,
                            },
                        },
                    }}
                />
                <TextField
                    margin="dense"
                    name="event_start_date"
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={editedEvent?.event_start_date || ''}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="event_end_date"
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={editedEvent?.event_end_date || ''}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="cancel" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="save" onClick={handleConfirm}disabled={isEditing}>
                    {isEditing ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTicketingDialog;
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Collapse,
    useMediaQuery,
    useTheme,
    Typography,
    Menu,
    MenuItem,
    IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Receipt as ReceiptIcon,
    ExitToApp as ExitToAppIcon,
    ExpandLess,
    ExpandMore,
    AdminPanelSettings as AdminIcon,
    Storefront as StorefrontIcon,
    Assignment as AssignmentIcon,
    EventNote as EventNoteIcon,
    CalendarToday as CalendarTodayIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    Payments as PaymentsIcon,
    AttachMoney as AttachMoneyIcon
} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

import keycloak from "../keycloak";
import { styled, alpha } from "@mui/material/styles";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        color: 'var(--gray-400, #888)',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '12px',
    },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            padding: '10px 16px',
            '& .MuiListItemIcon-root': {
                minWidth: 24,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                    ),
                },
            },
        },
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
    transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

const drawerWidth = 311;

const AccountHeader = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRoleSelect = (role) => {
        onRoleChange(role);
        handleClose();
    };

    const getRoleDisplay = (role) => {
        switch (role) {
            case 'merchants':
                return 'Merchant';
            case 'eventlah-admin':
                return 'Admin';
            case 'eventlah-agent':
                return 'Agent';
            default:
                return role;
        }
    };

    const LogoPlaceholder = ({ width = 50, height = 50, bgColor = '#f0f0f0', textColor = '#888' }) => {
        return (
            <div className="flex items-center justify-center" style={{ width, height }}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full h-full rounded-md"
                >
                    <rect width={width} height={height} fill={bgColor} className="rounded-md" />
                    <text
                        x="50%"
                        y="50%"
                        fontSize={width * 0.2}
                        fill={textColor}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontFamily="system-ui"
                    >
                        LOGO
                    </text>
                </svg>
            </div>
        );
    };

    return (
        <ListItem>
            <Box sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                mt: 2,
            }}>
                {/* Main content layout */}
                <Box
                    sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '6px',
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        mr: 2,
                    }}
                >
                    <LogoPlaceholder width={50} height={50} />
                </Box>

                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{storeName}</Typography>
                    </Box>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#c7c7c7', mb: '2px' }}>
                        {getRoleDisplay(currentRole)} Account Status
                    </Typography>
                    <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>
                        Role: {getRoleDisplay(currentRole)}
                    </Typography>
                </Box>

                <Box sx={{ my: "auto" }}>
                    {availableRoles.length > 1 && (
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.2s ease-in-out'
                            }}
                            aria-controls={open ? 'role-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>

            <StyledMenu
                id="role-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'role-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                elevation={2}
            >
                {availableRoles.map((role) => (
                    <StyledMenuItem
                        key={role}
                        onClick={() => handleRoleSelect(role)}
                        selected={currentRole === role}
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            padding: 2,
                        }}
                    >
                        {/*dropdown menu*/}
                        <Box
                            sx={{
                                width: 40,
                                height: 40,
                                borderRadius: '6px',
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'hidden',
                                mr: 2,
                            }}
                        >
                            <LogoPlaceholder width={40} height={40} />
                        </Box>

                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{storeName}</Typography>
                            <Typography sx={{ fontWeight: '500', fontSize: '12px', mt: '2px' }}>
                            Role: {getRoleDisplay(role)}
                            </Typography>
                        </Box>

                        {currentRole === role && (
                            <ListItemIcon sx={{ minWidth: 'auto', marginLeft: 1 }}>
                                <CheckIcon color="primary" fontSize="small" />
                            </ListItemIcon>
                        )}
                    </StyledMenuItem>
                ))}
            </StyledMenu>
        </ListItem>
    );
};

// MerchantView component remains unchanged
const MerchantView = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, link: '/' },
        { text: 'Transactions', icon: <AccountBalanceWalletIcon />, link: '/settings/transaction-history' },
        { text: 'Products', icon: <StorefrontIcon />, link: '/settings/product-catalog' },
        { text: 'Orders', icon: <AssignmentIcon />, link: '/order-overview' },
        { text: 'Ticketing', icon: <EventNoteIcon />, link: '/ticketing-overview' },
        { text: 'Bookings', icon: <RoomPreferencesIcon />, link: '/booking-overview' },
        { text: 'Appointments', icon: <CalendarTodayIcon />, link: '/settings/appointments' },
        { text: 'Customers', icon: <PeopleIcon />, link: '/settings/customer-records' },
        { text: 'Agents', icon: <PeopleIcon />, link: '/agents-overview' },
        { text: 'Billings', icon: <ReceiptIcon />, link: '/settings/account-status#billing' },
    ];

    return (
        <Box>
            <AccountHeader
                storeName={storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {menuItems.map((item) => (
                    <ListItem button component={Link} to={item.link} key={item.text}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

// AdminView component remains unchanged
const AdminView = ({ currentRole, availableRoles, onRoleChange }) => {
    const [openAdmin, setOpenAdmin] = useState(false);

    return (
        <Box>
            <AccountHeader
                storeName="Admin Dashboard"
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />

            <ListItem button component={Link} to="/">
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>

            <ListItem button onClick={() => setOpenAdmin(!openAdmin)}>
                <ListItemIcon><AdminIcon /></ListItemIcon>
                <ListItemText primary="Merchant Overview" />
                {openAdmin ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button component={Link} to="/admin/admin-dashboard" sx={{ pl: 9 }}>
                        <CustomListItemText primary="Admin Dashboard" />
                    </ListItem>
                    <ListItem button component={Link} to="/admin/onboarding" sx={{ pl: 9 }}>
                        <CustomListItemText primary="Onboarding" />
                    </ListItem>
                    <ListItem button component={Link} to="/admin/agreement" sx={{ pl: 9 }}>
                        <CustomListItemText primary="Agreement Management" />
                    </ListItem>
                    <ListItem button component={Link} to="/admin/email-management" sx={{ pl: 9 }}>
                        <CustomListItemText primary="Email Management" />
                    </ListItem>
                    <ListItem button component={Link} to="/admin/subscriptions" sx={{ pl: 9 }}>
                        <CustomListItemText primary="Admin Subscriptions" />
                    </ListItem>
                    <ListItem button component={Link} to="/admin/subscription-features" sx={{ pl: 9 }}>
                        <CustomListItemText primary="Admin Subscriptions Features" />
                    </ListItem>
                </List>
            </Collapse>
        </Box>
    );
};

// AgentView component remains unchanged
const AgentView = ({ storeName, currentRole, availableRoles, onRoleChange }) => {
    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, link: '/agent-dashboard' },
        { text: 'Profile', icon: <PersonAddAlt1Icon />, link: '/agent-profile' },
        { text: 'Commissions', icon: <AttachMoneyIcon />, link: '/agent-commissions' },
        { text: 'Payouts', icon: <PaymentsIcon />, link: '/agent-payouts' },
    ];

    return (
        <Box>
            <AccountHeader
                storeName= {storeName}
                currentRole={currentRole}
                availableRoles={availableRoles}
                onRoleChange={onRoleChange}
            />
            <List>
                {menuItems.map((item) => (
                    <ListItem button component={Link} to={item.link} key={item.text}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

// Sidebar component remains unchanged
const Sidebar = ({ isOpen, toggleSidebar }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const availableRoles = ['merchants', 'eventlah-admin', 'eventlah-agent'].filter(role =>
        role === 'merchants' ? keycloak.hasRealmRole('merchants') :
            role === 'eventlah-admin' ? keycloak.hasRealmRole('eventlah-admin') :
                keycloak.hasRealmRole('eventlah-agent')
    );

    const [currentRole, setCurrentRole] = useState(availableRoles[0]);

    const storeName = useSelector(state =>
        state.organizationProfiles?.profiles[0]?.data?.name || 'Merchant Store Name'
    );

    const handleLogout = () => {
        keycloak.logout();
    };

    const handleRoleChange = (newRole) => {
        setCurrentRole(newRole);
    };

    return (
        <Drawer
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={isOpen}
            onClose={toggleSidebar}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                {currentRole === 'merchants' && (
                    <MerchantView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                )}
                {currentRole === 'eventlah-admin' && (
                    <AdminView
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                )}
                {currentRole === 'eventlah-agent' && (
                    <AgentView
                        storeName={storeName}
                        currentRole={currentRole}
                        availableRoles={availableRoles}
                        onRoleChange={handleRoleChange}
                    />
                )}

                <ListItem button onClick={handleLogout}>
                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
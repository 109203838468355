import React, { useState } from 'react';
import {
    Menu,
    MenuItem,
    IconButton,
    Badge,
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    DialogContentText
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import ExitToApp from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from "@mui/system";
import { Link } from 'react-router-dom';
import keycloak from "../../keycloak";

const CustomText = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
}));

const HeaderProfile = () => {

    const name = keycloak.tokenParsed?.name || 'No name available';


    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleLogout = () => {
        keycloak.logout();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
        handleClose();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Box sx={{display:'flex', alignItems:'center',}}>
            <CustomText onClick={handleClick} sx={{cursor:'pointer'}}>
                {name}
            </CustomText>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <KeyboardArrowDownIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        width: '250px',
                        height: 'auto',
                        backgroundColor:'var(--Gray-50, #F9FAFB)',
                        borderRadius:'7px',
                        marginTop:'10px',
                        marginLeft:'40px'
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {/*<MenuItem*/}
                {/*    component={Link}*/}
                {/*    to="/settings/profile-settings"*/}
                {/*    onClick={handleClose}*/}
                {/*    sx={{*/}
                {/*        height: '110px',*/}
                {/*        padding: '20px',*/}
                {/*        margin: '14px',*/}
                {/*        backgroundColor: 'white',*/}
                {/*        boxShadow: 2,*/}
                {/*        borderRadius:'7px',*/}
                {/*        border:'none',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    Profile Settings*/}
                {/*    <Badge badgeContent={1} color="primary" style={{ marginLeft: 'auto' }} />*/}
                {/*</MenuItem>*/}
                <MenuItem
                    component={Link}
                    to="/settings/profile-settings"
                    onClick={handleClose}
                    sx={{
                        border:'none',
                        backgroundColor:'white',
                        height:'50px',
                    }}
                >
                    <AccountCircle style={{ marginLeft: 14, marginRight: 32 }} />
                    Profile
                </MenuItem>
                <MenuItem
                    component={Link}
                    to="#"
                    onClick={handleClose}
                    sx={{
                        border:'none',
                        backgroundColor:'white',
                        height:'50px',
                    }}
                >
                    <VerifiedUser style={{ marginLeft: 14, marginRight: 32 }} />
                    Verification
                </MenuItem>
                <MenuItem
                    onClick={handleLogout}
                    sx={{
                        border:'none',
                        backgroundColor:'white',
                        height:'50px',
                    }}
                >
                    <ExitToApp style={{  marginLeft: 14, marginRight: 32 }} />
                    Logout
                </MenuItem>
            </Menu>
            {/*<Dialog*/}
            {/*    open={dialogOpen}*/}
            {/*    onClose={handleDialogClose}*/}
            {/*    aria-labelledby="alert-dialog-title"*/}
            {/*    aria-describedby="alert-dialog-description"*/}
            {/*    fullWidth*/}
            {/*    maxWidth='lg'*/}
            {/*    PaperProps={{*/}
            {/*        style: {*/}
            {/*            width:'800px',*/}
            {/*            height: '800px',*/}
            {/*            borderRadius:'10px',*/}
            {/*            backgroundColor: '#ebebeb',*/}
            {/*        }*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <DialogActions*/}
            {/*        sx={{*/}
            {/*            backgroundColor:'white',*/}
            {/*            height:'80px'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Button variant='cancel' onClick={handleDialogClose}>*/}
            {/*            Close*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*    <DialogTitle></DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <DialogContentText sx={{backgroundColor:'white', height:'35px'}}>*/}
            {/*        </DialogContentText>*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
        </Box>
    );
};

export default HeaderProfile;
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Box,
    IconButton, Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';

const columns = [
    { id: 'event_date', label: 'Event Date', minWidth: 100 },
    { id: 'updated_at', label: 'Order Date', minWidth: 100 },
    { id: 'code', label: 'Order ID', minWidth: 100 },
    { id: 'customer_first_name', label: 'Customer First Name', minWidth: 150 },
    { id: 'customer_last_name', label: 'Customer Last Name', minWidth: 150 },
    { id: 'customer_phone', label: 'Customer Phone', minWidth: 120 },
    { id: 'customer_email', label: 'Customer Email', minWidth: 200 },
    { id: 'total_price', label: 'Total Price', minWidth: 100 },
    { id: 'total_quantity', label: 'Total Quantity', minWidth: 100 },
];

const BookingList = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [bookings, setBookings] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            // Simulating data fetching based on id
            // Replace this with actual API call in a real application
            const fetchBookings = async () => {
                // Placeholder data - replace with actual data fetching logic
                const placeholderData = [
                    {
                        id: id,
                        event_date: '2023-08-01',
                        updated_at: '2023-07-15',
                        code: `ORD00${id}`,
                        customer_first_name: 'John',
                        customer_last_name: 'Doe',
                        customer_phone: '123-456-7890',
                        customer_email: 'john@example.com',
                        total_price: 100,
                        total_quantity: 2,
                    },
                    // Add more placeholder bookings as needed
                ];
                setBookings(placeholderData);
            };

            fetchBookings();
        }
    }, [open, id]);

    const filteredBookings = bookings.filter((booking) =>
        Object.values(booking).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <>
            <Tooltip title="View Booking List">
                <IconButton onClick={handleOpen} aria-label="view-booking-list">
                    <ListIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>
                    Booking List for ID: {id}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredBookings.map((booking) => (
                                    <TableRow hover key={booking.id}>
                                        {columns.map((column) => (
                                            <TableCell key={column.id}>
                                                {booking[column.id]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default BookingList;
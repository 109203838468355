import React, { useState } from 'react';
import {Button, Box, Tabs, Tab, Divider, Typography} from '@mui/material';
import SpacesFormDetails from "./SpacesForm-details";
import SpacesFormGallery from "./SpacesForm-gallery";
import SpacesFormPackages from "./SpacesForm-packages";

const SpacesForm = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box display='flex' height='100vh' bgcolor='#f5f5f5'>
            <Box padding='32px' flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>
                            Add New Booking
                        </Typography >
                        <Typography variant='h2'>
                            Add new booking under your organization.
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', gap: '12px' }}>
                        <Button variant="cancel" onClick={ ()=>{} }>Cancel</Button>
                        <Button variant="save" onClick={ ()=>{} }>Save as Draft</Button>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="spaces tabs">
                        <Tab label="Booking Details" />
                        <Tab label="Gallery" />
                        <Tab label="Packages" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <SpacesFormDetails/> }
                    { value === 1 && <SpacesFormGallery/> }
                    { value === 2 &&  <SpacesFormPackages/> }
                </Box>
            </Box>
        </Box>

    );
};

export default SpacesForm;

import { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Card,
    Paper,
    TextField,
    Grid,
    Button,
    IconButton,
    Tooltip,
    Divider,
    Skeleton
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import LineChartComponent from "../../dashboard/ChartComponent";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const AgentDashboard = () => {
    const [loading, setLoading] = useState(true); // Add loading state
    const [bookings, setBookings] = useState([]); // Move bookings state here

    // Simulating data loading
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 4000); // simulate 4 seconds delay
    }, []);

    const accumulativeSalesData = [
        { sale_date: '2024-10-01', total_sales: 1000.00 },
        { sale_date: '2024-10-05', total_sales: 1200.00 },
        { sale_date: '2024-10-10', total_sales: 1500.00 },
        { sale_date: '2024-10-12', total_sales: 1800.00 },
        { sale_date: '2024-10-15', total_sales: 1900.00 },
        { sale_date: '2024-10-18', total_sales: 2100.00 },
        { sale_date: '2024-10-20', total_sales: 2500.00 },
        { sale_date: '2024-10-25', total_sales: 3000.00 },
        { sale_date: '2024-10-28', total_sales: 4000.00 },
        { sale_date: '2024-10-30', total_sales: 5000.00 },
    ];

    const salesThisWeekData = [
        { sale_date: '2024-10-15', total_sales: 200.00 },
        { sale_date: '2024-10-16', total_sales: 250.00 },
        { sale_date: '2024-10-17', total_sales: 300.00 },
        { sale_date: '2024-10-18', total_sales: 350.00 },
        { sale_date: '2024-10-19', total_sales: 400.00 },
        { sale_date: '2024-10-20', total_sales: 450.00 },
        { sale_date: '2024-10-21', total_sales: 500.00 },
        { sale_date: '2024-10-22', total_sales: 550.00 },
        { sale_date: '2024-10-23', total_sales: 600.00 },
        { sale_date: '2024-10-24', total_sales: 650.00 },
    ];

    const timeRangeAccumulative = 'daily';
    const timeRangeWeekly = 'daily';

    // Booking data (Example data)
    const exampleBookings = [
        {
            id: 1,
            event_date: '2024-12-01',
            purchase_date: '2024-11-15',
            total_quantity: 5,
            total_price: 250,
            customer_name: 'John Doe',
            email: 'john.doe@example.com',
            phone_no: '0123456789',
            status: 1,
            notes: 'Looking forward to the event!'
        },
        {
            id: 2,
            event_date: '2024-12-05',
            purchase_date: '2024-11-20',
            total_quantity: 3,
            total_price: 150,
            customer_name: 'Jane Smith',
            email: 'jane.smith@example.com',
            phone_no: '0987654321',
            status: 0,
            notes: 'Please call if anything changes.'
        }
    ];

    const columns = [
        { field: 'customer_name', headerName: 'Name', flex: 2, minWidth: 150 },
        { field: 'email', headerName: 'Email', flex: 3, minWidth: 200 },
        { field: 'total_quantity', headerName: 'No of Pax', flex: 1, minWidth: 120 },
        { field: 'total_price', headerName: 'Total Price', flex: 1, minWidth: 160 },
        { field: 'phone_no', headerName: 'Phone No', flex: 2, minWidth: 150 },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            minWidth: 150,
            valueFormatter: (params) => params.value === 1 ? 'Paid' : 'Pending payment'
        },
        { field: 'notes', headerName: 'Customer Notes', flex: 3, minWidth: 200 }
    ];

    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const agentCode = 'AGENT123';
    const allCount = 10;
    const pendingPaymentCount = 3;
    const paidBookingCount = 7;
    const bookingStats = [
        {
            title: 'All Booking',
            count: allCount,
            borderColor: '#12B76A',
            backgroundColor: '#ECFDF3',
        },
        {
            title: 'Pending Payment',
            count: pendingPaymentCount,
            borderColor: '#FFED00',
            backgroundColor: '#FDFFE7',
        },
        {
            title: 'Paid Booking',
            count: paidBookingCount,
            borderColor: '#1999E3',
            backgroundColor: '#F1FAFF',
        }
    ];

    const copyToClipboard = () => {
        const referralLink = `https://www.orchardvilla.com.my/iftar2024/?promo=${agentCode}`;
        navigator.clipboard.writeText(referralLink);
    };

    return (
        <Box sx={{ padding: '32px' }}>
            {/* Referral Section */}
            <Card sx={{ maxWidth: '100%', borderRadius: '8px', padding: '32px', }}>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <Typography variant="h6">Your Agent Referral Program</Typography>
                    <Paper elevation={0} sx={{ bgcolor: 'grey.50', py: 2, px: 3, display: 'inline-block', borderRadius: '8px' }}>
                        {loading ? <Skeleton variant="text" width={100} height={40} /> : <Typography sx={{ fontWeight: '800', fontSize: '28px' }}>{agentCode}</Typography>}
                    </Paper>
                </Box>

                <Box sx={{ textAlign: 'center', mb: 3 }}>
                    <EmojiEventsIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                    <Typography variant="h6" gutterBottom>Earn While You Share!</Typography>
                    <Typography variant="body2" color="text.secondary">Share your unique link and earn commission for each successful booking.</Typography>
                </Box>

                <Box sx={{ textAlign: 'center', my: 3 }}>
                    <Typography variant="subtitle2">Promotional Video</Typography>
                    {loading ? <Skeleton variant="rectangular" width="50%" height={300} sx={{ margin: '0 auto', borderRadius: '8px' }} /> : (
                        <iframe
                            width="50%"
                            height="300"
                            src="https://www.youtube.com/embed/U85Ovt8142s?si=ii9D0aCSga4ep7Ig"
                            title="Promotional Video"
                            frameBorder="0"
                            allowFullScreen
                            style={{ borderRadius: '8px', border: '1px solid #EAECF0' }}
                        />
                    )}
                </Box>

                <Divider sx={{ my: 3 }} />

                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2">Your Referral Link</Typography>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {loading ? (
                            <Skeleton variant="rectangular" width="100%" height={40} />
                        ) : (
                            <TextField
                                fullWidth
                                size="small"
                                value={`https://www.orchardvilla.com.my/iftar2024/?promo=${agentCode}`}
                                InputProps={{ readOnly: true }}
                                sx={{ bgcolor: 'grey.50', '& .MuiOutlinedInput-root': { height: '40px' } }}
                            />
                        )}
                        {loading ? (
                            <Skeleton variant="circular" width={40} height={40} />
                        ) : (
                            <Tooltip title="Copy link">
                                <IconButton onClick={copyToClipboard} sx={{ border: 1, borderColor: 'divider', borderRadius: '8px' }}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>

                <Button variant="contained" color="primary" fullWidth size="large" startIcon={<ShareIcon />} sx={{ py: 1.5 }} disabled={loading}>
                    Share Now
                </Button>
            </Card>

            <Divider sx={{ my: 10 }} />

            {/* Booking Stats */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' }, mb: '20px', gap: 3 }}>
                {loading ? (
                    Array(3).fill().map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width="100%" height={120} sx={{ borderRadius: '8px' }} />
                    ))
                ) : (
                    bookingStats.map((stat, index) => (
                        <Card key={index} sx={{ flex: 1, padding: '24px 32px 32px 32px', borderRadius: '8px' }}>
                            <Typography sx={{ mb: 2, fontSize: '16px', fontWeight: 600 }}>{stat.title}</Typography>
                            <Box sx={{ padding: '10px', pl: 3, height: '100px', border: `1px solid ${stat.borderColor}`, backgroundColor: stat.backgroundColor, display: 'flex', alignItems: 'center', borderRadius: '8px' }}>
                                <Typography fontSize="36px" fontWeight="bold">{stat.count}</Typography>
                            </Box>
                        </Card>
                    ))
                )}
            </Box>

            {/* Sales */}
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '8px' }} />
                    ) : (
                        <Card sx={{ height: '400px', marginBottom: '20px' }}>
                            <Typography variant="h6" sx={{ padding: '16px' }}>Accumulative Sales (RM)</Typography>
                            <LineChartComponent data={accumulativeSalesData} timeRange={timeRangeAccumulative} />
                        </Card>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '8px' }} />
                    ) : (
                        <Card sx={{ height: '400px', marginBottom: '20px' }}>
                            <Typography variant="h6" sx={{ padding: '16px' }}>Sales This Week (RM)</Typography>
                            <LineChartComponent data={salesThisWeekData} timeRange={timeRangeWeekly} />
                        </Card>
                    )}
                </Grid>
            </Grid>

            <Divider sx={{ my: 10 }} />

            {/* Table Section */}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box display="flex" width="100%" justifyContent="flex-end">
                    <TextField
                        variant="outlined"
                        placeholder="Search bookings"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            maxWidth: '300px',
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': { height: '34px', padding: '0 14px' },
                                '& .MuiInputBase-input': { height: '34px', padding: '0 14px' }
                            }
                        }}
                    />
                </Box>
                {loading ? (
                    <Skeleton variant="rectangular" width="100%" height={400} sx={{ marginTop: '16px', borderRadius: '8px' }} />
                ) : (
                    <Paper
                        sx={{
                            width: "100%",
                            borderRadius: "8px",
                            border: "1px solid var(--Gray-200, #EAECF0)",
                            background: "var(--White, #FFF)",
                            boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                            marginTop: '16px',
                        }}
                    >
                        <Typography sx={{ color: "var(--Gray-900, #101828)", padding: "20px 24px" }}>
                            Booking Details
                        </Typography>

                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={exampleBookings}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            paginationMode="client"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my: 1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    </Paper>
                )}
            </Box>
        </Box>
    );
};

export default AgentDashboard;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchProductVariants,
    createProductVariant,
    updateProductVariant,
} from '../../../../../../redux/productSlice';
import {
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import VariantForm from './VariantForm';
import VariantPreview from './VariantPreview';

const ProductVariant = ({ productId }) => {
    const dispatch = useDispatch();
    const fetchedVariants = useSelector(state => state.products.productVariants[productId]?.data || []);
    const product = useSelector(state => state.products.products.find(p => p.id === parseInt(productId)));
    const [variants, setVariants] = useState([]);
    const [selectedVariantIndexes, setSelectedVariantIndexes] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    useEffect(() => {
        const fetchVariants = async () => {
            if (productId) {
                try {
                    setLoading(true);
                    await dispatch(fetchProductVariants(productId)).unwrap();
                    setError(null);
                } catch (err) {
                    console.error("Error fetching product variants:", err);
                    setError("An error occurred while retrieving product variants. Please try again later.");
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchVariants();
    }, [dispatch, productId]);

    useEffect(() => {
        if (fetchedVariants.length > 0) {
            const convertedVariants = fetchedVariants.reduce((acc, variant) => {
                const existingVariant = acc.find(v => v.optionName === variant.variant_name);
                if (existingVariant) {
                    existingVariant.optionValues.push(variant.variant_value);
                    existingVariant.prices.push(variant.price);
                    existingVariant.ids.push(variant.id);
                    existingVariant.variantImages.push(variant.variant_image);
                } else {
                    acc.push({
                        id: Date.now() + Math.random(),
                        optionName: variant.variant_name,
                        optionValues: [variant.variant_value],
                        prices: [variant.price],
                        ids: [variant.id],
                        variantImages: [variant.variant_image]
                    });
                }
                return acc;
            }, []);
            setVariants(convertedVariants);
        }
    }, [fetchedVariants]);

    useEffect(() => {
        if (variants.length > 0) {
            const initialIndexes = variants.reduce((acc, variant, index) => {
                acc[index] = 0;
                return acc;
            }, {});
            setSelectedVariantIndexes(initialIndexes);
        }
    }, [variants]);

    const addVariant = () => {
        const newVariant = {
            id: Date.now(),
            optionName: '',
            optionValues: [''],
            prices: [''],
            ids: [],
            variantImages: ['']
        };
        setVariants([...variants, newVariant]);
    };

    const updateVariant = (id, field, value) => {
        const newVariants = variants.map(variant =>
            variant.id === id ? { ...variant, [field]: value } : variant
        );
        setVariants(newVariants);
    };

    const updateVariantValue = (id, field, index, value) => {
        const newVariants = variants.map(variant => {
            if (variant.id === id) {
                const newValues = [...variant[field]];
                newValues[index] = value;
                return { ...variant, [field]: newValues };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const addOptionValue = (variantId) => {
        const newVariants = variants.map(variant => {
            if (variant.id === variantId) {
                return {
                    ...variant,
                    optionValues: [...variant.optionValues, ''],
                    prices: [...variant.prices, ''],
                    ids: [...(variant.ids || []), null],
                    variantImages: [...(variant.variantImages || []), '']
                };
            }
            return variant;
        });
        setVariants(newVariants);
    };

    const removeVariant = (id) => {
        const newVariants = variants.filter(variant => variant.id !== id);
        setVariants(newVariants);
    };

    const removeOptionValue = (variantId, valueIndex) => {
        setVariants(prevVariants => prevVariants.map(variant => {
            if (variant.id === variantId) {
                return {
                    ...variant,
                    optionValues: variant.optionValues.filter((_, i) => i !== valueIndex),
                    prices: variant.prices.filter((_, i) => i !== valueIndex),
                    ids: variant.ids ? variant.ids.filter((_, i) => i !== valueIndex) : [],
                    variantImages: variant.variantImages ? variant.variantImages.filter((_, i) => i !== valueIndex) : []
                };
            }
            return variant;
        }));
    };

    const handleSave = async () => {
        if (!productId) {
            setSnackbar({
                open: true,
                message: 'Please save the product details first before adding variants.',
                severity: 'warning'
            });
            return;
        }

        setLoading(true);
        try {
            const variantsToSave = variants.flatMap(variant =>
                variant.optionValues.map((value, index) => ({
                    id: variant.ids ? variant.ids[index] : null,
                    variant_name: variant.optionName,
                    variant_value: value,
                    price: variant.prices[index] || "0.00",
                    variant_image: variant.variantImages ? variant.variantImages[index] || null : null
                }))
            );

            let successCount = 0;
            let errorCount = 0;

            for (let variant of variantsToSave) {
                try {
                    if (variant.id) {
                        await dispatch(updateProductVariant({
                            productId,
                            variantId: variant.id,
                            variantData: {
                                id: variant.id,
                                product_id: productId,
                                variant_name: variant.variant_name,
                                variant_value: variant.variant_value,
                                price: variant.price,
                                variant_image: variant.variant_image
                            }
                        })).unwrap();
                    } else {
                        await dispatch(createProductVariant({
                            productId,
                            variantData: {
                                variant_name: variant.variant_name,
                                variant_value: variant.variant_value,
                                price: variant.price,
                                variant_image: variant.variant_image
                            }
                        })).unwrap();
                    }
                    successCount++;
                } catch (err) {
                    console.error("Error saving variant:", err);
                    errorCount++;
                }
            }

            setSnackbar({
                open: true,
                message: errorCount === 0
                    ? 'Product variants saved successfully!'
                    : `${successCount} variants saved, ${errorCount} failed`,
                severity: errorCount === 0 ? 'success' : 'warning'
            });

            if (successCount > 0) {
                await dispatch(fetchProductVariants(productId)).unwrap();
            }

        } catch (err) {
            console.error("Error in save process:", err);
            setSnackbar({
                open: true,
                message: 'An error occurred while saving the variants. Please try again.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleQuantityChange = (amount) => {
        if (typeof amount === 'number') {
            setQuantity(prevQuantity => Math.max(1, prevQuantity + amount));
        } else {
            const newQuantity = Math.max(1, parseInt(amount) || 1);
            setQuantity(newQuantity);
        }
    };

    const handleOptionSelect = (variantIndex, optionIndex) => {
        setSelectedVariantIndexes(prev => ({
            ...prev,
            [variantIndex]: optionIndex
        }));
    };

    const calculatePrice = () => {
        return variants.reduce((total, variant, index) => {
            const selectedOptionIndex = selectedVariantIndexes[index] || 0;
            return total + (parseFloat(variant.prices[selectedOptionIndex]) || 0);
        }, 0).toFixed(2);
    };

    return (
        <Box sx={{ display: 'flex', maxWidth: '100%', padding: 2 }}>
            <VariantForm
                variants={variants}
                error={error}
                addVariant={addVariant}
                updateVariant={updateVariant}
                updateVariantValue={updateVariantValue}
                addOptionValue={addOptionValue}
                removeVariant={removeVariant}
                removeOptionValue={removeOptionValue}
                handleSave={handleSave}
                loading={loading}
                product={product}
            />
            <VariantPreview
                variants={variants}
                selectedVariantIndexes={selectedVariantIndexes}
                handleOptionSelect={handleOptionSelect}
                quantity={quantity}
                handleQuantityChange={handleQuantityChange}
                calculatePrice={calculatePrice}
                product={product}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductVariant;
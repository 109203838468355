import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const LineChartComponent = ({ data, timeRange }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (!data || data.length === 0) {
            return; // Don't create a chart if there's no data
        }

        const ctx = chartRef.current.getContext('2d');

        const getWeekNumber = (dateString) => {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                console.error('Invalid date:', dateString);
                return null;
            }
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            const week1 = new Date(date.getFullYear(), 0, 4);
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
        };


        const formatData = () => {
            switch(timeRange) {
                case 'daily':
                    return data.map(item => ({
                        label: new Date(item.sale_date).getDate().toString(), // Only the day
                        value: parseFloat(item.total_sales)
                    }));
                case 'weekly':
                    return data.map(item => {
                        const weekNumber = getWeekNumber(item.sale_date);
                        return {
                            label: weekNumber ? `Week ${weekNumber}` : 'Unknown',
                            value: parseFloat(item.total_sales)
                        };
                    });
                case 'monthly':
                    return data.map(item => ({
                        label: new Date(item.month + '-01').toLocaleString('default', { month: 'short', year: 'numeric' }),
                        value: parseFloat(item.total_sales)
                    }));
                case 'quarterly':
                    return data.map(item => ({
                        label: `Q${item.quarter} ${item.year}`,
                        value: parseFloat(item.total_sales)
                    }));
                default:
                    return [];
            }
        };

        const formattedData = formatData();

        chartInstanceRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: formattedData.map(item => item.label),
                datasets: [{
                    label: 'Sales',
                    data: formattedData.map(item => item.value),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    fill: true,
                    tension: 0.1,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'category',
                        title: {
                            display: true,
                            text: timeRange === 'daily' ? 'Day of Month' : timeRange.charAt(0).toUpperCase() + timeRange.slice(1),
                        },
                        ticks: {
                            maxRotation: 0,
                            autoSkip: true,
                            maxTicksLimit: 10
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Sales (RM)',
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: function(tooltipItems) {
                                if (timeRange === 'daily') {
                                    const date = new Date(data[tooltipItems[0].dataIndex].sale_date);
                                    return date.toLocaleDateString();
                                }
                                return tooltipItems[0].label;
                            }
                        }
                    },
                },
            },
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [data, timeRange]);

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    return <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default LineChartComponent;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import App from './App';
import theme from './theme';
import keycloak from './keycloak'; // Ensure this path is correct
import ErrorBoundary from './components/ErrorBoundary';
import {persistor, store} from './redux/store';
import { Helmet } from "react-helmet";
// import CircularProgress from "@mui/material/CircularProgress";
// import {PersistGate} from "redux-persist/es/integration/react";

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const renderApp = () => {
    root.render(
        <ErrorBoundary>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <Provider store={store}>
                        {/*<PersistGate loading={<CircularProgress />} persistor={persistor}>*/}
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        {/*</PersistGate>*/}
                    </Provider>
                </ThemeProvider>
            </React.StrictMode>
        </ErrorBoundary>
    );
};

// Initialize Keycloak only once
const initKeycloak = async () => {
    try {
        const authenticated = await keycloak.init({
            onLoad: 'login-required', // Change this from 'check-sso' to 'login-required'
            // onLoad: 'check-sso',
            flow: 'standard',  // Try this if using public client
            silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            pkceMethod: 'S256',
            responseMode: 'fragment',
            checkLoginIframe: true,
            checkLoginIframeInterval: 30,  // Increase interval if needed
        });

        if (authenticated) {
            // Set up minimal token refresh
            setInterval(() => {
                keycloak.updateToken(70);
            }, 60000);
        }

        renderApp();
    } catch (error) {
        console.error('Keycloak init error:', error);
        renderApp();
    }
};

initKeycloak();
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Async thunk for fetching theme components
export const fetchThemeComponents = createAsyncThunk(
    'themeComponents/fetchThemeComponents',
    async ({ page = 1, limit = 10, organization_id }, { rejectWithValue }) => {
        // console.log('Fetching theme components with params:', { page, limit, organization_id });
        try {
            const response = await axios.get(`${API_BASE_URL}/theme-components`, {
                params: { page, limit, organization_id }
            });
            // console.log('Theme components data:', response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

// Async thunk for updating a theme component
export const updateThemeComponent = createAsyncThunk(
    'themeComponents/updateThemeComponent',
    async ({ id, themeComponentData }, { rejectWithValue }) => {
        console.log('handle save 4 = ', id + '&componentData=' + JSON.stringify(themeComponentData));
        try {
            const response = await axios.patch(`${API_BASE_URL}/theme-components?id=${id}`, themeComponentData);
            console.log('handle save 5', response);
            return response.data;
        } catch (error) {
            console.error('API error:', error);
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const themeComponentsSlice = createSlice({
    name: 'themeComponents',
    initialState: {
        themeComponents: [],
        pagination: {
            currentPage: 1,
            totalPages: 1,
            totalCount: 0,
            limit: 10
        },
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchThemeComponents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchThemeComponents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.themeComponents = action.payload.data.themeComponents;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchThemeComponents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch theme components';
            })
            .addCase(updateThemeComponent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Update the specific theme component in the state
                const index = state.themeComponents.findIndex(component => component.id === action.payload.data.id);
                console.log('index for state updateThemeComponent', index);
                if (index !== -1) {
                    state.themeComponents[index] = {
                        ...state.themeComponents[index],
                        ...action.payload.data
                    };
                } else {
                    // If the component doesn't exist in the state, add it
                    state.themeComponents.push(action.payload.data);
                }
            })
    }
});

export const selectAllThemeComponents = (state) => state.themeComponents?.themeComponents || [];
export const selectThemeComponentsStatus = (state) => state.themeComponents?.status || 'idle';
export const selectThemeComponentsError = (state) => state.themeComponents?.error || null;

export default themeComponentsSlice.reducer;
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api'

// Helper function to handle errors
const handleError = (error, operation) => {
    console.error(`Error ${operation}:`, error);
    throw error;
};

// GET - Fetch all coupons (with pagination and search)
export const fetchCoupons = async (page, pageSize, searchQuery) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/coupons`, {
            params: {
                page: page + 1,
                limit: pageSize,
                search: searchQuery
            }
        });
        return response.data;
    } catch (error) {
        handleError(error, 'fetching coupons');
    }
};

// GET - Fetch a single coupon by ID
export const fetchCouponById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/coupons/${id}`);
        return response.data;
    } catch (error) {
        handleError(error, `fetching coupon with id ${id}`);
    }
};

// POST - Create a new coupon
export const createCoupon = async (couponData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/coupons`, couponData);
        return response.data;
    } catch (error) {
        handleError(error, 'creating coupon');
    }
};

// PATCH - Update an existing coupon
export const updateCoupon = async (id, couponData) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/coupons?id=${id}`, couponData);
        return response.data;
    } catch (error) {
        handleError(error, `updating coupon with id ${id}`);
    }
};

// DELETE - Delete a coupon
export const deleteCoupon = async (id) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/coupons?id=${id}`);
        return response.data;
    } catch (error) {
        handleError(error, `deleting coupon with id ${id}`);
    }
};

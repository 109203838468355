import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
    Button,
    TextField,
    Box,
    IconButton,
    Typography,
    Paper,
    Divider,
    Autocomplete,
    Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import { styled } from "@mui/system";
import { Editor, EditorState, RichUtils, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { createEmail } from '../../../redux/emailSlice';
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '212px',
}));

const CustomEditor = ({ editorState, onEditorChange }) => {
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onEditorChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (inlineStyle) => {
        onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
                <IconButton onClick={() => toggleInlineStyle('BOLD')}>
                    <FormatBoldIcon />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle('ITALIC')}>
                    <FormatItalicIcon />
                </IconButton>
                <IconButton onClick={() => toggleInlineStyle('UNDERLINE')}>
                    <FormatUnderlinedIcon />
                </IconButton>
            </Box>
            <Box sx={{ border: '1px solid #ccc', borderRadius: '12px', minHeight: '200px', padding: '0 10px 10px 10px' }}>
                <Editor
                    editorState={editorState}
                    onChange={onEditorChange}
                    handleKeyCommand={handleKeyCommand}
                />
            </Box>
        </Box>
    );
};

const placeholderHtml = `
<h2>Welcome to Our Store!</h2>
<p>Dear valued customer,</p>
<p>We're excited to announce our new summer collection. Check out our latest styles and enjoy exclusive discounts!</p>
<p>Use code SUMMER2023 for 20% off your next purchase.</p>
<p>Thank you for choosing us!</p>
<p>Best regards,<br>The Store Team</p>
`;

const AddEmail = ({ onSuccess }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const status = useSelector(state => state.email.status);
    const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState(() => {
        const blocksFromHTML = convertFromHTML(placeholderHtml);
        return EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        ));
    });
    const [showPreview, setShowPreview] = useState(true);
    const [htmlContent, setHtmlContent] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        const html = stateToHTML(content.getCurrentContent());
        setHtmlContent(html);
    }, [content]);

    useEffect(() => {
        if (status === 'succeeded') {
            setOpenSnackbar(true);
            setTimeout(() => {
                navigate('/admin/email-management', { state: { newItemAdded: true } });
            }, 2000);
        }
    }, [status, navigate]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/ref-email-categories`);
            const data = response.data;
            if (data.code === 200 && data.data && data.data.refEmailCategories) {
                setCategories(data.data.refEmailCategories);
            } else {
                console.error('Unexpected API response format:', data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleAddCategory = async (newCategory) => {
        if (newCategory.trim() === '') return;
        console.log("New category added:", newCategory);
        setCategories([...categories, { name: newCategory }]);
        setSelectedCategory(newCategory);
        setInputValue('');
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
    };

    const handleContentChange = (newEditorState) => {
        setContent(newEditorState);
    };

    const handleSaveAdd = async () => {
        const newEmail = {
            title,
            subject,
            category: selectedCategory,
            content: htmlContent,
        };

        try {
            await dispatch(createEmail(newEmail)).unwrap();
            onSuccess('ConfirmationEmail created successfully');
            // Reset form fields after successful creation
            setTitle('');
            setSubject('');
            setSelectedCategory(null);
            setContent(EditorState.createEmpty());
        } catch (error) {
            onSuccess('Failed to create email: ' + error.message, 'error');
        }
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const options = useMemo(() => {
        const existingCategories = categories.map((category) => category.name);
        const addNewOption = {
            inputValue: inputValue,
            name: `${inputValue}`,
        };
        return [addNewOption, ...existingCategories];
    }, [categories, inputValue]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#f5f5f5' }}>
                <Typography variant="h4">Add New Email</Typography>
                <Box>
                    <IconButton onClick={togglePreview} sx={{ mr: 1 }}>
                        {showPreview ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                    <Button
                        variant="save"
                        onClick={handleSaveAdd}
                        disabled={status === 'loading'}
                    >
                        {status === 'loading' ? 'Saving...' : 'Save ConfirmationEmail'}
                    </Button>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                <Box sx={{ width: showPreview ? 'calc(100% - 400px)' : '100%', p: 2, overflowY: 'auto', transition: 'width 0.3s ease' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Box display="flex" alignItems="center">
                            <TextFieldTitle>Category</TextFieldTitle>
                            <Autocomplete
                                fullWidth
                                freeSolo
                                options={options}
                                value={selectedCategory}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setSelectedCategory(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        handleAddCategory(newValue.inputValue);
                                    } else {
                                        setSelectedCategory(newValue?.name || null);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = options.filter(option => {
                                        const optionName = typeof option === 'string' ? option : option.name;
                                        return optionName && optionName.toLowerCase().includes(params.inputValue.toLowerCase());
                                    });

                                    if (params.inputValue !== '' && !filtered.some(option => {
                                        const optionName = typeof option === 'string' ? option : option.name;
                                        return optionName === params.inputValue;
                                    })) {
                                        filtered.unshift({
                                            inputValue: params.inputValue,
                                            name: "+ Add New Category",
                                        });
                                    }
                                    return filtered;
                                }}
                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.name || '';
                                }}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        {(typeof option === 'string' ? option : option.name).startsWith('Add New Category:') ? (
                                            <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <AddIcon sx={{ mr: 1 }} />
                                                {typeof option === 'string' ? option : option.name}
                                            </Typography>
                                        ) : (
                                            typeof option === 'string' ? option : option.name
                                        )}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select or add a category"
                                        variant="outlined"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                height: '44px',
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                padding: '8px 14px',
                                            },
                                        }}
                                    />
                                )}
                                size="small"
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <TextFieldTitle>Title</TextFieldTitle>
                            <TextField
                                label="Enter the title"
                                value={title}
                                onChange={handleTitleChange}
                                fullWidth
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <TextFieldTitle>Subject</TextFieldTitle>
                            <TextField
                                label="Enter the subject"
                                value={subject}
                                onChange={handleSubjectChange}
                                fullWidth
                            />
                        </Box>
                        <CustomEditor
                            editorState={content}
                            onEditorChange={handleContentChange}
                        />
                    </Box>
                </Box>
                {showPreview && (
                    <Box sx={{ width: '500px', p: 2, borderLeft: '1px solid #ccc' }}>
                        <Typography variant="h6" gutterBottom>Email Preview</Typography>
                        <Paper variant="outlined" sx={{ p: 3, mx: 'auto', borderRadius:'12px', boxShadow: 3 }}>
                            <Typography variant="h5" gutterBottom>{title}</Typography>
                            <Typography variant="subtitle1" gutterBottom>Subject: {subject}</Typography>
                            <Box sx={{ mt: 2 }}>
                                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4, mb: 2 }}>
                                <Button variant='save'>Return to cart</Button>
                                <Button variant='cancel'>Visit our store</Button>
                            </Box>
                        </Paper>
                    </Box>
                )}
            </Box>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Email template added successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AddEmail;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Divider,
    TextField,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    InputAdornment
} from "@mui/material";
import { styled } from "@mui/system";
import {
    fetchOrganizationPublicDetails,
    saveOrganizationPublicDetails,
    updateOrganizationPublicDetails
} from '../../../../redux/organizationPublicDetailsSlice';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const PublicDetails = ({ requiredFields = [], onFormSubmitSuccess }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const { details, status, error } = useSelector((state) => state.organizationPublicDetails || {});
    const [detailsData, setDetailsData] = useState({
        id: '',
        legal_business_name: '',
        business_name: '',
        statement_descriptor: '',
        shortened_descriptor: '',
        customer_support_phone: '',
        customer_support_email: '',
        customer_support_address_line_1: '',
        customer_support_address_line_2: '',
        postal_code: '',
        city: '',
        state: '',
        country: '',
        customer_support_url: '',
        business_website: ''
    });

    useEffect(() => {
        if (status === 'idle') {

            dispatch(fetchOrganizationPublicDetails());
        }
    }, [status, dispatch]);

    useEffect(() => {
        console.log(details);
        if (details && details.length > 0) {
            const fetchedDetailsData = details[0].data || {};
            setDetailsData(prevData => ({
                ...prevData,
                ...fetchedDetailsData,
            }));
        }
    }, [details]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetailsData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (Object.keys(detailsData).length === 0) {
                throw new Error('Public details data is required');
            }

            const apiData = { ...detailsData };

            let result;
            if (publicDetails.id) {
                result = await dispatch(updateOrganizationPublicDetails({ id: publicDetails.id, details: apiData })).unwrap();
            } else {
                result = await dispatch(saveOrganizationPublicDetails({ details: [{ data: apiData }] })).unwrap();
            }

            if (result.data) {
                setDetailsData(result.data);
            }

            setSnackbar({
                open: true,
                message: 'Public details saved successfully!',
                severity: 'success'
            });
            if (onFormSubmitSuccess) {
                onFormSubmitSuccess();
            }
        } catch (error) {
            console.error('Failed to save public details:', error);
            setSnackbar({
                open: true,
                message: 'Failed to save public details. Please try again.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const malaysiaStates = [
        "Johor", "Kedah", "Kelantan", "Malacca", "Negeri Sembilan",
        "Pahang", "Penang", "Perak", "Perlis", "Sabah",
        "Sarawak", "Selangor", "Terengganu", "Kuala Lumpur"
    ];

    const countries = ["Malaysia"];

    if (status === 'loading') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Legal business name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="legal_business_name"
                    value={publicDetails.legal_business_name || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter legal business name"
                    placeholder='ys event & properties sdn bhd'
                    required={requiredFields.includes('legal_business_name')}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Business name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="business_name"
                    value={publicDetails.business_name || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter business name"
                    placeholder='ORCHARD VILLA'
                    required={requiredFields.includes('business_name')}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Statement descriptor <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="statement_descriptor"
                    value={publicDetails.statement_descriptor || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter statement descriptor"
                    placeholder='ORCHARDVILLA'
                    required={requiredFields.includes('statement_descriptor')}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Shortened descriptor
                </TextFieldTitle>
                <TextField
                    name="shortened_descriptor"
                    value={publicDetails.shortened_descriptor || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter shortened descriptor"
                    placeholder='OV'
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Customer support phone number
                </TextFieldTitle>
                <TextField
                    name="customer_support_phone"
                    value={publicDetails.customer_support_phone || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter customer support phone number"
                    placeholder='14 911 2550'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg"
                                        alt="Malaysia Flag"
                                        style={{ width: '24px', height: '16px', marginRight: '8px' }}
                                    />
                                    <Typography variant="body2">+60</Typography>
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Customer support address
                </TextFieldTitle>
                <Box sx={{ width: '100%', paddingRight: { md: '50px', lg: '240px' } }}>
                    <TextField
                        name="customer_support_address_line_1"
                        value={publicDetails.customer_support_address_line_1 || ''}
                        onChange={handleInputChange}
                        fullWidth
                        label="Address line 1"
                        sx={{ marginBottom: '18px' }}
                    />

                    <TextField
                        name="customer_support_address_line_2"
                        value={publicDetails.customer_support_address_line_2 || ''}
                        onChange={handleInputChange}
                        fullWidth
                        label="Address line 2"
                        sx={{ marginBottom: '18px' }}
                    />

                    <TextField
                        name="postal_code"
                        value={publicDetails.postal_code || ''}
                        onChange={handleInputChange}
                        fullWidth
                        label="Postal code (43000)"
                        sx={{ marginBottom: '18px' }}
                    />

                    <TextField
                        name="city"
                        value={publicDetails.city || ''}
                        onChange={handleInputChange}
                        fullWidth
                        label="City"
                        sx={{ marginBottom: '18px' }}
                    />

                    <FormControl fullWidth sx={{ marginBottom: '18px' }}>
                        <InputLabel>State</InputLabel>
                        <Select
                            name="state"
                            value={publicDetails.state || ''}
                            onChange={handleInputChange}
                            label="State"
                        >
                            {malaysiaStates.map((state) => (
                                <MenuItem key={state} value={state}>
                                    {state}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: '18px' }}>
                        <InputLabel>Country</InputLabel>
                        <Select
                            name="country"
                            value={publicDetails.country || ''}
                            onChange={handleInputChange}
                            label="Country"
                        >
                            {countries.map((country) => (
                                <MenuItem key={country} value={country}>
                                    {country}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Customer support email
                </TextFieldTitle>
                <TextField
                    name="customer_support_email"
                    value={publicDetails.customer_support_email || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter customer support email"
                    placeholder='ys.event.properites@gmail.com'
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Customer support URL
                </TextFieldTitle>
                <TextField
                    name="customer_support_url"
                    value={publicDetails.customer_support_url || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter customer support URL"
                    placeholder='https://example.com/support'
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider />

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Business website
                </TextFieldTitle>
                <TextField
                    name="business_website"
                    value={publicDetails.business_website || ''}
                    onChange={handleInputChange}
                    fullWidth
                    label="Enter business website"
                    placeholder='www.orchardvilla.com.my'
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Box sx={{ marginTop: '32px', display: 'flex', gap: '16px' }}>
                <Button
                    variant="save"
                    type="submit"
                    disabled={loading}
                >
                    {loading ? 'Updating...' : (publicDetails.id ? 'Update' : 'Save') + ' Public Details'}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default PublicDetails;
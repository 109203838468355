import { useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Skeleton,
    Button,
    Divider,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    CircularProgress,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useGetSpacesQuery, useDeleteSpaceMutation } from '../../../api-services/spaceApi';
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { DeleteIcon, EditIcon, ViewIcon } from "../../../shared/IconButtons";
import ViewBookingDialog from "./ViewBookingDialog";

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const BookingSetupList = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [viewDialogOpen, setViewDialogOpen] = useState(false);
    const [selectedSpace, setSelectedSpace] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletePackageId, setDeletePackageId] = useState(null);

    // RTK Query hooks
    const { data, isLoading, isFetching, error } = useGetSpacesQuery({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        search: searchQuery
    });

    const [deleteSpace, { isLoading: isDeleting }] = useDeleteSpaceMutation();

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleEditClick = (packageItem) => {
        navigate(`/settings/booking-setup/add-booking`, {
            state: { packageData: packageItem }
        });
    };

    const handleDeleteClick = (packageId) => {
        setDeletePackageId(packageId);
        setOpenDeleteDialog(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteSpace(deletePackageId).unwrap();
            setOpenDeleteDialog(false);
            setDeletePackageId(null);
        } catch (error) {
            console.error('Failed to delete package:', error);
        }
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeletePackageId(null);
    };

    const columns = [
        {
            field: 'image',
            headerName: '',
            width: 100,
            minWidth: 100,
            flex: 0,
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '60px',
                            height: '45px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            objectFit: 'cover',
                        }}
                        alt="Spaces Image"
                        src={params.row.image_url || "/1.jpeg"}
                    />
                </Box>
            )
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 180,
            minWidth: 150,
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300,
            minWidth: 250,
            flex: 2,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <Typography variant="body2" noWrap>{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'minimum_pax',
            headerName: 'Minimum Pax',
            width: 130,
            minWidth: 120,
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <Typography variant="body2">{`${params.row.minimum_pax || ''}`}</Typography>
                </Box>
            )
        },
        {
            field: 'maximum_pax',
            headerName: 'Maximum Pax',
            width: 130,
            minWidth: 120,
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
                    <Typography variant="body2">{`${params.row.maximum_pax || ''}`}</Typography>
                </Box>
            )
        },
        // {
        //     field: 'minimum_amount',
        //     headerName: 'Minimum Amount',
        //     width: 150,
        //     minWidth: 140,
        //     flex: 0.8,
        //     renderCell: (params) => (
        //         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
        //             <Typography variant="body2">
        //                 {params.row.minimum_amount ? `$${params.row.minimum_amount.toLocaleString()}` : 'N/A'}
        //             </Typography>
        //         </Box>
        //     )
        // },
        // {
        //     field: 'maximum_amount',
        //     headerName: 'Maximum Amount',
        //     width: 150,
        //     minWidth: 140,
        //     flex: 0.8,
        //     renderCell: (params) => (
        //         <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%', height: '100%' }}>
        //             <Typography variant="body2">
        //                 {params.row.maximum_amount ? `$${params.row.maximum_amount.toLocaleString()}` : 'N/A'}
        //             </Typography>
        //         </Box>
        //     )
        // },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            minWidth: 150,
            flex: 0,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', height: '100%' }}>
                    <IconButton
                        aria-label="view"
                        onClick={() => {
                            setSelectedSpace(params.row);
                            setViewDialogOpen(true);
                        }}
                    >
                        <ViewIcon />
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteClick(params.row.id)}
                        disabled={isDeleting}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            {/* Header section */}
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                    <Box>
                        <Typography variant='h1'>Booking Setup</Typography>
                        <Typography variant='h2'>View, purchase, and manage all your booking in one place.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button component={Link} to="/settings/booking-setup/add-booking" variant="save" startIcon={<AddIcon />}>
                            Add New Booking
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Divider />

            {/* Search section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box />
                <Box display="flex" gap="12px">
                    <TextField
                        variant="outlined"
                        placeholder="Search spaces"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        size="small"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '34px',
                                '& input': {
                                    height: '34px',
                                    padding: '0 14px',
                                }
                            }
                        }}
                    />
                </Box>
            </Box>

            {/* Data Grid section */}
            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    All Bookings
                </Typography>

                {isLoading || isFetching ? (
                    <Box>
                        <Box sx={{ px: 2, py: 1 }}>
                            <Skeleton variant="rectangular" height={52} />
                        </Box>
                        {[...Array(5)].map((_, index) => (
                            <SkeletonRow key={index} />
                        ))}
                    </Box>
                ) : error ? (
                    <Typography color="error" sx={{ p: 2 }}>
                        {error?.data?.message || 'Error loading spaces'}
                    </Typography>
                ) : (
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={data?.data?.spaces || []}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[5, 10, 20]}
                        rowCount={data?.data?.pagination?.totalCount || 0}
                        paginationMode="server"
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        loading={isLoading || isFetching}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                backgroundColor: '#737373',
                                color: '#fff',
                                height: '34px',
                                my: 1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: '#404040',
                                },
                            },
                        }}
                    />
                )}
            </Paper>

            {/* View Dialog */}
            <ViewBookingDialog
                open={viewDialogOpen}
                onClose={() => {
                    setViewDialogOpen(false);
                    setSelectedSpace(null);
                }}
                spaceData={selectedSpace}
            />

            {/* Delete Dialog */}
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this item? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="cancel"
                        onClick={handleCloseDeleteDialog}
                        disabled={isDeleting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="save"
                        onClick={handleConfirmDelete}
                        disabled={isDeleting}
                    >
                        {isDeleting ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Delete'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default BookingSetupList;
import {
    Box,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    styled
} from '@mui/material';

const DurationField = styled(Box)({
    '& .MuiTextField-root': {
        marginRight: '8px'
    }
});

const CustomDuration = ({ settings, handleSettingChange }) => {
    return (
        <>
            <Box mb={3}>
                <Typography sx={{ mb: 1, color: '#344054' }}>Minimum Duration</Typography>
                <DurationField>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={settings.minDurationHours}
                                onChange={(e) => handleSettingChange('minDurationHours', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={settings.minDurationMinutes}
                                onChange={(e) => handleSettingChange('minDurationMinutes', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DurationField>
            </Box>
            <Box mb={3}>
                <Typography sx={{ mb: 1, color: '#344054' }}>Maximum Duration</Typography>
                <DurationField>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={settings.maxDurationHours}
                                onChange={(e) => handleSettingChange('maxDurationHours', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={settings.maxDurationMinutes}
                                onChange={(e) => handleSettingChange('maxDurationMinutes', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DurationField>
            </Box>
            <Box mb={3}>
                <Typography sx={{ mb: 1, color: '#344054' }}>Time Increment</Typography>
                <DurationField>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={settings.timeIncrementHours}
                                onChange={(e) => handleSettingChange('timeIncrementHours', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                value={settings.timeIncrementMinutes}
                                onChange={(e) => handleSettingChange('timeIncrementMinutes', e.target.value)}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DurationField>
            </Box>
        </>
    );
};

export default CustomDuration;
import { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    InputAdornment,
    styled,
    ToggleButton,
    ToggleButtonGroup,
    Alert,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PaymentDeadlines from './PaymentDeadlines';
import DepositRefundPolicy from "./DepositRefundPolicy";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const StyledFormControlLabel = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '24px',
});

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#F9FAFB',
    padding: '4px',
    borderRadius: '8px',
    marginBottom: '24px',
    '& .MuiToggleButton-root': {
        flex: 1,
        border: 'none',
        borderRadius: '6px !important',
        color: '#344054',
        backgroundColor: 'transparent',
        textTransform: 'none',
        '&.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: '#101828',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1)',
        }
    }
}));

const StyledTextField = styled(TextField)({
    marginBottom: '24px',
});

const SubSectionTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#101828',
    marginBottom: '8px'
});

const SubSectionSubtext = styled(Typography)({
    fontSize: '14px',
    color: '#667085',
    marginBottom: '16px'
});

const DepositSettings = ({ onChange }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [depositType, setDepositType] = useState('fixed');
    const [refundRules, setRefundRules] = useState([]);
    const [depositSettings, setDepositSettings] = useState({
        amount: '100.00',
        percentage: '50',
        installmentPayments: false,
        depositDueHours: '24',
        paymentRules: [
            {
                id: 1,
                type: 'before_event',
                value: '30',
                percentage: '50'
            }
        ],
        refundPolicy: {
            'non-refundable': true,
            'full-refund': false,
            'partial-refund': false,
        },
        customPolicy: ''
    });

    const handleChange = (field, value) => {
        const newSettings = { ...depositSettings, [field]: value };
        setDepositSettings(newSettings);
        if (onChange) {
            onChange({ enabled: isEnabled, type: depositType, ...newSettings });
        }
    };

    const handleDepositTypeChange = (event, newType) => {
        if (newType !== null) {
            setDepositType(newType);
        }
    };

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Deposit Settings
            </TextFieldTitle>

            <Box sx={{ width: '100%' }}>
                <StyledFormControlLabel>
                    <LabelContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography className="heading">Booking Deposit</Typography>
                        </Box>
                        <Typography className="subtext">Collect deposits to secure bookings</Typography>
                    </LabelContent>
                    <Switch
                        checked={isEnabled}
                        onChange={(e) => setIsEnabled(e.target.checked)}
                        color="primary"
                    />
                </StyledFormControlLabel>
                <Alert
                    icon={<InfoOutlinedIcon />}
                    severity="info"
                    sx={{ mb: 3, backgroundColor: '#F9FAFB', color: '#101828' }}
                >
                    Enabling deposit requirements can help reduce no-shows and secure bookings. Consider your target market and booking value when setting deposit amounts.
                </Alert>

                {isEnabled && (
                    <>
                        <StyledToggleButtonGroup
                            value={depositType}
                            exclusive
                            onChange={handleDepositTypeChange}
                            aria-label="deposit type"
                        >
                            <ToggleButton value="fixed">Fixed Amount</ToggleButton>
                            <ToggleButton value="percentage">Percentage</ToggleButton>
                        </StyledToggleButtonGroup>

                        {depositType === 'fixed' ? (
                            <StyledTextField
                                fullWidth
                                label="Deposit Amount"
                                value={depositSettings.amount}
                                onChange={(e) => handleChange('amount', e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                                }}
                            />
                        ) : (
                            <StyledTextField
                                fullWidth
                                label="Deposit Percentage"
                                value={depositSettings.percentage}
                                onChange={(e) => handleChange('percentage', e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        )}

                        <Box sx={{ mb: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                                <Box>
                                    <SubSectionTitle>Installment Payments</SubSectionTitle>
                                    <SubSectionSubtext>Allow customers to pay remaining balance in installments</SubSectionSubtext>
                                </Box>
                                <Switch
                                    checked={depositSettings.installmentPayments}
                                    onChange={(e) => handleChange('installmentPayments', e.target.checked)}
                                    color="primary"
                                />
                            </Box>
                        </Box>

                        {/*Payment Deadlines*/}
                        <PaymentDeadlines
                            depositDueHours={depositSettings.depositDueHours}
                            paymentRules={depositSettings.paymentRules}
                            onDepositDueChange={(value) => handleChange('depositDueHours', value)}
                            onPaymentRulesChange={(rules) => handleChange('paymentRules', rules)}
                        />

                        {/* Deposit Refund Policy */}
                        <DepositRefundPolicy
                            refundPolicy={depositSettings.refundPolicy}
                            customPolicy={depositSettings.customPolicy}
                            refundRules={refundRules}
                            onRefundPolicyChange={(policy) => handleChange('refundPolicy', policy)}
                            onCustomPolicyChange={(value) => handleChange('customPolicy', value)}
                            onRefundRulesChange={setRefundRules}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
};

export default DepositSettings;
import {Box, Button, Divider, InputAdornment, Tab, Tabs, TextField, Typography} from '@mui/material';

import Published from "./ServiceTable-published";
import Draft from "./ServiceTable-draft";
import {Add as AddIcon} from "@mui/icons-material";
import React, {useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

const ServiceTable = () => {
    const [value, setValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/services-form');
    };

    return (
        <Box height='100vh' sx={{ padding: '32px', backgroundColor: '#f5f5f5'}}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Typography variant='h1'>
                        Services
                    </Typography>
                    <Typography variant='h2'>
                        View your organization's services.
                    </Typography>
                </Box>
                <Button variant="save" color="primary" startIcon={<AddIcon />} sx={{height:'40px'}} onClick={handleClick}>
                    Add New Service
                </Button>
            </Box>

            <Box height='38px' sx={{display: 'flex', paddingTop:'32px', paddingBottom:'42px'}}>
                <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                    <Tab label="Published" />
                    <Tab label="Draft" />
                </Tabs>
            </Box>

            <Divider sx={{margin: '5px 0 0 0'}}/>

            <Box sx={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-between', width: '100%' }}>
                <TextField
                    placeholder="Search for services"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ height: '44px', width: '400px', flexShrink: 0 }}
                />
                <Box display='flex' alignItems="center">
                    <Button variant="cancel" sx={{ height: '40px', marginRight: '12px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                            <path
                                d="M13.3333 1.6665V4.99984M6.66667 1.6665V4.99984M2.5 8.33317H17.5M4.16667 3.33317H15.8333C16.7538 3.33317 17.5 4.07936 17.5 4.99984V16.6665C17.5 17.587 16.7538 18.3332 15.8333 18.3332H4.16667C3.24619 18.3332 2.5 17.587 2.5 16.6665V4.99984C2.5 4.07936 3.24619 3.33317 4.16667 3.33317Z"
                                stroke="#344054" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        Dec 2023 – Jan 2024
                    </Button>
                    <Button variant="cancel" sx={{ height: '40px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
                            <path d="M5 10H15M2.5 5H17.5M7.5 15H12.5" stroke="#344054" strokeWidth="1.67"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Filters
                    </Button>
                </Box>
            </Box>

            <Box>
                {value === 0 && <Published/>}
                {value === 1 && <Draft/>}
            </Box>
        </Box>
    );
};

export default ServiceTable;

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    Select,
    MenuItem,
    Button,
    styled
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px'
});

const DurationCard = styled(Box)({
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px'
});

const AddButton = styled(Button)({
    color: '#1570EF',
    fontSize: '14px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
    }
});

// Modified StyledTextField to ensure the input doesn't overlap with the prefix
const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& input': {
            paddingLeft: '44px'
        },
        // Ensure the outline appears above background elements
        '& .MuiOutlinedInput-notchedOutline': {
            zIndex: 1
        }
    },
    // Create a position context for the absolute positioning
    position: 'relative'
});

// Modified RatePrefix to ensure it appears above the TextField background
const RatePrefix = styled(Box)({
    position: 'absolute',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#344054',
    fontSize: '16px',
    zIndex: 2,  // Higher z-index to appear above the TextField
    pointerEvents: 'none'  // Ensure it doesn't interfere with TextField interaction
});

// Wrapper component for the rate input field
const RateInputWrapper = styled(Box)({
    position: 'relative',
    width: '100%'
});

const BasePrice = () => {
    const [baseRate, setBaseRate] = useState('200.00');
    const [minDuration, setMinDuration] = useState('1');
    const [durationRules, setDurationRules] = useState([
        {
            type: 'more',
            duration: '2',
            rate: '180.00'
        }
    ]);

    const handleAddDurationRule = () => {
        setDurationRules([...durationRules, {
            type: 'more',
            duration: '',
            rate: ''
        }]);
    };

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Base Price
            </TextFieldTitle>
            <Box sx={{ width: '100%' }}>
                {/* Base Price Section */}
                <Box display="flex" gap={2} mb={3}>
                    <Box flex={1}>
                        <Typography sx={{ mb: 1, color: '#344054' }}>Base Rate (per hour)</Typography>
                        <RateInputWrapper>
                            <RatePrefix>RM</RatePrefix>
                            <StyledTextField
                                fullWidth
                                value={baseRate}
                                onChange={(e) => setBaseRate(e.target.value)}
                                placeholder="0.00"
                            />
                        </RateInputWrapper>
                    </Box>
                    <Box flex={1}>
                        <Typography sx={{ mb: 1, color: '#344054' }}>Minimum Duration</Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <TextField
                                fullWidth
                                value={minDuration}
                                onChange={(e) => setMinDuration(e.target.value)}
                                type="number"
                            />
                            <Typography sx={{ color: '#344054', whiteSpace: 'nowrap' }}>hour(s)</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BasePrice;
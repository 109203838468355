import React, {useState, useEffect, useMemo} from 'react';
import {
    Box,
    Typography,
    Paper,
    Button,
    Divider,
    Grid,
    Dialog,
    DialogContent,
    AccordionSummary,
    AccordionDetails,
    Accordion,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { couponsOptions } from '../shared/CouponsOptions';
import SpacesListExamples from '../spaces/SpacesListExamples';
import SelectPayment from './CheckoutContents/SelectPayment';
import VoucherCard from './CheckoutContents/SelectCoupon';
import SelectDate from './CheckoutContents/SelectDate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReviewPay from './CheckoutContents/ReviewPay';
import SelectProduct from "./SelectProduct";
import CustomerInfoForm from "./CustomerInfoForm";
import PaymentSummary from './PaymentSummary';

const CheckoutPage = () => {
    const spaces = SpacesListExamples();
    const [selectedSpace, setSelectedSpace] = useState(spaces[0]);
    const [selectedCoupon, setSelectedCoupon] = useState(couponsOptions[0]);
    const [openPaymentSuccessDialog, setOpenPaymentSuccessDialog] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({
        firstName: '',
        lastName: '',
        mobilePhone: '',
        email: '',
    });
    const [selectedVariants, setSelectedVariants] = useState([]);
    const [bookingFee, setBookingFee] = useState(5.00);

    const priceValue = parseFloat(
        selectedSpace.price.split(' - ')[0].replace(/[^0-9.-]+/g, '')
    );
    const discountAmount = selectedCoupon
        ? parseFloat(selectedCoupon.discount.replace(/[^0-9.-]+/g, ''))
        : 0;
    const finalPrice = priceValue - discountAmount;

    const handleConfirmPayment = () => {
        setOpenPaymentSuccessDialog(true);
    };

    useEffect(() => {
        console.log("Selected Variants updated:", selectedVariants);
    }, [selectedVariants]);

    const handleCustomerInfoChange = (event) => {
        const { name, value } = event.target;
        setCustomerInfo(prevInfo => ({
            ...prevInfo,
            [name]: value
        }));
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleSaveCustomerInfo = () => {
        console.log('Customer info saved:', customerInfo);
    };

    const totalAmount = useMemo(() => {
        const subtotal = selectedVariants.reduce((total, variant) => {
            return total + (Number(variant.price) * variant.quantity);
        }, 0);
        return subtotal + bookingFee;
    }, [selectedVariants, bookingFee]);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper sx={{
                        padding: { xs: '18px 8px', sm: '32px' },
                        backgroundColor: '#f5f5f5',
                        minHeight: '100vh',
                        borderRadius:'12px',
                    }}>
                        <Typography variant='h5' fontWeight='bold' gutterBottom sx={{
                            fontSize: { xs: '1.5rem', sm: '1.7rem' },
                            padding: { xs: '8px 0', sm: '8px 32px' },
                        }}>
                            Confirm and Pay
                        </Typography>

                        <Divider />

                        {/*<Typography variant='h6' gutterBottom sx={{*/}
                        {/*    fontSize: { xs: '1rem', sm: '1.25rem' },*/}
                        {/*    padding: { xs: 0, sm: '8px 32px' },*/}
                        {/*}}>*/}
                        {/*    Select Event Date*/}
                        {/*</Typography>*/}
                        {/*<SelectDate />*/}

                        {/*<Typography variant='h6' gutterBottom sx={{*/}
                        {/*    fontSize: { xs: '1rem', sm: '1.25rem' },*/}
                        {/*    padding: { xs: 0, sm: '8px 32px' },*/}
                        {/*}}>*/}
                        {/*    Select Product/Package*/}
                        {/*</Typography>*/}
                        {/*<SelectProduct*/}
                        {/*    setSelectedVariants={setSelectedVariants}*/}
                        {/*    setBookingFee={setBookingFee}*/}
                        {/*/>*/}

                        <Divider />

                        <Typography variant='h6' gutterBottom sx={{
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            padding: { xs: 0, sm: '8px 32px' },
                        }}>
                            Customer Information
                        </Typography>
                        <Box sx={{ padding: { xs: 0, sm: '0 32px' }, mb: '12px' }}>
                            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="customer-info-content"
                                    id="customer-info-header"
                                >
                                    <Typography variant='h6'>Customer Information</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CustomerInfoForm
                                        customerInfo={customerInfo}
                                        handleCustomerInfoChange={handleCustomerInfoChange}
                                        handleSaveCustomerInfo={handleSaveCustomerInfo}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Divider />

                        {/* Payment Summary */}
                        <Box sx={{ padding: { xs: 0, sm: '0 32px' }, mb: '12px' }}>
                            <Typography variant='h6' gutterBottom sx={{
                                fontSize: { xs: '1rem', sm: '1.25rem' },
                                padding: { xs: 0, sm: '8px 0' },
                            }}>
                                Payment Summary
                            </Typography>
                            <PaymentSummary
                                selectedVariants={selectedVariants}
                                bookingFee={bookingFee}
                            />
                        </Box>

                        <Divider />

                        <Typography variant='h6' gutterBottom sx={{
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            padding: { xs: 0, sm: '8px 32px' },
                        }}>
                            Select Coupon
                        </Typography>
                        <VoucherCard />

                        <Divider />

                        <Typography variant='h6' gutterBottom sx={{
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            padding: { xs: 0, sm: '8px 32px' },
                        }}>
                            Select Payment Method
                        </Typography>
                        <SelectPayment />

                        <Divider />

                        {/*<Typography variant='h6' sx={{*/}
                        {/*    fontSize: { xs: '1rem', sm: '1.25rem' },*/}
                        {/*    textAlign: { xs: 'center', sm: 'left' },*/}
                        {/*    padding: { xs: 0, sm: '8px 32px' },*/}
                        {/*    mb: 1,*/}
                        {/*}}>*/}
                        {/*    Payment Plan*/}
                        {/*</Typography>*/}
                        <ReviewPay
                            totalAmount={totalAmount}
                            handleConfirmPayment={handleConfirmPayment}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper sx={{
                        backgroundColor: '#f5f5f5',
                        borderRadius:'12px',
                        padding:'12px',
                    }}>
                        <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: '12px' }}>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 70, height: 70, bgcolor: '#ccc', mr: 5 }} />
                                    <Box>
                                        <Typography variant="h6">
                                            Title Events
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#333' }}>
                                            Quantity: 1
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="body1" sx={{ color: '#333', textAlign: 'right' }}>
                                        RM199.99
                                    </Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt:2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 70, height: 70, bgcolor: '#ccc', mr: 5 }} />
                                    <Box>
                                        <Typography variant="h6">
                                            Title Events 2
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#333' }}>
                                            Quantity: 1
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="body1" sx={{ color: '#333', textAlign: 'right' }}>
                                        RM199.99
                                    </Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt:2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ width: 70, height: 70, bgcolor: '#ccc', mr: 5 }} />
                                    <Box>
                                        <Typography variant="h6">
                                            Title Events 3
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#333' }}>
                                            Quantity: 1
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant="body1" sx={{ color: '#333', textAlign: 'right' }}>
                                        RM199.99
                                    </Typography>
                                </Box>
                            </Box>

                            <Box sx={{backgroundColor: '#e0e0e0', height: '1px', my: 2}}/>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                                <Typography variant="body1" sx={{ color: '#333' }}>
                                    Subtotal
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#333' }}>
                                    RM599.97
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                                <Typography variant="body1" sx={{ color: '#333' }}>
                                    Shipping
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#333' }}>
                                    Free
                                </Typography>
                            </Box>
                            <Box sx={{backgroundColor: '#e0e0e0', height: '1px', my: 2}}/>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                                <Typography variant="body1" sx={{ color: '#333', fontWeight: 'bold' }}>
                                    Total
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#333', fontWeight: 'bold' }}>
                                    RM599.97
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            {/* Payment Success Dialog */}
            <Dialog
                open={openPaymentSuccessDialog}
                onClose={() => setOpenPaymentSuccessDialog(false)}
                maxWidth='xs'
                fullWidth
            >
                <DialogContent sx={{
                    textAlign: 'center',
                    padding: { xs: '24px', sm: '32px' },
                }}>
                    <CheckCircleIcon color='success' sx={{ fontSize: { xs: 40, sm: 60 } }} />
                    <Typography variant='h5' fontWeight='bold' gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                        Your payment was successful!
                    </Typography>
                    <Typography variant='body2' color='textSecondary' gutterBottom>
                        We'll email admin@mycompany.com a receipt for each invoice.
                    </Typography>
                    <Typography variant='body2' fontWeight='bold' gutterBottom>
                        Invoices
                    </Typography>
                    <Typography variant='h6' gutterBottom>
                        9999504
                    </Typography>
                    <Button variant='save' onClick={() => setOpenPaymentSuccessDialog(false)} sx={{
                        marginTop: '16px',
                        width: { xs: '100%', sm: 'auto' },
                    }}>
                        Return
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default CheckoutPage;
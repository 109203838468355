import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, TextField, Paper, Typography, Chip, Skeleton } from '@mui/material';
import { fetchAccounts } from '../../../../redux/accountSlice';

const StatusChip = ({ status }) => {
    const statusColors = {
        Active: { backgroundColor: '#4caf50', textColor: '#fff' },
        Inactive: { backgroundColor: '#f44336', textColor: '#fff' },
    };

    const { backgroundColor, textColor } = statusColors[status] || {};

    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const PaymentStatusChip = ({ status }) => {
    const statusColors = {
        Paid: { backgroundColor: '#4caf50', textColor: '#fff' },
        Upcoming: { backgroundColor: '#ff9800', textColor: '#fff' },
        Overdue: { backgroundColor: '#f44336', textColor: '#fff' },
    };

    const { backgroundColor, textColor } = statusColors[status] || {};

    return (
        <Chip
            label={status}
            sx={{
                backgroundColor,
                color: textColor,
                fontSize: '0.6rem',
                height: '20px',
                borderRadius: '4px',
                '& .MuiChip-label': { padding: '0 6px', color: textColor },
            }}
        />
    );
};

const Billing = () => {
    const dispatch = useDispatch();
    const { billing, status, error } = useSelector((state) => state.accounts);
    const [searchQuery, setSearchQuery] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        dispatch(fetchAccounts('billing'));
        // Set isInitialLoad to false after the initial fetch
        setIsInitialLoad(false);
    }, [dispatch]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const billingData = billing?.data?.accounts || [];

    const filteredRows = billingData.map((account) => ({
        id: account.id,
        name: account.user.username,
        description: account.accountStatus.description
    })).filter((row) =>
        row.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'name', headerName: 'Merchant Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
    ];

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    if (status === 'failed') {
        return <Typography color="error">Error: {error}</Typography>;
    }

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <TextField
                    variant="outlined"
                    placeholder="Search by Merchant Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '34px',
                            '& input': {
                                height: '34px',
                                padding: '0 14px',
                            },
                            '& .MuiInputBase-input': {
                                height: '34px',
                                padding: '0 14px',
                            }
                        }
                    }}
                />
            </Box>

            <Paper
                sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}
            >
                <Typography
                    sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}
                >
                    Billing
                </Typography>
                {(isInitialLoad || status === 'loading') ? (
                    renderSkeletons()
                ) : billingData.length > 0 ? (
                    <DataGrid
                        slots={{ toolbar: GridToolbar }}
                        rows={filteredRows}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        rowHeight={70}
                        pageSizeOptions={[5, 10, 20]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 6,
                                },
                            },
                        }}
                        sx={{
                            '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                backgroundColor: '#737373',
                                color: '#fff',
                                height: '34px',
                                my: 1,
                                minWidth: 'auto',
                                '&:hover': {
                                    backgroundColor: '#404040',
                                },
                            },
                        }}
                    />
                ) : (
                    <Typography sx={{ padding: '20px 24px' }}>No billing data available.</Typography>
                )}
            </Paper>
        </Box>
    );
};

export default Billing;
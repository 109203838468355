import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import SalesPerAgent from "./SalesPerAgent";
import CommissionsPerAgents from "./CommissionsPerAgents";
import Revenues from "./Revenues";
import Payout from "./Payout";

const AgentsOverview = () => {
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex', padding:{xs: 1, md: '32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Agents Overview</Typography>
                        <Typography variant='h2'>Comprehensive Insights into Your Team's Performance and Activity.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Sales per agents" />
                        <Tab label="Commissions per agents" />
                        <Tab label="Revenues" />
                        <Tab label="Payout" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <SalesPerAgent/> }
                    { value === 1 && <CommissionsPerAgents/> }
                    { value === 2 && <Revenues/> }
                    { value === 3 && <Payout/> }
                </Box>
            </Box>
        </Box>
    );
};

export default AgentsOverview;
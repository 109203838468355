import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/products`, {
                headers,
                params: {
                    page: page + 1,
                    limit: pageSize,
                    search: searchQuery,
                },
            });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createProduct = createAsyncThunk(
    'products/createProduct',
    async (productData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/products`, productData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateProduct = createAsyncThunk(
    'products/updateProduct',
    async ({ id, postData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/products?id=${id}`, postData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/products?id=${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createOrUpdateProduct = createAsyncThunk(
    'products/createOrUpdateProduct',
    async (productData, { rejectWithValue }) => {
        try {
            const token = await getToken();
            const response = await axios.post(`${API_BASE_URL}/products`, productData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error in createOrUpdateProduct:', error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchProductVariants = createAsyncThunk(
    'products/fetchProductVariants',
    async (productId, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/product-variants?productId=${productId}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createProductVariant = createAsyncThunk(
    'products/createProductVariant',
    async ({ productId, variantData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/product-variants`, variantData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateProductVariant = createAsyncThunk(
    'products/updateProductVariant',
    async ({ productId, variantId, variantData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/product-variants?id=${variantId}`, { variantId, variantData }, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteProductVariant = createAsyncThunk(
    'products/deleteProductVariant',
    async ({ productId, variantId }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/products/${productId}/variants/${variantId}`, { headers });
            return { productId, variantId };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);


const productSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        productVariants: {},
        status: 'idle',
        error: null,
        totalCount: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = action.payload.products;
                state.totalCount = action.payload.pagination.totalCount;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch products';
            })
            .addCase(createProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products.push(action.payload);
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.products.findIndex(product => product.id === action.payload.id);
                if (index !== -1) {
                    state.products[index] = action.payload;
                }
            })
            .addCase(deleteProduct.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.products = state.products.filter(product => product.id !== action.payload);
            })
            .addCase(fetchProductVariants.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.productVariants[action.meta.arg] = action.payload;
            })
            .addCase(createProductVariant.fulfilled, (state, action) => {
                const { productId, variantData } = action.payload;
                if (!state.productVariants[productId]) {
                    state.productVariants[productId] = [];
                }
                state.productVariants[productId].push(variantData);
            })
            .addCase(updateProductVariant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.productVariants[action.payload.product_id].findIndex(variant => variant.id === action.payload.id);
                if (index !== -1) {
                    state.productVariants[action.payload.product_id][index] = action.payload;
                }
            })
            .addCase(deleteProductVariant.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.productVariants[action.payload.productId] = state.productVariants[action.payload.productId].filter(variant => variant.id !== action.payload.variantId);
            });
    }
});
export default productSlice.reducer;
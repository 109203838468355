import {Box, Button, MenuItem, Select, styled, Switch, TextField, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, {useState} from "react";
import Header from "../../../../../header/Header";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const StyledFormControlLabel = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '24px',
});

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const DurationCard = styled(Box)({
    border: '1px solid #E4E7EC',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '16px'
});

const AddButton = styled(Button)({
    color: '#1570EF',
    fontSize: '14px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
    }
});

// Modified StyledTextField to ensure the input doesn't overlap with the prefix
const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& input': {
            paddingLeft: '44px'
        },
        // Ensure the outline appears above background elements
        '& .MuiOutlinedInput-notchedOutline': {
            zIndex: 1
        }
    },
    // Create a position context for the absolute positioning
    position: 'relative'
});

// Modified RatePrefix to ensure it appears above the TextField background
const RatePrefix = styled(Box)({
    position: 'absolute',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#344054',
    fontSize: '16px',
    zIndex: 2,  // Higher z-index to appear above the TextField
    pointerEvents: 'none'  // Ensure it doesn't interfere with TextField interaction
});


// Wrapper component for the rate input field
const RateInputWrapper = styled(Box)({
    position: 'relative',
    width: '100%'
});

const DurationBasePricing = () => {
    const [isDurationPricingEnabled, setIsDurationPricingEnabled] = useState(false);
    const [minDuration, setMinDuration] = useState('1');
    const [durationRules, setDurationRules] = useState([
        {
            type: 'more',
            duration: '2',
            rate: '180.00'
        }
    ]);

    const handleAddDurationRule = () => {
        setDurationRules([...durationRules, {
            type: 'more',
            duration: '',
            rate: ''
        }]);
    };
    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Duration Based Pricing
            </TextFieldTitle>
            <Box sx={{ width: '100%' }}>
                {/* Duration Based Pricing Section */}
                <StyledFormControlLabel>
                    <LabelContent>
                        <Typography className="heading">Duration Based Pricing</Typography>
                        <Typography className="subtext">Set different rates based on booking duration</Typography>
                    </LabelContent>
                    <Switch
                        checked={isDurationPricingEnabled}
                        onChange={(e) => setIsDurationPricingEnabled(e.target.checked)}
                        color="primary"
                    />
                </StyledFormControlLabel>

                {isDurationPricingEnabled && (
                    <>
                        {durationRules.map((rule, index) => (
                            <DurationCard key={index}>
                                <Box display="flex" gap={2}>
                                    <Box width="200px">
                                        <Typography sx={{ mb: 1, color: '#344054' }}>Hours</Typography>
                                        <Select
                                            fullWidth
                                            value={rule.type}
                                            onChange={(e) => {
                                                const newRules = [...durationRules];
                                                newRules[index].type = e.target.value;
                                                setDurationRules(newRules);
                                            }}
                                        >
                                            <MenuItem value="more">More than</MenuItem>
                                            <MenuItem value="less">Less than</MenuItem>
                                            <MenuItem value="between">Between</MenuItem>
                                        </Select>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography sx={{ mb: 1, color: '#344054' }}>Duration</Typography>
                                        <TextField
                                            fullWidth
                                            value={rule.duration}
                                            onChange={(e) => {
                                                const newRules = [...durationRules];
                                                newRules[index].duration = e.target.value;
                                                setDurationRules(newRules);
                                            }}
                                            type="number"
                                        />
                                    </Box>
                                    <Box flex={1}>
                                        <Typography sx={{ mb: 1, color: '#344054' }}>Rate (per hour)</Typography>
                                        <RateInputWrapper>
                                            <RatePrefix>RM</RatePrefix>
                                            <StyledTextField
                                                fullWidth
                                                value={rule.rate}
                                                onChange={(e) => {
                                                    const newRules = [...durationRules];
                                                    newRules[index].rate = e.target.value;
                                                    setDurationRules(newRules);
                                                }}
                                                placeholder="0.00"
                                            />
                                        </RateInputWrapper>
                                    </Box>
                                </Box>
                            </DurationCard>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <AddButton
                                startIcon={<AddIcon sx={{ fontSize: 16 }} />}
                                onClick={handleAddDurationRule}
                            >
                                Add Duration Rule
                            </AddButton>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}
export default DurationBasePricing;
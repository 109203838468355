import React, { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton } from '@mui/material';

// import OrderOverviewCurrent from './OrderOverviewCurrent';
// import OrderOverviewProcessing from './OrderOverviewProcessing';
// import OrderOverviewCompleted from './OrderOverviewCompleted';
import OrdersList from './OrdersList';
import QrCodeIcon from "@mui/icons-material/QrCode";
// import QRDialog from './QRDialog';

const OrdersOverview = () => {
    const [value, setValue] = useState(0);
    const [openQRScanner, setOpenQRScanner] = useState(false);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleQRIconClick = () => {
        setOpenQRScanner(true);
    };

    const handleCloseQRScanner = () => {
        setOpenQRScanner(false);
    };

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Orders Overview</Typography>
                        <Typography variant='h2'>All of your product's orders.</Typography>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Pending" />
                        <Tab label="Processing" />
                        <Tab label="Completed" />
                        <Tab label="Cancelled" />
                        <Tab label="Rejected" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    {value === 0 && <OrdersList status="pending" />}
                    {value === 1 && <OrdersList status="processing" />}
                    {value === 2 && <OrdersList status="completed" />}
                    {value === 3 && <OrdersList status="cancelled" />}
                    {value === 4 && <OrdersList status="rejected" />}
                </Box>
            </Box>
        </Box>
    );
};

export default OrdersOverview;
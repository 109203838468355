import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Skeleton, Button
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { fetchPackages, setPackagesLength } from '../../../redux/packagesSlice';
import EditPackageDialog from "./EditPackageDialog";
import PackageSetupIcon from "./PackageSetupIcon";
import AddIcon from "@mui/icons-material/Add";

const PackageList = ({ handleDialogOpen }) => {
    const dispatch = useDispatch();
    const { packages, status, error, packagesLength } = useSelector(state => state.packages);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const handleMenuClick = (event, packageItem) => {
        setAnchorEl(event.currentTarget);
        setSelectedPackage(packageItem);;
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handleMenuClose(); // Close the menu after opening the dialog
    };

    const handleCloseDialog = () => {
        dispatch(fetchPackages());
        setOpenDialog(false);
        setSelectedPackage(null);
    };

    useEffect(() => {
        if (packages?.packages) {
            dispatch(setPackagesLength(packages?.packages?.length));
        }
    }, [packages, dispatch]);

    if (status === 'loading') {
        return (
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '24px', paddingTop: '24px' }}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                    <Card key={item} sx={{ borderRadius: '10px', margin: 0 }}>
                        <CardContent sx={{ padding: 0, margin: 0 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '50px', paddingX: '16px', paddingBottom: '0px', background: 'white' }}>
                                <Skeleton variant="rectangular" width={45} height={18} />
                                <IconButton aria-label="settings">
                                    <Skeleton variant="circular" width={16} height={16} />
                                </IconButton>
                            </Box>
                            <Box sx={{ height: '102px', display: 'flex', alignItems: 'center', paddingX: '16px', marginBottom: '0px' }}>
                                <Box>
                                    <Skeleton variant="text" width={100} height={24} />
                                    <Skeleton variant="text" width={60} height={26} />
                                </Box>
                            </Box>
                        </CardContent>
                        <CardActions sx={{ background: 'var(--gray-30, #F7F7F7)', height: '44px', padding: 0 }}>
                            <Box sx={{ paddingX: '16px' }}>
                                <Skeleton variant="text" width={120} height={20} />
                            </Box>
                        </CardActions>
                    </Card>
                ))}
            </Box>
        );
    }

    if (status === 'failed') {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '24px', paddingTop: '24px' }}>
            {packagesLength > 0 ? (
                packages?.packages.map((packageItem) => (
                    <Card key={packageItem.id} sx={{ borderRadius: '10px', margin: 0 }}>
                        <CardContent sx={{ padding: 0, margin: 0 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '50px', paddingX: '16px', paddingBottom: '0px', background: 'white' }}>
                                <Box
                                    sx={{
                                        background: 'var(--alert-green-50, #ECFDF3)',
                                        display: 'flex',
                                        height: '18px',
                                        width: '45px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '4px',
                                        margin: 0,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--alert-green-500, #12B76A)',
                                            fontFamily: 'Inter',
                                            fontSize: '10px',
                                            fontWeight: 600,
                                            margin: 0,
                                        }}
                                    >
                                        Active
                                    </Typography>
                                </Box>
                                <IconButton aria-label="settings" onClick={(event) => handleMenuClick(event, packageItem)}>
                                    <MoreVertIcon sx={{ fontSize: '16px' }} />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                    PaperProps={{
                                        sx: {
                                            boxShadow: '0px 0px 14px 0px rgba(191, 205, 255, 0.10)',
                                            width: '140px',
                                            height: '98px',
                                        },
                                    }}
                                >
                                    <MenuItem
                                        onClick={handleOpenDialog} // Trigger the dialog when clicked
                                        sx={{
                                            border: 'none',
                                            color: 'var(--gray-500, #6D6D6D)',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            height: '41px',
                                            paddingLeft: '16px',
                                        }}
                                    >
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleMenuClose}
                                        sx={{
                                            border: 'none',
                                            color: 'var(--gray-500, #6D6D6D)',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            height: '41px',
                                            paddingLeft: '16px',
                                        }}
                                    >
                                        Archive
                                    </MenuItem>
                                </Menu>
                            </Box>

                            <Box sx={{ height: '102px', display: 'flex', alignItems: 'center', paddingX: '16px', marginBottom: '0px' }}>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: 'var(--dark-500, #09090B)',
                                            fontFamily: 'Inter',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            lineHeight: '24px',
                                            margin: 0,
                                        }}
                                    >
                                        {packageItem.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'var(--dark-500, #09090B)',
                                            fontFamily: 'Inter',
                                            fontSize: '18px',
                                            fontWeight: 700,
                                            lineHeight: '26px',
                                            margin: 0,
                                        }}
                                    >
                                        RM {packageItem.amount}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'var(--dark-300, #09090B)',
                                            fontFamily: 'Inter',
                                            fontSize: '12px',
                                            fontWeight: 300,
                                            lineHeight: '26px',
                                            margin: 0,
                                        }}
                                    >
                                        {packageItem.description}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>

                        <CardActions sx={{ background: 'var(--gray-30, #F7F7F7)', height: '44px', padding: 0 }}>
                            <Box sx={{ paddingX: '16px' }}>
                                <Typography
                                    sx={{
                                        color: 'var(--Gray-500, #667085)',
                                        fontFamily: 'Inter',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                    }}
                                >
                                    Promotion amount • RM{packageItem.promotion_amount}
                                </Typography>
                            </Box>
                        </CardActions>
                    </Card>
                ))
            ) : (
                <Box sx={{ padding: '8px', backgroundColor: '#FFF', borderRadius: '5px', marginTop: '24px', gridColumn: '1 / -1' }}>
                    <Card sx={{ border: '1px dashed #D0D5DD', backgroundColor: 'var(--gray-30, #F7F7F7)', textAlign: 'center', boxShadow: 'none' }}>
                        <CardContent sx={{ padding: 0 }}>
                            <Box sx={{ height: '88px', paddingTop: '24px' }}>
                                {/*icon*/}
                                <PackageSetupIcon/>
                            </Box>
                            <Typography
                                sx={{
                                    color: 'var(--dark-500, #09090B)',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                }}
                            >
                                Setup package
                            </Typography>
                            <Typography
                                sx={{
                                    color: 'var(--Gray-500, #667085)',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '18px',
                                }}
                            >
                                Setup packages to each of your spaces
                            </Typography>
                            <Button variant="cancel" startIcon={<AddIcon />} onClick={handleDialogOpen} sx={{ marginTop: '24px' }}>
                                Add Packages
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            )}
            <EditPackageDialog
                open={openDialog}
                handleClose={handleCloseDialog}
                packageItem={selectedPackage}
            />
        </Box>
    );
};

export default PackageList;

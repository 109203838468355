import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
};

// Thunk to fetch all packages
export const fetchPackages = createAsyncThunk('packages/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/packages`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to create a new package
export const createPackage = createAsyncThunk('packages/create', async (packageData, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.post(`${API_BASE_URL}/packages`, packageData, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to fetch a specific package by ID
export const fetchPackageById = createAsyncThunk('packages/fetchById', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        const response = await axios.get(`${API_BASE_URL}/packages?id=${id}`, { headers });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to delete a package by ID
export const deletePackage = createAsyncThunk('packages/delete', async (id, { rejectWithValue }) => {
    try {
        const headers = await getAuthHeaders();
        await axios.delete(`${API_BASE_URL}/packages/${id}`, { headers });
        return id;
    } catch (error) {
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

// Thunk to update a package by ID
export const updatePackage = createAsyncThunk('packages/update',
    async (editedPackage, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/packages?id=${editedPackage.id}`, editedPackage, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    });

// Slice for Packages
const packagesSlice = createSlice({
    name: 'packages',
    initialState: {
        packages: [],
        selectedPackage: null,
        status: 'idle',
        error: null,
        packagesLength: 0,
    },
    reducers: {
        setPackagesLength: (state, action) => {
            state.packagesLength = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPackages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPackages.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages = action.payload.data; // Set packages from the API response
                state.packagesLength = action.payload.data.length; // Set the length of the packages array
            })
            .addCase(fetchPackages.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch packages';
            })

            .addCase(createPackage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages.push(action.payload);
                state.packagesLength = state.packages.length; // Update the length after creating a package
            })
            .addCase(createPackage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create package';
            })

            .addCase(fetchPackageById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPackageById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPackage = action.payload.data;
            })
            .addCase(fetchPackageById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch package by ID';
            })

            .addCase(deletePackage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.packages = state.packages.filter(pkg => pkg.id !== action.payload);
                state.packagesLength = state.packages.length; // Update the length after deleting a package
                if (state.selectedPackage && state.selectedPackage.id === action.payload) {
                    state.selectedPackage = null;
                }
            })
            .addCase(deletePackage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete package';
            })

            .addCase(updatePackage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePackage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (Array.isArray(state.packages)) { // Add this check
                    const index = state.packages.findIndex(pkg => pkg.id === action.payload.id);
                    if (index !== -1) state.packages[index] = action.payload;
                }
                if (state.selectedPackage && state.selectedPackage.id === action.payload.id) {
                    state.selectedPackage = action.payload;
                }
                state.packagesLength = state.packages ? state.packages.length : 0; // Update this line
            })
            .addCase(updatePackage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update package';
            });
    },
});

export const { setPackagesLength } = packagesSlice.actions;
export default packagesSlice.reducer;

import {
    Box,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    styled, Paper
} from '@mui/material';

const DurationField = styled(Box)({
    '& .MuiTextField-root': {
        marginRight: '8px'
    }
});

const PreviewPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: theme.palette.primary[50],
    border: `1px solid ${theme.palette.grey[200]}`,
}));

const CustomDuration = ({ settings, handleSettingChange }) => {

    const generatePreviewTimes = () => {
        const minDuration = parseInt(settings.minDurationHours) * 60 + parseInt(settings.minDurationMinutes);
        const maxDuration = parseInt(settings.maxDurationHours) * 60 + parseInt(settings.maxDurationMinutes);
        const increment = parseInt(settings.timeIncrementHours) * 60 + parseInt(settings.timeIncrementMinutes);

        if (minDuration >= maxDuration || increment <= 0) return [];

        const times = [];
        for (let i = minDuration; i <= maxDuration; i += increment) {
            times.push(`${Math.floor(i / 60)}h ${i % 60}m`);
        }
        return times;
    };


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <DurationField>
                    <Typography variant="subtitle1" gutterBottom>
                        Minimum Duration
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            label="Hours"
                            value={settings.minDurationHours}
                            onChange={(e) => handleSettingChange('minDurationHours', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />
                        <TextField
                            type="number"
                            label="Minutes"
                            value={settings.minDurationMinutes}
                            onChange={(e) => handleSettingChange('minDurationMinutes', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                        />
                    </Box>

                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                        Maximum Duration
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            label="Hours"
                            value={settings.maxDurationHours}
                            onChange={(e) => handleSettingChange('maxDurationHours', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />
                        <TextField
                            type="number"
                            label="Minutes"
                            value={settings.maxDurationMinutes}
                            onChange={(e) => handleSettingChange('maxDurationMinutes', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                        />
                    </Box>

                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                        Time Increment
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <TextField
                            type="number"
                            label="Hours"
                            value={settings.timeIncrementHours}
                            onChange={(e) => handleSettingChange('timeIncrementHours', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">h</InputAdornment>,
                            }}
                        />
                        <TextField
                            type="number"
                            label="Minutes"
                            value={settings.timeIncrementMinutes}
                            onChange={(e) => handleSettingChange('timeIncrementMinutes', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m</InputAdornment>,
                            }}
                        />
                    </Box>
                </DurationField>
            </Grid>
            <Grid item xs={12} md={6}>
                <PreviewPaper elevation={0}>
                    <Typography variant="h6" gutterBottom>
                        Preview
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                        Based on your settings, customers will be able to book the following durations:
                    </Typography>
                    <Box>
                        {generatePreviewTimes().map((time, index) => (
                            <Typography key={index} variant="body2">
                                • {time}
                            </Typography>
                        ))}
                    </Box>
                </PreviewPaper>
            </Grid>
        </Grid>
    );
};

export default CustomDuration;
import { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Link,
    Skeleton
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fetchShippingRates, updateShippingRate, deleteShippingRate } from './ShippingRateAPI';
import { DeleteIcon, EditIcon } from "../../../shared/IconButtons";
import EditShippingRateDialog from './EditShippingRateDialog';
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import EastIcon from "@mui/icons-material/East";
import AddIcon from "@mui/icons-material/Add";

const ShippingRatesList = ({ handleClickOpen, onSnackbarChange  }) => {
    const [shippingRates, setShippingRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedShippingRate, setSelectedShippingRate] = useState(null);
    const [editedShippingRate, setEditedShippingRate] = useState(null);

    const loadShippingRates = async () => {
        setLoading(true);
        try {
            const data = await fetchShippingRates(paginationModel.page, paginationModel.pageSize, searchQuery);
            setShippingRates(data.data.shippingRates);
            setTotalCount(data.data.pagination.totalCount || 0);
        } catch (error) {
            console.error('Error loading shipping rates:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadShippingRates();
    }, [paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleEditClick = (shippingRate) => {
        setSelectedShippingRate(shippingRate);
        setEditedShippingRate({ ...shippingRate });
        setEditDialogOpen(true);
    };

    const handleDeleteClick = (shippingRate) => {
        setSelectedShippingRate(shippingRate);
        setDeleteDialogOpen(true);
    };

    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setEditedShippingRate(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleEditConfirm = async () => {
        try {
            await updateShippingRate(editedShippingRate.id, editedShippingRate);
            await loadShippingRates();
            setEditDialogOpen(false);
            onSnackbarChange({
                open: true,
                message: 'Shipping rate updated successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error updating shipping rate:', error);
            let errorMessage = 'Failed to update shipping rate. Please try again.';
            if (error.response && error.response.data) {
                errorMessage = error.response.data.description || error.response.data.message || errorMessage;
            }
            onSnackbarChange({
                open: true,
                message: errorMessage,
                severity: 'error'
            });
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteShippingRate(selectedShippingRate.id);
            await loadShippingRates();
            setDeleteDialogOpen(false);
            onSnackbarChange({
                open: true,
                message: 'Shipping rate deleted successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error deleting shipping rate:', error);
            let errorMessage = 'Failed to delete shipping rate. Please try again.';
            if (error.response && error.response.data) {
                errorMessage = error.response.data.description || error.response.data.message || errorMessage;
            }
            onSnackbarChange({
                open: true,
                message: errorMessage,
                severity: 'error'
            });
        }
    };

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.3,
            minWidth: 100,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 0.7,
            minWidth: 120,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'currency_id',
            headerName: 'Currency ID',
            flex: 0.7,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1.2,
            minWidth: 300,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'estimated_shipping_time_min',
            headerName: 'Min Shipping Time',
            flex: 0.7,
            minWidth: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'estimated_shipping_time_max',
            headerName: 'Max Shipping Time',
            flex: 0.7,
            minWidth: 180,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.7,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box>
            {loading ? (
                renderSkeletons()
            ) : shippingRates.length === 0 ? (
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '150px'}}>
                    <Box>
                        <IconButton sx={{backgroundColor: '#e0e0e0', p: 2, borderRadius: 1, mb: 4}}>
                            <LocalShippingOutlinedIcon sx={{fontSize: 40}}/>
                        </IconButton>
                        <Typography variant="h5" sx={{fontSize: '22px', fontWeight: '700', mb: 1}}>
                            Add your first shipping rate
                        </Typography>
                        <Typography variant="body1" color="textSecondary" sx={{maxWidth: 400, mb: 1}}>
                            Specify shipping rates and use it on your customers receipts, invoices, and Checkout Sessions.
                        </Typography>
                        <Link href="#" variant="body2" color="#f5c542" underline="none">
                            Learn more <EastIcon sx={{fontSize: '14px', ml: '2px'}}/>
                        </Link>
                        <Box sx={{marginTop: 3}}>
                            <Button variant="save" startIcon={<AddIcon />} onClick={handleClickOpen}>
                                Create shipping rate
                            </Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Box/>
                            <Box display="flex" gap="12px">
                                <TextField
                                    variant="outlined"
                                    placeholder="Search shipping rates"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    size="small"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '34px',
                                            '& input': {
                                                height: '34px',
                                                padding: '0 14px',
                                            },
                                            '& .MuiInputBase-input': {
                                                height: '34px',
                                                padding: '0 14px',
                                            }
                                        }
                                    }}
                                />
                            </Box>
                        </Box>

                        <Paper
                            sx={{
                                width: "100%",
                                borderRadius: "8px",
                                marginTop: '24px',
                                border: "1px solid var(--Gray-200, #EAECF0)",
                                background: "var(--White, #FFF)",
                                boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                            }}
                        >
                            <Typography
                                sx={{
                                    color: "var(--Gray-900, #101828)",
                                    fontFamily: "Inter",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "28px",
                                    padding: "20px 24px",
                                }}
                            >
                                All Shipping Rates
                            </Typography>

                            <DataGrid
                                slots={{ toolbar: GridToolbar }}
                                rows={shippingRates}
                                columns={columns}
                                paginationModel={paginationModel}
                                onPaginationModelChange={handlePaginationModelChange}
                                pageSizeOptions={[5, 10, 20]}
                                rowCount={totalCount}
                                paginationMode="server"
                                checkboxSelection
                                disableRowSelectionOnClick
                                rowHeight={70}
                                sx={{
                                    '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                        backgroundColor: '#737373',
                                        color: '#fff',
                                        height: '34px',
                                        my: 1,
                                        minWidth: 'auto',
                                        '&:hover': {
                                            backgroundColor: '#404040',
                                        },
                                    },
                                }}
                            />
                        </Paper>
                    </Box>

                    {/* Edit Dialog */}
                    <EditShippingRateDialog
                        open={editDialogOpen}
                        onClose={() => setEditDialogOpen(false)}
                        shippingRate={editedShippingRate}
                        onChange={handleEditInputChange}
                        onSave={handleEditConfirm}
                    />

                    {/* Delete Dialog */}
                    <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                        <DialogTitle>Delete Shipping Rate</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this shipping rate? This action cannot be undone.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='cancel' onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                            <Button variant='save' onClick={handleDeleteConfirm} color="error">Delete</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            )}
        </Box>
    );
};

export default ShippingRatesList;

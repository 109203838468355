import { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Divider, Typography, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BookingDetails from "./BookingDetails/BookingDetails";
import AddPackageToEvent from "../../../../shared/ticketing/AddPackageToEvent";
import BookingSetupImage from "./BookingSetupImage";
import PaymentStructure from "./PaymentStructure/PaymentStructure";

const BookingSetup = () => {
    const [value, setValue] = useState(0);
    const [bookingData, setBookingData] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if we have package data from the navigation state
        if (location.state?.packageData) {
            setBookingData(location.state.packageData);
            setIsEditMode(true);
        } else {
            // Initialize empty state for create mode
            setBookingData({
                name: '',
                description: '',
                minimum_pax: '',
                maximum_pax: '',
                image_url: '',
                // Add other default fields as needed
            });
            setIsEditMode(false);
        }
    }, [location]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const commonProps = {
        bookingData,
        setBookingData,
        isEditMode
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%' }}>
                    <Box>
                        <Typography variant='h1'>
                            {isEditMode ? 'Edit Booking Setup' : 'Create New Booking'}
                        </Typography>
                        <Typography variant='h2'>
                            {isEditMode
                                ? 'Modify your existing booking setup.'
                                : 'Set up and manage your new booking space.'}
                        </Typography>
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Details" />
                        <Tab label="Packages" />
                        <Tab label="Add Images" />
                        <Tab label="Payment Structure" />
                    </Tabs>
                </Box>

                <Divider sx={{ margin: '41px 0 32px 0' }} />

                <Box>
                    {value === 0 && <BookingDetails {...commonProps} />}
                    {value === 1 && <AddPackageToEvent {...commonProps} />}
                    {value === 2 && <BookingSetupImage {...commonProps} />}
                    {value === 3 && <PaymentStructure {...commonProps} />}
                </Box>
            </Box>
        </Box>
    );
};

export default BookingSetup;
import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Paper, Typography, Stepper, Step, StepLabel, CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useFormContext } from "../context/FormContext";
import { fetchOrganizations, patchOrganization } from "../../redux/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import StoreSetupForm from "../shared/StoreSetupForm";
import {setCurrentStep, updateOnboardingRecord} from "../../redux/accountOnboardingSlice";
import keycloak from "../../keycloak";

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const ThirdStep = () => {
    const [activeStep, setActiveStep] = useState(2);
    const dispatch = useDispatch();
    const { formData, updateFormData } = useFormContext();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const organization = useSelector(state => state.organizations.organization);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await dispatch(fetchOrganizations()).unwrap();
                setError(null);
            } catch (err) {
                setError("Failed to fetch organization data. Please try again later.");
                console.error("Error fetching organization:", err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleCancel = () => {
        dispatch(setCurrentStep('second'));
        navigate('/second');
    }


    const handleSave = async () => {
        dispatch(setCurrentStep('fourth'));
        const token = keycloak.tokenParsed;
        try {
            const result = await dispatch(updateOnboardingRecord({
                email: token?.email,
                onboardingData: {
                    current_step: 'fourth',
                    completed_steps: ['first', 'second', 'third']
                }
            })).unwrap();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
            console.error('Failed to update server:', error);
            // Handle the error (e.g., show an error message to the user)
        }
    }

    useEffect(() => {
        if (!formData.storeLinkBase) {
            updateFormData('storeLinkBase', 'https://pages.eventlah.com/');
        }
    }, [formData.storeLinkBase, updateFormData]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Stepper activeStep={2} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Paper elevation={5} sx={{ padding: { xs: '16px', sm: '24px', md: '32px' }, margin: '32px', borderRadius: '12px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                    <Typography variant='h1' sx={{ fontSize: { xs: '24px', md: '32px' } }}>Store Setup</Typography>
                    <Typography variant='h2' sx={{ fontSize: { xs: '16px', md: '20px' }, marginTop: '8px' }}>
                        Please provide your store details to begin setting up your online presence on Eventlah.
                    </Typography>
                </Box>

                <Divider/>

                <StoreSetupForm />
                <Box sx={{ display: 'flex', gap: '12px', justifyContent: { xs: 'center', md: 'flex-end' }, mt: 5 }}>
                    <Button
                        onClick={() => navigate('/fourth')}
                        sx={{
                            textTransform: 'none',
                            color: 'orange',
                            border: 'none',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                border: 'none',
                            },
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button variant="cancel" onClick={handleCancel}>Previous</Button>
                    <Button variant="save" onClick={handleSave}>
                        Next
                    </Button>
                </Box>
            </Paper>
        </>
    );
};



export default ThirdStep;

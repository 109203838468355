import { useState, Fragment } from 'react';
import {
    Box,
    Typography,
    Switch,
    Button,
    Divider,
    styled,
    IconButton,
    Tooltip,
    Collapse,
    Link
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const SettingRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.01)',
    },
}));

const SettingInfo = styled(Box)(({ theme }) => ({
    flex: 1,
    marginRight: theme.spacing(2),
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const LinkItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
}));

const OnboardingSettings = () => {
    const [settings, setSettings] = useState({
        requireId: false,
        enableAutoApproval: false,
        requireDocuments: false,
        requireTraining: false
    });

    const [hasChanges, setHasChanges] = useState(false);

    const handleSettingChange = (setting) => (event) => {
        setSettings(prev => ({
            ...prev,
            [setting]: event.target.checked
        }));
        setHasChanges(true);
    };

    const trainingLinks = [
        {
            title: 'Product Knowledge Training',
            url: 'https://example.com/product-training',
            description: 'Complete core product features and capabilities training'
        },
        {
            title: 'Compliance Guidelines',
            url: 'https://example.com/compliance',
            description: 'Review regulatory requirements and compliance procedures'
        },
        {
            title: 'Best Practices Guide',
            url: 'https://example.com/best-practices',
            description: 'Learn industry best practices and recommended approaches'
        }
    ];

    const settingsConfig = [
        {
            key: 'requireId',
            title: 'Require ID verification',
            description: 'Agents must verify their identity using a government-issued ID before registration',
            tooltip: 'We use secure verification providers to validate government IDs'
        },
        {
            key: 'enableAutoApproval',
            title: 'Enable automatic approval',
            description: 'Automatically approve new agents that meet all requirements',
            tooltip: 'Agents will still need to complete all required verification steps'
        },
        {
            key: 'requireDocuments',
            title: 'Require document submission',
            description: 'Agents must upload necessary documentation before completing registration',
            tooltip: 'Include business licenses, certifications, and other relevant documents'
        },
        {
            key: 'requireTraining',
            title: 'Require training completion',
            description: 'Agents must complete mandatory training modules before activation',
            tooltip: 'Training includes product knowledge, compliance, and best practices',
            extraContent: (
                <Collapse in={settings.requireTraining}>
                    <Box sx={{ mt: 2 }}>
                        {trainingLinks.map((link, index) => (
                            <LinkItem key={index}>
                                <Box>
                                    <Link
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                            color: 'orange',
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            }
                                        }}
                                    >
                                        {link.title}
                                        <OpenInNewIcon sx={{ fontSize: 16 }} />
                                    </Link>
                                    <Typography variant="caption" color="text.secondary" display="block">
                                        {link.description}
                                    </Typography>
                                </Box>
                            </LinkItem>
                        ))}
                    </Box>
                </Collapse>
            )
        },
    ];

    return (
        <Box>
            <Box>
                <Typography variant="h6" fontWeight={600} gutterBottom>
                    Onboarding Settings
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Set requirements for new agent registration
                </Typography>
            </Box>

            <Divider />

            <Box>
                {settingsConfig.map((setting, index) => (
                    <Fragment key={setting.key}>
                        <SettingRow>
                            <SettingInfo>
                                <Box display="flex" alignItems="center" mb={0.5}>
                                    <Typography variant="subtitle1" fontWeight={500}>
                                        {setting.title}
                                    </Typography>
                                    <Tooltip title={setting.tooltip} arrow placement="top">
                                        <IconButton size="small" sx={{ ml: 1 }}>
                                            <HelpOutlineIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {setting.description}
                                </Typography>
                                {setting.extraContent}
                            </SettingInfo>
                            <StyledSwitch
                                checked={settings[setting.key]}
                                onChange={handleSettingChange(setting.key)}
                                inputProps={{ 'aria-label': setting.title }}
                            />
                        </SettingRow>
                        {index < settingsConfig.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </Box>

            <Divider/>

            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
            }}>
                <Button
                    variant="save"
                    disabled={!hasChanges}
                >
                    Save Settings
                </Button>
            </Box>
        </Box>
    );
};

export default OnboardingSettings;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../utils/authUtils';

export const spaceApi = createApi({
    reducerPath: 'spaceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: async (headers) => {
            const token = await getToken();
            headers.set('authorization', `Bearer ${token}`);
            headers.set('Content-Type', 'application/json');
            return headers;
        },
    }),
    tagTypes: ['Space',  'TimeSettings', 'CapacitySettings', 'Sessions', 'TimeSlots'],
    endpoints: (builder) => ({
        getSpaces: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: '/spaces',
                params: {
                    page,
                    pageSize,
                    search,
                    organization_id: 1
                }
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.data.spaces.map(({ id }) => ({ type: 'Space', id })),
                        { type: 'Space', id: 'LIST' }
                    ]
                    : [{ type: 'Space', id: 'LIST' }]
        }),

        // Base Price endpoints
        getSpaceBasePrice: builder.query({
            query: (spaceId) => `/spaces/${spaceId}/base-price`,
            providesTags: (result, error, spaceId) => [{ type: 'SpacePrice', id: spaceId }]
        }),

        getSpaceById: builder.query({
            query: (id) => `/spaces?id=${id}`,
            providesTags: (result, error, id) => [{ type: 'Space', id }]
        }),

        updateSpaceBasePrice: builder.mutation({
            query: ({ spaceId, basePriceData }) => ({
                url: `/spaces/${spaceId}/base-price`,
                method: 'PATCH',
                body: basePriceData
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'SpacePrice', id: spaceId },
                { type: 'Space', id: spaceId }
            ]
        }),

        // Duration-based pricing endpoints
        getSpaceDurationPricing: builder.query({
            query: (spaceId) => `/spaces/${spaceId}/duration-pricing`,
            providesTags: (result, error, spaceId) => [{ type: 'SpaceDuration', id: spaceId }]
        }),

        updateSpaceDurationPricing: builder.mutation({
            query: ({ spaceId, durationPricingData }) => ({
                url: `/spaces/${spaceId}/duration-pricing`,
                method: 'PATCH',
                body: durationPricingData
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'SpaceDuration', id: spaceId },
                { type: 'Space', id: spaceId }
            ]
        }),

        // Duration rules endpoints
        addDurationRule: builder.mutation({
            query: ({ spaceId, ruleData }) => ({
                url: `/spaces/${spaceId}/duration-rules`,
                method: 'POST',
                body: ruleData
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'SpaceDuration', id: spaceId }
            ]
        }),

        updateDurationRule: builder.mutation({
            query: ({ spaceId, ruleId, ruleData }) => ({
                url: `/spaces/${spaceId}/duration-rules/${ruleId}`,
                method: 'PATCH',
                body: ruleData
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'SpaceDuration', id: spaceId }
            ]
        }),

        deleteDurationRule: builder.mutation({
            query: ({ spaceId, ruleId }) => ({
                url: `/spaces/${spaceId}/duration-rules/${ruleId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'SpaceDuration', id: spaceId }
            ]
        }),

        createSpace: builder.mutation({
            query: (spaceData) => ({
                url: '/spaces',
                method: 'POST',
                body: spaceData
            }),
            invalidatesTags: [{ type: 'Space', id: 'LIST' }]
        }),

        updateSpace: builder.mutation({
            query: ({ id, ...spaceData }) => ({
                url: `/spaces?id=${id}`,
                method: 'PATCH',
                body: spaceData
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Space', id },
                { type: 'Space', id: 'LIST' }
            ]
        }),

        deleteSpace: builder.mutation({
            query: (id) => ({
                url: `/spaces?id=${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'Space', id },
                { type: 'Space', id: 'LIST' }
            ]
        }),

        updateSpaceStatus: builder.mutation({
            query: ({ id, entityId, statusData }) => ({
                url: `/${id}/status/${entityId}`,
                method: 'PATCH',
                body: statusData
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Space', id },
                { type: 'Space', id: 'LIST' }
            ]
        }),

        // Booking Setup - Details
        updateSpaceDetails: builder.mutation({
            query: ({ spaceId, data }) => ({
                url: `/spaces/${spaceId}/details`,
                method: 'PATCH',
                body: {
                    name: data.name,
                    description: data.description,
                    amenities: data.amenities
                }
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'Space', id: spaceId }
            ]
        }),

        // Capacity Settings
        getSpaceCapacitySettings: builder.query({
            query: (spaceId) => {
                console.log('Making API request for space ID:', spaceId);
                return `/spaces/${spaceId}/capacity-settings`;
            },
            providesTags: (result, error, spaceId) => [
                { type: 'CapacitySettings', id: spaceId }
            ]
        }),

        updateSpaceCapacitySettings: builder.mutation({
            query: ({ spaceId, data }) => ({
                url: `/spaces/${spaceId}/capacity-settings`,
                method: 'PATCH',
                body: {
                    min_capacity: data.minimumCapacity,
                    max_capacity: data.maximumCapacity,
                    is_min_capacity_enabled: data.isMinCapacityEnabled,
                    is_max_capacity_enabled: data.isMaxCapacityEnabled
                }
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'CapacitySettings', id: spaceId },
                { type: 'Space', id: spaceId }
            ]
        }),

        // Time Settings
        getSpaceTimeSettings: builder.query({
            query: (spaceId) => `/spaces/${spaceId}/time-settings`,
            providesTags: (result, error, spaceId) => [
                { type: 'TimeSettings', id: spaceId }
            ]
        }),

        updateSpaceTimeSettings: builder.mutation({
            query: ({ spaceId, data }) => ({
                url: `/spaces/${spaceId}/time-settings`,
                method: 'PATCH',
                body: {
                    min_duration: data.minDuration,
                    max_duration: data.maxDuration,
                    time_increment: data.timeIncrement,
                    is_custom_duration_enabled: data.isCustomDurationEnabled
                }
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'TimeSettings', id: spaceId }
            ]
        }),

        // Sessions
        getSpaceSessions: builder.query({
            query: (spaceId) => `/spaces/${spaceId}/sessions`,
            providesTags: (result, error, spaceId) => [
                { type: 'Sessions', id: spaceId }
            ]
        }),

        addSpaceSession: builder.mutation({
            query: ({ spaceId, data }) => ({
                url: `/spaces/${spaceId}/sessions`,
                method: 'POST',
                body: {
                    name: data.name
                }
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'Sessions', id: spaceId }
            ]
        }),

        updateSpaceSession: builder.mutation({
            query: ({ spaceId, sessionId, data }) => ({
                url: `/spaces/${spaceId}/sessions/${sessionId}`,
                method: 'PATCH',
                body: {
                    name: data.name
                }
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'Sessions', id: spaceId }
            ]
        }),

        deleteSpaceSession: builder.mutation({
            query: ({ spaceId, sessionId }) => ({
                url: `/spaces/${spaceId}/sessions/${sessionId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { spaceId }) => [
                { type: 'Sessions', id: spaceId }
            ]
        }),

        // Time Slots
        getSpaceTimeSlots: builder.query({
            query: ({ spaceId, sessionId }) => ({
                url: `/spaces/${spaceId}/time-slots`,
                params: { session_id: sessionId }
            }),
            providesTags: (result, error, { spaceId, sessionId }) => [
                { type: 'TimeSlots', id: `${spaceId}-${sessionId}` }
            ]
        }),

        addSpaceTimeSlot: builder.mutation({
            query: ({ spaceId, sessionId, data }) => ({
                url: `/spaces/${spaceId}/time-slots`,
                method: 'POST',
                body: {
                    session_id: sessionId,
                    start_time: data.startTime,
                    end_time: data.endTime,
                    is_active: data.isActive
                }
            }),
            invalidatesTags: (result, error, { spaceId, sessionId }) => [
                { type: 'TimeSlots', id: `${spaceId}-${sessionId}` }
            ]
        }),

        updateSpaceTimeSlot: builder.mutation({
            query: ({ spaceId, slotId, data }) => ({
                url: `/spaces/${spaceId}/time-slots/${slotId}`,
                method: 'PATCH',
                body: {
                    start_time: data.startTime,
                    end_time: data.endTime,
                    is_active: data.isActive
                }
            }),
            invalidatesTags: (result, error, { spaceId, sessionId }) => [
                { type: 'TimeSlots', id: `${spaceId}-${sessionId}` }
            ]
        }),

        deleteSpaceTimeSlot: builder.mutation({
            query: ({ spaceId, slotId }) => ({
                url: `/spaces/${spaceId}/time-slots/${slotId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { spaceId, sessionId }) => [
                { type: 'TimeSlots', id: `${spaceId}-${sessionId}` }
            ]
        })

    })
});

export const {
    useGetSpacesQuery,
    useGetSpaceByIdQuery,
    useCreateSpaceMutation,
    useUpdateSpaceMutation,
    useDeleteSpaceMutation,
    useUpdateSpaceStatusMutation,

    // New exports for base price
    useGetSpaceBasePriceQuery,
    useUpdateSpaceBasePriceMutation,

    // New exports for duration-based pricing
    useGetSpaceDurationPricingQuery,
    useUpdateSpaceDurationPricingMutation,
    useAddDurationRuleMutation,
    useUpdateDurationRuleMutation,
    useDeleteDurationRuleMutation,

    // Details
    useUpdateSpaceDetailsMutation,

    // Capacity Settings
    useGetSpaceCapacitySettingsQuery,
    useUpdateSpaceCapacitySettingsMutation,

    // Time Settings
    useGetSpaceTimeSettingsQuery,
    useUpdateSpaceTimeSettingsMutation,

    // Sessions
    useGetSpaceSessionsQuery,
    useAddSpaceSessionMutation,
    useUpdateSpaceSessionMutation,
    useDeleteSpaceSessionMutation,

    // Time Slots
    useGetSpaceTimeSlotsQuery,
    useAddSpaceTimeSlotMutation,
    useUpdateSpaceTimeSlotMutation,
    useDeleteSpaceTimeSlotMutation

} = spaceApi;
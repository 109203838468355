import React, { useState } from 'react';
import {
    Box, TextField, Typography, Alert, IconButton, List,
    Accordion, AccordionSummary, AccordionDetails,
    Paper, Divider
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Code, Group, LocalShipping, Notifications } from "@mui/icons-material";
import CustomerNotificationsSettings from "./EmailNotificationContents/CustomerNotifications";
import StaffNotifications from "./EmailNotificationContents/StaffNotificationContents";

const notificationOptions = [
    {
        icon: <Notifications />,
        primary: 'Customer notifications',
        secondary: 'Notify customers about order and account events',
        details: 'Configure email templates and settings for customer notifications.'
    },
    {
        icon: <Group />,
        primary: 'Staff notifications',
        secondary: 'Notify staff members about new order events',
        details: 'Set up email notifications for your staff members about new orders and other important events.'
    },
    {
        icon: <LocalShipping />,
        primary: 'Fulfillment request notification',
        secondary: 'Notify your fulfillment service provider when you mark an order as fulfilled',
        details: 'Configure notifications to automatically inform your fulfillment service when an order is marked as fulfilled.'
    }
    // {
    //     icon: <Code />,
    //     primary: 'Webhooks',
    //     secondary: 'Send XML or JSON notifications about store events to a URL',
    //     details: 'Set up webhooks to send automated notifications about store events to a specified URL in XML or JSON format.'
    // },
];

const EmailNotification = () => {
    const [email, setEmail] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        // In a real application, you would trigger authentication here
    };

    const handleCloseAlert = () => {
        setIsAuthenticated(false);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box>
            <Typography variant='h1'>Email Notification</Typography>
            <Typography variant='h2'>Set up and manage email notifications.</Typography>

            <Divider/>

            <Paper sx={{ maxWidth: '1000px', margin: 'auto', p: 2 }}>
                <Typography gutterBottom sx={{fontSize:'20px', fontWeight:'600'}}>
                    Sender email
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    The email your store uses to send emails to your customers
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mb: 2 }}
                />
                {/*{isAuthenticated && (*/}
                {/*    <Alert*/}
                {/*        icon={<CheckCircleOutlineIcon fontSize="inherit" />}*/}
                {/*        severity="success"*/}
                {/*        action={*/}
                {/*            <IconButton*/}
                {/*                aria-label="close"*/}
                {/*                color="inherit"*/}
                {/*                size="small"*/}
                {/*                onClick={handleCloseAlert}*/}
                {/*            >*/}
                {/*                <CloseIcon fontSize="inherit" />*/}
                {/*            </IconButton>*/}
                {/*        }*/}
                {/*        sx={{*/}
                {/*            backgroundColor: '#e6f4ea',*/}
                {/*            color: '#1e4620',*/}
                {/*            '& .MuiAlert-icon': {*/}
                {/*                color: '#1e4620',*/}
                {/*            },*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Domain successfully authenticated*/}
                {/*    </Alert>*/}
                {/*)}*/}
                {/*{isAuthenticated && (*/}
                {/*    <Typography variant="body2" sx={{ mt: 2 }}>*/}
                {/*        Your domain is authenticated. If your email is showing as store+7759835984@eventlahemail.com in the inbox, ensure you have a DMARC record.*/}
                {/*    </Typography>*/}
                {/*)}*/}
            </Paper>

            <Box sx={{ maxWidth: '1000px', mx: 'auto', padding:2, marginTop: 2, backgroundColor:'white', borderRadius:'12px' }}>
                <List>
                    {notificationOptions.map((option, index) => (
                        <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleAccordionChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    {option.icon}
                                    <Box sx={{ ml: 2, flexGrow: 1 }}>
                                        <Typography>{option.primary}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {option.secondary}
                                        </Typography>
                                    </Box>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                { index === 0 && <CustomerNotificationsSettings /> }
                                { index === 1 && <StaffNotifications /> }
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default EmailNotification;
import React, { useState } from "react";
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '312px',
}));

const AgentsSetupForm = () => {
    const [customerData, setCustomerData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        state: '',
        country: 'malaysia',
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomerData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        setTimeout(() => {
            setSnackbarMessage("Form submitted successfully!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setLoading(false);
        }, 1500);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {/* First Name Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>First Name</TextFieldTitle>
                    <TextField
                        name="firstName"
                        value={customerData.firstName || ''}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder='First Name'
                        label='First Name'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Last Name Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Last Name</TextFieldTitle>
                    <TextField
                        name="lastName"
                        value={customerData.lastName || ''}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder='Last Name'
                        label='Last Name'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Email Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Email</TextFieldTitle>
                    <TextField
                        name="email"
                        type="email"
                        value={customerData.email || ''}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder='Email'
                        label='Email'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Address Field 1 */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Address Line 1</TextFieldTitle>
                    <TextField
                        name="addressLine1"
                        value={customerData.addressLine1 || ''}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Address Line 1'
                        label='Address Line 1'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider/>

                {/* Address Field 2 */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Address Line 2</TextFieldTitle>
                    <TextField
                        name="addressLine2"
                        value={customerData.addressLine2 || ''}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Address Line 2'
                        label='Address Line 2'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Postcode */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Postcode</TextFieldTitle>
                    <TextField
                        name="postcode"
                        value={customerData.postcode || ''}
                        onChange={handleChange}
                        fullWidth
                        placeholder='Postcode'
                        label='Postcode'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* State Fields */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>State</TextFieldTitle>
                    <TextField
                        name="state"
                        value={customerData.state || ''}
                        onChange={handleChange}
                        fullWidth
                        required
                        placeholder='State'
                        label='State'
                        sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                    />
                </Box>

                <Divider />

                {/* Country Field */}
                <Box sx={{ display: 'flex', gap: '10px', marginY: 2 }}>
                    <TextFieldTitle>Country</TextFieldTitle>
                    <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                        <InputLabel>Country</InputLabel>
                        <Select
                            name="country"
                            value={customerData.country || 'malaysia'}
                            onChange={handleChange}
                            placeholder="Country"
                            label="Country"
                        >
                            <MenuItem value="malaysia">Malaysia</MenuItem>
                            <MenuItem value="singapore">Singapore</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: "flex-end", gap: '8px' }}>
                    <Button variant='cancel' type="button">Cancel</Button>
                    <Button variant='save' type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </Box>
            </form>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <CheckCircleOutlineIcon />,
                        error: <ErrorOutlineIcon />,
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AgentsSetupForm;
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Skeleton, IconButton,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fetchPurchases } from '../../../redux/purchasesSlice';
import { ViewIcon} from "../../shared/IconButtons";
import ViewOrders from "./ViewOrders";

const OrdersList = ({ status }) => {
    const dispatch = useDispatch();
    const [organizationId, setOrganizationId] = useState(1);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const purchasesData = useSelector((state) => state.purchases.purchases);
    const { pagination, status: fetchStatus } = useSelector((state) => state.purchases);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);

    const getStatusId = (status) => {
        switch(status) {
            case 'pending':
                return 3;
            case 'processing':
                return 4;
            case 'completed':
                return 6;
            case 'cancelled':
                return 7;
            case 'rejected':
                return 8;
            default:
                return 3; // Default to pending if status is not recognized
        }
    };

    useEffect(() => {
        dispatch(fetchPurchases({
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            searchQuery: searchQuery,
            organization_id: organizationId,
            purchase_type: 2,
            status_id: getStatusId(status)
        })).then(() => {
            setIsInitialLoad(false);
        });
    }, [paginationModel, searchQuery, dispatch, organizationId, status]);

    useEffect(() => {
        if (pagination) {
            setTotalCount(pagination.totalCount || 0);
        }
    }, [pagination]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const rows = useMemo(() => {
        return purchasesData?.map(purchase => ({
            ...purchase,
            id: purchase.id,
            customerName: purchase.customerName,
            productName: purchase.product?.name || 'N/A',
            quantity: purchase.quantity,
            productPrice: purchase.product_price,
            totalAmount: purchase.total_amount,
            purchaseDate: new Date(purchase.created_at).toLocaleDateString(),
        })) || [];
    }, [purchasesData]);

    const columns = [
        { field: 'invoice_number', headerName: 'Invoice No.', flex: 0.7, minWidth: 130 },
        { field: 'purchaseDate', headerName: 'Purchase Date', flex: 0.7, minWidth: 130 },
        { field: 'customerName', headerName: 'Customer Name', flex: 0.7, minWidth: 130 },
        {
            field: 'totalAmount',
            headerName: 'Total Amount',
            flex: 0.7,
            minWidth: 130,
            renderCell: (params) => `RM${params.value}`
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.5,
            minWidth: 100,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                    <IconButton
                        aria-label="view"
                        onClick={() => {
                            setSelectedPurchase(params.row);
                            setIsDialogOpen(true);
                        }}
                    >
                        <ViewIcon/>
                    </IconButton>
                </Box>
            ),
        },
    ];

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    return (
        <Box sx={{ display:'flex' }}>
            <Box flex={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search purchases"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper sx={{
                    width: "100%",
                    borderRadius: "8px",
                    marginTop: '24px',
                    border: "1px solid var(--Gray-200, #EAECF0)",
                    background: "var(--White, #FFF)",
                    boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                }}>
                    <Typography sx={{
                        color: "var(--Gray-900, #101828)",
                        fontFamily: "Inter",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "28px",
                        padding: "20px 24px",
                    }}>
                        {`${status.charAt(0).toUpperCase() + status.slice(1)} Purchases`}
                    </Typography>

                    {isInitialLoad && fetchStatus === 'loading' ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={fetchStatus === 'loading'}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            <ViewOrders
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                purchaseData={selectedPurchase}
            />
        </Box>
    );
};

export default OrdersList;
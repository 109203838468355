import React, {useState, useEffect} from 'react';
import {
    Box,
    Typography,
    Switch,
    TextField,
    Button,
    Grid,
    Paper, MenuItem,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { addPaymentMethod } from '../../../../../../redux/paymentsMethodSlice';

const PaymentForm = ({header, subHeader, imgSrc, imgAlt, onSave, onCancel, initialEnabled}) => {
    const [enabled, setEnabled] = useState(initialEnabled);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [merchantId, setMerchantId] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [hashType, setHashType] = useState('md5');
    const [environmentMode, setEnvironmentMode] = useState('Live');
    const navigate = useNavigate();  // Initialize the useNavigate hook

    useEffect(() => {
        setEnabled(initialEnabled);
    }, [initialEnabled]);

    const handleSave = async () => {
        try {
            const newPaymentMethod = {
                organization_id: 1,
                title: title,
                description: description,
                merchant_id: merchantId,
                secret_key: secretKey,
                hash_type: hashType,
                environment_mode: environmentMode,
            };
            await addPaymentMethod(newPaymentMethod);
            navigate('/finish');
            // Handle the success case
        } catch (error) {
            // Handle the error case
        }
    };

    return (
        <Paper elevation={0} sx={{bgcolor: '#f0f0f0', p: 3, maxWidth: 600}}>
            <Box sx={{display: 'flex'}}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1, mr: 1}}>
                    <Box component="img" src={imgSrc} alt={imgAlt} sx={{width: '50px'}}/>
                </Box>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        {header}
                    </Typography>
                    <Typography variant="body2" gutterBottom color="text.secondary">
                        {subHeader}
                    </Typography>
                </Box>
            </Box>

            <Box component="form" sx={{mt: 3}}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Enable / Disable</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Switch
                            size="small"
                            checked={enabled}
                            onChange={(e) => setEnabled(e.target.checked)}
                        />
                        <Typography variant="body2" component="span" sx={{ml: 1}}>Enable this payment
                            gateway</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Title</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>

                    <Grid sx={{ textAlign: 'start', alignSelf: 'flex-start' }} item xs={4}>
                        <Typography >Description</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            size="small"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            InputProps={{
                                style: { padding: 0 }
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Merchant ID</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            value={merchantId}
                            onChange={(e) => setMerchantId(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Secret Key</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            type="password"
                            value={secretKey}
                            onChange={(e) => setSecretKey(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Hash Type</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            value={hashType}
                            onChange={(e) => setHashType(e.target.value)}
                        >
                            <MenuItem value="md5">md5</MenuItem>
                            {/* You can add more MenuItem components for additional options */}
                        </TextField>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography sx={{ textAlign: 'start' }}>Environment Mode</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            size="small"
                            select
                            value={environmentMode}
                            onChange={(e) => setEnvironmentMode(e.target.value)}
                        >
                            <MenuItem value="Live">Live</MenuItem>
                            <MenuItem value="Sandbox">Sandbox</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                    <Button variant="cancel" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="save" onClick={handleSave}>
                        Save changes
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default PaymentForm;
import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';

const SelectedProductsDialog = ({ open, onClose, selectedProducts }) => {
    const [packageName, setPackageName] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handlePackageNameChange = (event) => {
        setPackageName(event.target.value);
    };

    const handleCreatePackage = () => {
        // Here you would typically send the package data to your backend
        // For this example, we'll just show a success message
        setSnackbar({ open: true, message: 'Package created successfully!', severity: 'success' });
        setPackageName('');
        // Close the dialog after a short delay
        setTimeout(() => {
            onClose();
        }, 1000);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Selected Products</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="package-name"
                        label="Package Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={packageName}
                        onChange={handlePackageNameChange}
                    />
                    <List>
                        {selectedProducts.map((product) => (
                            <ListItem key={product.id}>
                                <ListItemText
                                    primary={product.name}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2" color="text.primary">
                                                Price: RM{product.price}
                                            </Typography>
                                            {" — "}{product.description}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="cancel">Close</Button>
                    <Button onClick={handleCreatePackage} variant="save" disabled={!packageName.trim()}>
                        Create Package
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SelectedProductsDialog;
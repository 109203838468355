import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api';

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchPurchases = createAsyncThunk(
    'purchases/fetchPurchases',
    async ({ page, pageSize, searchQuery, organizationId, purchase_type, status_id}, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/purchase-orders`, {
                headers,
                params: {
                    page,
                    pageSize: pageSize,
                    search: searchQuery,
                    organization_id: organizationId,
                    purchase_type: purchase_type,
                    status_id: status_id
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createPurchase = createAsyncThunk(
    'purchases/create',
    async (purchaseData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/purchases`, purchaseData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchPurchaseById = createAsyncThunk(
    'purchases/fetchById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/purchases/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updatePurchase = createAsyncThunk(
    'purchases/update',
    async ({ id, purchaseData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/purchases/${id}`, purchaseData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deletePurchase = createAsyncThunk(
    'purchases/delete',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/purchases/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const purchasesSlice = createSlice({
    name: 'purchases',
    initialState: {
        purchases: [],
        status: 'idle',
        error: null,
        pagination: null,
        selectedPurchase: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPurchases.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPurchases.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.purchases = action.payload.data.purchases.map(purchase => ({
                    ...purchase,
                    customerName: purchase.customer
                        ? `${purchase.customer.first_name} ${purchase.customer.last_name}`
                        : 'Unknown Customer',
                    productName: purchase.product && purchase.product[0]
                        ? purchase.product[0].name
                        : 'Unknown Product'
                }));
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchPurchases.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch purchases';
            })
            .addCase(createPurchase.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createPurchase.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.purchases.push(action.payload);
            })
            .addCase(createPurchase.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create purchase';
            })
            .addCase(fetchPurchaseById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPurchaseById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedPurchase = action.payload.data;
            })
            .addCase(fetchPurchaseById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch purchase';
            })
            .addCase(updatePurchase.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePurchase.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.purchases.findIndex(purchase => purchase.id === action.payload.id);
                if (index !== -1) state.purchases[index] = action.payload;
                if (state.selectedPurchase && state.selectedPurchase.id === action.payload.id) {
                    state.selectedPurchase = action.payload;
                }
            })
            .addCase(updatePurchase.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update purchase';
            })
            .addCase(deletePurchase.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePurchase.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.purchases = state.purchases.filter(purchase => purchase.id !== action.payload);
                if (state.selectedPurchase && state.selectedPurchase.id === action.payload) {
                    state.selectedPurchase = null;
                }
            })
            .addCase(deletePurchase.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete purchase';
            });
    },
});

export default purchasesSlice.reducer;
import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography } from '@mui/material';
import BookingOverviewAll from "./BookingOverviewAll";
import BookingOverviewCurrent from "./BookingOverviewCurrent";
import BookingOverviewUpcoming from "./BookingOverviewUpcoming";
import BookingOverviewPast from "./BookingOverviewPast";

const BookingOverview = () => {
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display:'flex', padding:{xs:1,md:'32px'} }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Booking Overview</Typography>
                        <Typography variant='h2'>All of your bookings.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/*add buttons here*/}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="All" />
                        <Tab label="Current" />
                        <Tab label="Upcoming" />
                        <Tab label="Past" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <BookingOverviewAll/> }
                    { value === 1 && <BookingOverviewCurrent/> }
                    { value === 2 && <BookingOverviewUpcoming/> }
                    { value === 3 && <BookingOverviewPast/> }
                </Box>
            </Box>
        </Box>
    );
};

export default BookingOverview;
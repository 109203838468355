import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    Box,
    Grid,
    Divider,
    Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ViewOrders = ({ open, onClose, purchaseData }) => {
    if (!purchaseData) return null;

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '8px',
                }
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 24px',
            }}>
                <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                    Invoice Details
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '24px' }}>
                <Paper elevation={0} sx={{ padding: '24px', border: '1px solid #EAECF0' }}>
                    {/* Header Section */}
                    <Box sx={{ mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h5" sx={{ fontWeight: 600, color: '#101828' }}>
                                    {purchaseData.invoice_number || 'Invoice Pending'}
                                </Typography>
                                <Typography sx={{ color: '#667085', mt: 1 }}>
                                    Date: {formatDate(purchaseData.created_at)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#101828' }}>
                                    Total Amount
                                </Typography>
                                <Typography variant="h5" sx={{ color: '#027A48', fontWeight: 600, mt: 1 }}>
                                    RM {parseFloat(purchaseData.total_amount).toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    {/* Customer Details */}
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, color: '#101828' }}>
                            Customer Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ color: '#667085', mb: 1 }}>Name</Typography>
                                <Typography sx={{ fontWeight: 500 }}>
                                    {`${purchaseData.customer?.first_name} ${purchaseData.customer?.last_name}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ color: '#667085', mb: 1 }}>Email</Typography>
                                <Typography sx={{ fontWeight: 500 }}>
                                    {purchaseData.customer?.email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Purchase Details */}
                    <Box>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2, color: '#101828' }}>
                            Purchase Details
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ color: '#667085', mb: 1 }}>Purchase ID</Typography>
                                <Typography sx={{ fontWeight: 500 }}>#{purchaseData.id}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ color: '#667085', mb: 1 }}>Product Total</Typography>
                                <Typography sx={{ fontWeight: 500 }}>
                                    RM {purchaseData.product_total_amount || purchaseData.total_amount}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};

export default ViewOrders;
import React, {useEffect, useState} from "react";
import {
    Box,
    TextField,
    Typography,
    Button,
    Snackbar,
    Alert, Divider
} from "@mui/material";
import { styled } from "@mui/system";
import keycloak from "../../../../keycloak";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const EditProfileSettings = () => {

    const [profile, setProfile] = useState({
        oldEmail: '',
        email: '',
        name: '',
        password: ''
    });

    useEffect(() => {
    if (keycloak.authenticated) {
        keycloak.loadUserProfile()
            .then((kcProfile) => {
                setProfile(prevProfile => ({
                    ...prevProfile,
                    oldEmail: kcProfile.email,
                    id: kcProfile.id,
                    email: kcProfile.email,
                    firstName: `${kcProfile.firstName}`,
                    lastName: `${kcProfile.lastName}`
                }));
            })
            .catch((error) => {
                console.error('Failed to load user profile', error);
            });
        }
    }, []);


    const [errors, setErrors] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));

        if (name === 'email') {
            if (!validateEmail(value)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: 'Please enter a valid email address'
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    email: ''
                }));
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateEmail(profile.email)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: 'Please enter a valid email address'
            }));
            return;
        }
        setLoading(true);

        try {
            const response = await fetch(`https://api-dev.eventlah.com/api/user-models?email=${encodeURIComponent(profile.oldEmail)}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: profile.id, // Assuming you have the user's ID in the profile state
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    email: profile.email,
                    // Add any other fields you want to update
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.description || 'Failed to update profile');
            }

            setSnackbar({
                open: true,
                message: 'Profile updated successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            setSnackbar({
                open: true,
                message: error.message || 'An error occurred while updating the profile',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Typography variant="h1">Edit Profile</Typography>
            <Typography variant="h2">Update your personal information and preferences</Typography>
            <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
                Please note: Changing your email address will also update your login credentials. You will need to use your new email to sign in after saving these changes.
            </Alert>

            <Divider/>
            {/* ConfirmationEmail */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Email <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="email"
                    value={profile.email}
                    onChange={handleChange}
                    fullWidth
                    label='Enter Email'
                    required
                    type="email"
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            {/* Name */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    First Name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="first_name"
                    value={profile.firstName}
                    onChange={handleChange}
                    fullWidth
                    placeholder='Enter First Name'
                    required
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Last Name <RequiredIndicator>*</RequiredIndicator>
                </TextFieldTitle>
                <TextField
                    name="last_name"
                    value={profile.lastName}
                    onChange={handleChange}
                    fullWidth
                    placeholder='Enter Last Name'
                    required
                    sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                />
            </Box>

            <Divider/>

            {/* Password */}
            {/*<Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>*/}
            {/*    <TextFieldTitle>*/}
            {/*        Password <RequiredIndicator>*</RequiredIndicator>*/}
            {/*    </TextFieldTitle>*/}
            {/*    <TextField*/}
            {/*        name="password"*/}
            {/*        value={profile.password}*/}
            {/*        onChange={handleChange}*/}
            {/*        fullWidth*/}
            {/*        label='Enter Password'*/}
            {/*        required*/}
            {/*        type="password"*/}
            {/*        sx={{ paddingRight: { md: '50px', lg: '240px' } }}*/}
            {/*    />*/}
            {/*</Box>*/}

            {/* Submit Button */}
            <Box sx={{ marginTop: '32px' }}>
                <Button
                    variant="save"
                    type="submit"
                    disabled={loading || !!errors.email}
                >
                    {loading ? 'Updating...' : 'Update Profile'}
                </Button>
            </Box>

            {/* Snackbar for success message */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EditProfileSettings;
import { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    styled, Divider
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import CustomDuration from './CustomDuration';
import FixedTimeSlots from './FixedTimeSlots';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px'
});

const TitleSection = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .title': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828'
    }
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    width: '100%',
    marginBottom: '24px',
    padding: '4px',
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    '& .MuiToggleButtonGroup-grouped': {
        border: 'none',
        borderRadius: '6px !important',
        margin: '0 !important',
        flex: 1,
        textTransform: 'none',
        '&.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: '#101828',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1)',
        }
    }
});

const TimeSlots = ({
                       timeSettings,
                       onTimeSettingsChange,
                       timeSlots,
                       onTimeSlotsChange
                   }) => {
    const [isOverrideEnabled, setIsOverrideEnabled] = useState(false);
    const [isCustomDurationEnabled, setIsCustomDurationEnabled] = useState(true);
    const [isFixedTimeSlotsEnabled, setIsFixedTimeSlotsEnabled] = useState(true);

    // Convert minutes to hours and minutes for local state
    const convertMinutesToHoursMinutes = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60).toString();
        const minutes = (totalMinutes % 60).toString();
        return { hours, minutes };
    };

    // Initialize settings from timeSettings prop
    const [settings, setSettings] = useState(() => {
        console.log('Initializing settings from timeSettings:', timeSettings);
        const minDuration = convertMinutesToHoursMinutes(timeSettings?.minDuration || 0);
        const maxDuration = convertMinutesToHoursMinutes(timeSettings?.maxDuration || 0);
        const timeIncrement = convertMinutesToHoursMinutes(timeSettings?.timeIncrement || 0);

        return {
            minDurationHours: minDuration.hours,
            minDurationMinutes: minDuration.minutes,
            maxDurationHours: maxDuration.hours,
            maxDurationMinutes: maxDuration.minutes,
            timeIncrementHours: timeIncrement.hours,
            timeIncrementMinutes: timeIncrement.minutes
        };
    });

    // Update local state when timeSettings prop changes
    useEffect(() => {
        console.log('timeSettings changed:', timeSettings);
        if (timeSettings) {
            const minDuration = convertMinutesToHoursMinutes(timeSettings.minDuration || 0);
            const maxDuration = convertMinutesToHoursMinutes(timeSettings.maxDuration || 0);
            const timeIncrement = convertMinutesToHoursMinutes(timeSettings.timeIncrement || 0);

            setSettings({
                minDurationHours: minDuration.hours,
                minDurationMinutes: minDuration.minutes,
                maxDurationHours: maxDuration.hours,
                maxDurationMinutes: maxDuration.minutes,
                timeIncrementHours: timeIncrement.hours,
                timeIncrementMinutes: timeIncrement.minutes
            });

            setIsOverrideEnabled(timeSettings.isCustomDurationEnabled || false);
        }
    }, [timeSettings]);

    // Convert hours and minutes to total minutes
    const convertToMinutes = (hours, minutes) => {
        return (parseInt(hours) * 60) + parseInt(minutes);
    };

    const handleSettingChange = (field, value) => {
        console.group('TimeSlots - handleSettingChange');
        console.log('Field:', field, 'Value:', value);

        // Ensure value is not negative
        const sanitizedValue = Math.max(0, parseInt(value) || 0);

        // For minutes, ensure value is less than 60
        if (field.includes('Minutes') && sanitizedValue >= 60) {
            console.groupEnd();
            return;
        }

        // Update local state
        const newSettings = {
            ...settings,
            [field]: sanitizedValue.toString()
        };
        setSettings(newSettings);

        // Calculate total minutes for each duration type
        const updatedTimeSettings = {
            isCustomDurationEnabled: isOverrideEnabled,
            minDuration: convertToMinutes(
                newSettings.minDurationHours,
                newSettings.minDurationMinutes
            ),
            maxDuration: convertToMinutes(
                newSettings.maxDurationHours,
                newSettings.maxDurationMinutes
            ),
            timeIncrement: convertToMinutes(
                newSettings.timeIncrementHours,
                newSettings.timeIncrementMinutes
            )
        };

        console.log('Calling onTimeSettingsChange with:', updatedTimeSettings);
        onTimeSettingsChange(updatedTimeSettings);
        console.groupEnd();
    };

    const handleOverrideToggle = (e) => {
        const isEnabled = e.target.checked;
        setIsOverrideEnabled(isEnabled);
        if (!isEnabled) {
            setIsCustomDurationEnabled(false);
            setIsFixedTimeSlotsEnabled(false);
        }

        if (onTimeSettingsChange) {
            const updatedTimeSettings = {
                ...timeSettings,
                isCustomDurationEnabled: isEnabled,
                minDuration: isEnabled ? convertToMinutes(settings.minDurationHours, settings.minDurationMinutes) : 0,
                maxDuration: isEnabled ? convertToMinutes(settings.maxDurationHours, settings.maxDurationMinutes) : 0,
                timeIncrement: isEnabled ? convertToMinutes(settings.timeIncrementHours, settings.timeIncrementMinutes) : 0
            };
            console.log('Updating time settings on override toggle:', updatedTimeSettings);
            onTimeSettingsChange(updatedTimeSettings);
        }
    };

    const handleCustomDurationToggle = (e) => {
        setIsCustomDurationEnabled(e.target.checked);
        // Update timeSettings if needed
    };

    const handleFixedTimeSlotsToggle = (e) => {
        setIsFixedTimeSlotsEnabled(e.target.checked);
        // Update timeSettings if needed
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
                <TextFieldTitle>
                    Time Slots
                </TextFieldTitle>

                <Box sx={{ width: '100%' }}>
                    <Header>
                        <TitleSection>
                            <LabelContent>
                                <Typography className="heading">Time Settings</Typography>
                                <Typography className="subtext">
                                    Customize booking durations and time slots for your services.
                                </Typography>
                            </LabelContent>
                        </TitleSection>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Switch
                                checked={isOverrideEnabled}
                                onChange={handleOverrideToggle}
                                color="primary"
                            />
                        </Box>
                    </Header>

                    {isOverrideEnabled && (
                        <>
                            <Divider />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginBottom: '16px' }}>
                                <Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                        <Box>
                                            <Typography sx={{ fontWeight: 500, color: '#101828' }}>Custom Duration</Typography>
                                            <Typography color="text.secondary" sx={{ fontSize: '14px', fontWeight: 400, mt: 1 }}>
                                                Set the minimum and maximum duration for bookings, as well as the time increment.
                                                For example, if you set a minimum of 1 hour, maximum of 4 hours, and increment of 30 minutes,
                                                customers can book for 1 hour, 1.5 hours, 2 hours, and so on, up to 4 hours.
                                            </Typography>
                                        </Box>
                                        <Switch
                                            checked={isCustomDurationEnabled}
                                            onChange={handleCustomDurationToggle}
                                            color="primary"
                                        />
                                    </Box>
                                    {isCustomDurationEnabled && (
                                        <CustomDuration
                                            settings={settings}
                                            handleSettingChange={handleSettingChange}
                                        />
                                    )}
                                </Box>

                                <Divider />

                                <Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: '8px' }}>
                                        <Box>
                                            <Typography sx={{ fontWeight: 500, color: '#101828' }}>Fixed Time Slots</Typography>
                                            <Typography color="text.secondary" sx={{ fontSize: '14px', fontWeight: 400, mt: 1 }}>
                                                Create specific time slots for your services. This allows you to offer
                                                pre-defined booking times, giving you more control over your schedule.
                                                Ideal for businesses with structured time slots like classes or appointments.
                                            </Typography>
                                        </Box>
                                        <Switch
                                            checked={isFixedTimeSlotsEnabled}
                                            onChange={handleFixedTimeSlotsToggle}
                                            color="primary"
                                        />
                                    </Box>
                                    {isFixedTimeSlotsEnabled && (
                                        <FixedTimeSlots
                                            timeSlots={timeSlots}
                                            onTimeSlotsChange={onTimeSlotsChange}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default TimeSlots;
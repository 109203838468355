import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import keycloak from "../keycloak";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    try {
        await new Promise(resolve => setTimeout(resolve, 3000));
        if (keycloak.token) {
            return {
                'Authorization': `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            };
        } else {
            throw new Error('Token is not available');
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw new Error('Failed to refresh authentication token');
    }
};

export const fetchAccounts = createAsyncThunk(
    'accounts/fetchAccounts',
    async (status, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/accounts?status=${status}`, { headers });
            return { status, data: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const saveAccount = createAsyncThunk(
    'accounts/saveAccount',
    async (account, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/accounts`, account, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateAccount = createAsyncThunk(
    'accounts/updateAccount',
    async ({ id, account }, { rejectWithValue }) => {
        if (!account || Object.keys(account).length === 0) {
            return rejectWithValue('Account data is required');
        }

        try {
            const headers = await getAuthHeaders();
            console.log('Payload data:', account);
            const response = await axios.patch(`${API_BASE_URL}/accounts/${id}`, account, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const accountsSlice = createSlice({
    name: 'accounts',
    initialState: {
        verified: [],
        warning: [],
        incomplete: [],
        expired: [],
        billing: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAccounts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAccounts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state[action.payload.status] = action.payload.data;
            })
            .addCase(fetchAccounts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch accounts';
            })
            .addCase(saveAccount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveAccount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const status = action.payload.status;
                if (state[status]) {
                    state[status].push(action.payload);
                }
            })
            .addCase(saveAccount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to save account';
            })
            .addCase(updateAccount.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAccount.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const status = action.payload.status;
                if (state[status]) {
                    const index = state[status].findIndex(account => account.id === action.payload.id);
                    if (index !== -1) {
                        state[status][index] = action.payload;
                    }
                }
            })
            .addCase(updateAccount.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update account';
            });
    },
});

export default accountsSlice.reducer;
import {
    Box,
    TextField,
    Typography,
    styled,
    Paper,
    InputAdornment,
    Divider,
    Button,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";

const SectionTitle = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
});

const PreviewSection = styled(Paper)(({ theme }) => ({
    padding: '24px',
    marginTop: '24px',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: theme.palette.divider
}));

const DiscountRuleSection = styled(Paper)(({ theme }) => ({
    padding: '24px',
    marginBottom: '24px',
    backgroundColor: '#FFF',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: theme.palette.divider
}));

const SummaryRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
    '& .MuiTypography-root': {
        color: theme.palette.text.secondary
    }
}));

const TotalRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    '& .MuiTypography-root': {
        fontWeight: 600,
        fontSize: '18px',
        color: theme.palette.text.primary
    }
}));

const DiscountText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 500
}));

const BulkBookingDiscounts = () => {
    const [bookingDetails, setBookingDetails] = useState({
        quantity: '',
        basePrice: '',
        finalPrice: ''
    });

    const [discountRules, setDiscountRules] = useState([
        { minQuantity: 5, discount: 10 },
        { minQuantity: 10, discount: 20 }
    ]);

    const calculateDiscount = (quantity) => {
        if (!quantity || !discountRules.length) return 0;

        const sortedRules = [...discountRules]
            .sort((a, b) => b.minQuantity - a.minQuantity);

        const applicableRule = sortedRules.find(rule =>
            quantity >= rule.minQuantity
        );

        return applicableRule ? applicableRule.discount : 0;
    };

    const handleInputChange = (field, value) => {
        const newDetails = { ...bookingDetails, [field]: value };

        if (newDetails.quantity && newDetails.basePrice) {
            const discount = calculateDiscount(Number(newDetails.quantity));
            const totalBeforeDiscount = Number(newDetails.quantity) * Number(newDetails.basePrice);
            const discountAmount = (totalBeforeDiscount * discount) / 100;
            const finalPrice = totalBeforeDiscount - discountAmount;

            newDetails.finalPrice = finalPrice.toFixed(2);
        }

        setBookingDetails(newDetails);
    };

    const addDiscountRule = () => {
        setDiscountRules([...discountRules, { minQuantity: '', discount: '' }]);
    };

    const removeDiscountRule = (index) => {
        const newRules = discountRules.filter((_, i) => i !== index);
        setDiscountRules(newRules);
        // Recalculate price with updated rules
        if (bookingDetails.quantity && bookingDetails.basePrice) {
            handleInputChange('quantity', bookingDetails.quantity);
        }
    };

    const updateDiscountRule = (index, field, value) => {
        const newRules = [...discountRules];
        newRules[index] = { ...newRules[index], [field]: Number(value) };
        setDiscountRules(newRules);
        // Recalculate price with updated rules
        if (bookingDetails.quantity && bookingDetails.basePrice) {
            handleInputChange('quantity', bookingDetails.quantity);
        }
    };

    // Calculate values for display
    const quantity = Number(bookingDetails.quantity);
    const basePrice = Number(bookingDetails.basePrice);
    const subtotal = quantity * basePrice;
    const discountPercentage = calculateDiscount(quantity);
    const discountAmount = (subtotal * discountPercentage) / 100;

    return (
        <Box>
            <DiscountRuleSection elevation={0}>
                <SectionTitle>Bulk Discount Rules</SectionTitle>
                {discountRules.map((rule, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
                        <TextField
                            label="Minimum Quantity"
                            type="number"
                            value={rule.minQuantity}
                            onChange={(e) => updateDiscountRule(index, 'minQuantity', e.target.value)}
                            InputProps={{
                                inputProps: { min: 0 }
                            }}
                        />
                        <TextField
                            label="Discount Percentage"
                            type="number"
                            value={rule.discount}
                            onChange={(e) => updateDiscountRule(index, 'discount', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: { min: 0, max: 100 }
                            }}
                        />
                        <IconButton
                            onClick={() => removeDiscountRule(index)}
                            color="error"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))}
                <Button
                    startIcon={<AddIcon />}
                    onClick={addDiscountRule}
                    variant="save"
                    sx={{ mt: 1 }}
                >
                    Add Discount Rule
                </Button>
            </DiscountRuleSection>

            <SectionTitle>Discount Preview</SectionTitle>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                <TextField
                    label="Number of Bookings"
                    type="number"
                    value={bookingDetails.quantity}
                    onChange={(e) => handleInputChange('quantity', e.target.value)}
                    InputProps={{
                        inputProps: { min: 0 }
                    }}
                    fullWidth
                />

                <TextField
                    label="Base Price"
                    type="number"
                    value={bookingDetails.basePrice}
                    onChange={(e) => handleInputChange('basePrice', e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                        inputProps: { min: 0 }
                    }}
                    fullWidth
                />

                <TextField
                    label="Final Price"
                    value={bookingDetails.finalPrice}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">RM</InputAdornment>,
                        readOnly: true
                    }}
                    fullWidth
                />
            </Box>

            {bookingDetails.quantity && bookingDetails.basePrice && (
                <PreviewSection elevation={0}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 600, mb: 3 }}>
                        Discount Preview
                    </Typography>

                    <SummaryRow>
                        <Typography>
                            Number of Bookings
                        </Typography>
                        <Typography>
                            {bookingDetails.quantity}
                        </Typography>
                    </SummaryRow>

                    <SummaryRow>
                        <Typography>
                            Price per Booking
                        </Typography>
                        <Typography>
                            RM {Number(bookingDetails.basePrice).toFixed(2)}
                        </Typography>
                    </SummaryRow>

                    <SummaryRow>
                        <Typography>
                            Subtotal
                        </Typography>
                        <Typography>
                            RM {subtotal.toFixed(2)}
                        </Typography>
                    </SummaryRow>

                    {discountPercentage > 0 && (
                        <SummaryRow>
                            <DiscountText>
                                Bulk Discount ({discountPercentage}%)
                            </DiscountText>
                            <DiscountText>
                                - RM {discountAmount.toFixed(2)}
                            </DiscountText>
                        </SummaryRow>
                    )}

                    <Divider sx={{ my: 2 }} />

                    <TotalRow>
                        <Typography>
                            Total
                        </Typography>
                        <Typography>
                            RM {bookingDetails.finalPrice}
                        </Typography>
                    </TotalRow>
                </PreviewSection>
            )}
        </Box>
    );
};

export default BulkBookingDiscounts;
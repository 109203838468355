import React, { useState } from 'react';
import {
    Box,
    Typography,
    Switch,
    FormControlLabel,
    Checkbox,
    TextField,
    FormGroup,
    styled
} from '@mui/material';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0,
    marginBottom: '24px',
    '& .MuiSwitch-root': {
        marginLeft: 8,
    }
});

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const SectionTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#101828',
    marginTop: '24px',
    marginBottom: '16px'
});

const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        height: '40px',
        backgroundColor: '#FFFFFF',
    },
    '& input': {
        textAlign: 'left',
        padding: '8px 12px',
    }
});

const RequiredInfoCheckbox = styled(FormControlLabel)({
    margin: '8px 0',
    '& .MuiTypography-root': {
        fontSize: '14px',
        color: '#344054'
    }
});

const UnitLabel = styled(Typography)({
    color: '#667085',
    fontSize: '14px',
    marginLeft: '8px'
});

const WaitlistSettings = () => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [autoNotify, setAutoNotify] = useState(false);
    const [waitlistData, setWaitlistData] = useState({
        maxPerSlot: '5',
        holdDuration: '24',
        queueManagement: {
            firstComeFirstServed: false,
            vipPriority: false,
            autoRemove: false
        },
        requiredInfo: {
            name: true,
            email: true,
            phone: false,
            partySize: false
        }
    });

    const handleQueueManagementChange = (event) => {
        setWaitlistData(prev => ({
            ...prev,
            queueManagement: {
                ...prev.queueManagement,
                [event.target.name]: event.target.checked
            }
        }));
    };

    const handleRequiredInfoChange = (event) => {
        setWaitlistData(prev => ({
            ...prev,
            requiredInfo: {
                ...prev.requiredInfo,
                [event.target.name]: event.target.checked
            }
        }));
    };

    const handleInputChange = (event) => {
        setWaitlistData(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Waiting List
            </TextFieldTitle>

            <Box sx={{ width: '100%' }}>
                <StyledFormControlLabel
                    control={
                        <Switch
                            checked={isEnabled}
                            onChange={(e) => setIsEnabled(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={
                        <LabelContent>
                            <Typography className="heading">Waiting List Settings</Typography>
                            <Typography className="subtext">Allow customers to join a waiting list when slots are full</Typography>
                        </LabelContent>
                    }
                />

                {isEnabled && (
                    <>
                        <SectionTitle>Waiting List Capacity</SectionTitle>
                        <Box display="flex" gap={4} mb={3}>
                            <Box flex={1}>
                                <Typography sx={{ fontSize: '14px', color: '#344054', mb: 1 }}>
                                    Maximum Per Time Slot
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <StyledTextField
                                        name="maxPerSlot"
                                        value={waitlistData.maxPerSlot}
                                        onChange={handleInputChange}
                                        type="number"
                                        size="small"
                                    />
                                    <UnitLabel>pax</UnitLabel>
                                </Box>
                            </Box>
                            <Box flex={1}>
                                <Typography sx={{ fontSize: '14px', color: '#344054', mb: 1 }}>
                                    Hold Duration
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <StyledTextField
                                        name="holdDuration"
                                        value={waitlistData.holdDuration}
                                        onChange={handleInputChange}
                                        type="number"
                                        size="small"
                                    />
                                    <UnitLabel>hours</UnitLabel>
                                </Box>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: '#101828' }}>
                                    Automatic Notifications
                                </Typography>
                                <Typography sx={{ fontSize: '14px', color: '#667085' }}>
                                    Notify waitlisted customers when slots become available
                                </Typography>
                            </Box>
                            <Switch
                                checked={autoNotify}
                                onChange={(e) => setAutoNotify(e.target.checked)}
                                color="primary"
                            />
                        </Box>

                        <SectionTitle>Queue Management</SectionTitle>
                        <FormGroup>
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.queueManagement.firstComeFirstServed}
                                        onChange={handleQueueManagementChange}
                                        name="firstComeFirstServed"
                                    />
                                }
                                label="First come, first served"
                            />
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.queueManagement.vipPriority}
                                        onChange={handleQueueManagementChange}
                                        name="vipPriority"
                                    />
                                }
                                label="Enable priority waitlist for VIP customers"
                            />
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.queueManagement.autoRemove}
                                        onChange={handleQueueManagementChange}
                                        name="autoRemove"
                                    />
                                }
                                label="Auto-remove after response window expires"
                            />
                        </FormGroup>

                        <SectionTitle>Required Information</SectionTitle>
                        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.requiredInfo.name}
                                        onChange={handleRequiredInfoChange}
                                        name="name"
                                        disabled
                                    />
                                }
                                label="Name"
                            />
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.requiredInfo.email}
                                        onChange={handleRequiredInfoChange}
                                        name="email"
                                        disabled
                                    />
                                }
                                label="Email"
                            />
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.requiredInfo.phone}
                                        onChange={handleRequiredInfoChange}
                                        name="phone"
                                    />
                                }
                                label="Phone Number"
                            />
                            <RequiredInfoCheckbox
                                control={
                                    <Checkbox
                                        checked={waitlistData.requiredInfo.partySize}
                                        onChange={handleRequiredInfoChange}
                                        name="partySize"
                                    />
                                }
                                label="Party Size"
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default WaitlistSettings;
import { useState, useEffect } from 'react';
import { Box, Divider, TextField, Typography, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCreateSpaceMutation, useUpdateSpaceMutation } from '../../../../../api-services/spaceApi';
import { useNavigate } from 'react-router-dom';
import CapacitySettings from "./CapacitySettings";
import SpecialOccasions from "./SpecialOccasions";
import WaitlistSettings from "./WaitinglistSettings";
import TimeSlots from "./TimeSlots/TimeSlots";
import AmenitySettings from "./AmenitySettings";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')({
    color: 'red',
    marginLeft: '4px',
});

const BookingDetails = ({ bookingData, setBookingData, isEditMode }) => {
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [minCapacityEnabled, setMinCapacityEnabled] = useState(false);
    const [maxCapacityEnabled, setMaxCapacityEnabled] = useState(false);
    const [venueData, setVenueData] = useState({
        name: '',
        amenities: '',
        description: '',
        minimum_pax: '',
        maximum_pax: '',
        minimum_amount: null,
        maximum_amount: null,
        minimum_currency_id: null,
        maximum_currency_id: null,
        organization_id: 1,
    });

    // Add new state for time settings
    const [timeSettings, setTimeSettings] = useState({
        isCustomDurationEnabled: false,
        minDuration: 0,
        maxDuration: 0,
        timeIncrement: 0
    });

    // Add new state for time slots
    const [timeSlots, setTimeSlots] = useState({
        sessions: [],
        timeSlots: {}
    });

    // RTK Query mutations
    const [createSpace, { isLoading: isCreating }] = useCreateSpaceMutation();
    const [updateSpace, { isLoading: isUpdating }] = useUpdateSpaceMutation();

    const isLoading = isCreating || isUpdating;

    // Debug function to log state changes
    const logStateChange = (label, data) => {
        console.group(`BookingDetails State Change: ${label}`);
        console.log('New Value:', data);
        console.log('Current Venue Data:', venueData);
        console.log('Current Booking Data:', bookingData);
        console.groupEnd();
    };

    /// Debug function to validate time slots data
    const validateTimeSlots = (timeSlotData) => {
        console.group('Time Slots Validation');
        console.log('Validating Time Slots Data:', timeSlotData);

        if (!timeSlotData?.sessions || !timeSlotData?.timeSlots) {
            console.error('Time slots data structure is invalid:', timeSlotData);
            console.groupEnd();
            return false;
        }

        let isValid = true;
        let slotsFound = false;

        Object.entries(timeSlotData.timeSlots).forEach(([sessionId, slots]) => {
            console.log(`Checking slots for session ${sessionId}:`, slots);

            if (slots && slots.length > 0) {
                slotsFound = true;
                slots.forEach((slot, index) => {
                    if (!slot.startTime || !slot.endTime) {
                        console.error(`Invalid slot at index ${index}:`, slot);
                        isValid = false;
                    }
                });
            }
        });

        if (!slotsFound) {
            console.warn('No time slots found in any session');
            isValid = false;
        }

        console.log('Time slots validation result:', isValid);
        console.groupEnd();
        return isValid;
    };

    // Debug function to validate custom duration settings
    const validateCustomDuration = (timeSettings) => {
        console.group('Custom Duration Validation');
        console.log('Validating Time Settings:', timeSettings);

        if (!timeSettings?.isCustomDurationEnabled) {
            console.log('Custom duration is not enabled, skipping validation');
            console.groupEnd();
            return true;
        }

        const isValid = timeSettings.minDuration > 0
            && timeSettings.maxDuration >= timeSettings.minDuration
            && timeSettings.timeIncrement > 0;

        if (!isValid) {
            console.error('Invalid time settings:', {
                minDuration: timeSettings.minDuration,
                maxDuration: timeSettings.maxDuration,
                timeIncrement: timeSettings.timeIncrement
            });
        }

        console.log('Custom duration validation result:', isValid);
        console.groupEnd();
        return isValid;
    };

    useEffect(() => {
        if (isEditMode && bookingData) {
            setVenueData({
                ...venueData,
                ...bookingData,
            });
            setMinCapacityEnabled(!!bookingData.minimum_pax);
            setMaxCapacityEnabled(!!bookingData.maximum_pax);

            // Initialize time settings if they exist in bookingData
            if (bookingData.timeSettings) {
                setTimeSettings(bookingData.timeSettings);
            }

            // Initialize time slots if they exist in bookingData
            if (bookingData.timeSlots) {
                setTimeSlots(bookingData.timeSlots);
            }

            // Debug log for initialization
            console.group('Booking Details Initialization');
            console.log('Venue Data:', bookingData);
            console.log('Time Settings:', bookingData.timeSettings || 'Not set');
            console.log('Time Slots:', bookingData.timeSlots || 'Not set');
            console.groupEnd();
        }
    }, [isEditMode, bookingData]);

    // Add handler for time settings changes
    const handleTimeSettingsChange = (updatedSettings) => {
        console.group('Time Settings Update');
        console.log('Previous:', timeSettings);
        console.log('New:', updatedSettings);
        console.groupEnd();

        setTimeSettings(updatedSettings);
        setBookingData({
            ...bookingData,
            timeSettings: updatedSettings
        });
    };

    // Add handler for time slots changes
    const handleTimeSlotsChange = (updatedSlots) => {
        console.group('Time Slots Update');
        console.log('Previous:', timeSlots);
        console.log('New:', updatedSlots);
        console.groupEnd();

        setTimeSlots(updatedSlots);
        setBookingData({
            ...bookingData,
            timeSlots: updatedSlots
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Add debug logging to input change handler
        logStateChange(`Input Change: ${name}`, value);
        const updatedData = {
            ...venueData,
            [name]: value
        };
        setVenueData(updatedData);
        setBookingData?.(updatedData);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSave = async () => {
        console.group('Saving Booking Details');
        console.log('Starting save process with current data:', {
            venueData,
            bookingData
        });

        try {
            const dataToSubmit = {
                ...venueData,
                minimum_pax: minCapacityEnabled ? venueData.minimum_pax : null,
                maximum_pax: maxCapacityEnabled ? venueData.maximum_pax : null,
            };

            // Log the received time settings
            console.log('Time Settings from bookingData:', bookingData?.timeSettings);
            console.log('Time Slots from bookingData:', bookingData?.timeSlots);

            // Validate time settings if present
            if (bookingData?.timeSettings) {
                const isCustomDurationValid = validateCustomDuration(bookingData.timeSettings);
                if (!isCustomDurationValid) {
                    throw new Error('Invalid custom duration configuration');
                }
                dataToSubmit.timeSettings = bookingData.timeSettings;
            }

            // Validate time slots if present
            if (bookingData?.timeSlots) {
                const areTimeSlotsValid = validateTimeSlots(bookingData.timeSlots);
                if (!areTimeSlotsValid) {
                    throw new Error('Invalid time slots configuration');
                }
                dataToSubmit.timeSlots = bookingData.timeSlots;
            }

            console.log('Final data to submit:', dataToSubmit);

            if (isEditMode) {
                const result = await updateSpace({
                    id: bookingData.id,
                    ...dataToSubmit
                }).unwrap();
                console.log('Update response:', result);
                setSnackbarMessage('Booking updated successfully!');
            } else {
                const result = await createSpace(dataToSubmit).unwrap();
                console.log('Create response:', result);
                setSnackbarMessage('Booking created successfully!');
            }

            setSnackbarSeverity('success');
        } catch (error) {
            console.error('Error during save:', error);
            setSnackbarMessage(`Failed to ${isEditMode ? 'update' : 'create'} booking: ${error.message}`);
            setSnackbarSeverity('error');
        } finally {
            console.groupEnd();
            setSnackbarOpen(true);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box component="form" noValidate autoComplete="off">
                {/* Booking Name */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
                    <TextFieldTitle>
                        Booking Name <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        fullWidth
                        label="Enter name"
                        placeholder="e.g., Grand Ballroom"
                        name="name"
                        value={venueData.name}
                        onChange={handleInputChange}
                        required
                        inputProps={{ maxLength: 255 }}
                    />
                </Box>

                <Divider />

                {/* Booking Description */}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
                    <TextFieldTitle>
                        Booking Description <RequiredIndicator>*</RequiredIndicator>
                    </TextFieldTitle>
                    <TextField
                        fullWidth
                        label="Enter description"
                        placeholder="Briefly describe the venue and its primary use"
                        name="description"
                        value={venueData.description}
                        onChange={handleInputChange}
                        required
                        multiline
                        rows={7}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                        }}
                    />
                </Box>

                <Divider />

                {/*amenities*/}
                <AmenitySettings
                    amenities={venueData.amenities}
                    onInputChange={handleInputChange}
                />

                <Divider />

                {/* Capacity Settings Component */}
                <CapacitySettings
                    minCapacityEnabled={minCapacityEnabled}
                    maxCapacityEnabled={maxCapacityEnabled}
                    venueData={venueData}
                    onMinCapacityChange={setMinCapacityEnabled}
                    onMaxCapacityChange={setMaxCapacityEnabled}
                    onInputChange={handleInputChange}
                />

                {/*SpecialOccasions*/}
                {/*
                TODO: Next Release
                <SpecialOccasions />
                */}

                {/* Waitlist Settings */}
                {/*
                TODO: Next Release
                <WaitlistSettings />
                */}

                <Divider />

                {/* Time Slot Settings */}
                {/* Update TimeSlots component with new props */}
                <TimeSlots
                    timeSettings={timeSettings}
                    onTimeSettingsChange={handleTimeSettingsChange}
                    timeSlots={timeSlots}
                    onTimeSlotsChange={handleTimeSlotsChange}
                />

                <Divider />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
                    <Button
                        variant="cancel"
                        disabled={isLoading}
                        onClick={() => navigate('/settings/booking-setup')}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="save"
                        onClick={() => {
                            console.log('Save initiated with current state:', {
                                venueData,
                                bookingData,
                                timeSettings: bookingData?.timeSettings,
                                timeSlots: bookingData?.timeSlots
                            });
                            handleSave();
                        }}
                        disabled={isLoading}
                        startIcon={isLoading ? <CircularProgress size={20} /> : null}
                    >
                        {isLoading ? 'Saving...' : isEditMode ? 'Update' : 'Save'}
                    </Button>
                </Box>
            </Box>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
};

export default BookingDetails;
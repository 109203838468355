import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import keycloak from "../../../../keycloak";
import {
    Box,
    Button,
    Divider,
    Typography,
    TextField,
    Snackbar,
    Alert,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem, Link
} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import ResetPassword from "./ResetPassword";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const RequiredIndicator = styled('span')(({ theme }) => ({
    color: 'red',
    marginLeft: '4px',
}));

const UserProfileSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userData, setUserData] = useState({
        oldEmail: '',
        email: '',
        firstName: '',
        lastName: '',
        // role: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [loading, setLoading] = useState(false);

    const isAdmin = keycloak.hasRealmRole('eventlah-admin');

    // Get the store name from the Redux store
    const storeName = useSelector(state => state.organizationProfiles.profiles[0]?.data?.storeName || 'Merchant Store Name');

    // useEffect(() => {
    //     console.log('keycloak.tokenParsed', keycloak.tokenParsed?.given_name);
    //     // Fetch user data from Keycloak
    //     if (keycloak.authenticated) {
    //         setUserData({
    //             email: keycloak.tokenParsed.email || '',
    //             firstName: keycloak.tokenParsed?.given_name || '',
    //             lastName: keycloak.tokenParsed?.family_name || '',
    //             role: isAdmin ? 'Admin' : 'User',
    //         });
    //     }
    // }, [keycloak, isAdmin]);

    useEffect(() => {
        if (keycloak.authenticated) {
            keycloak.loadUserProfile()
                .then((kcProfile) => {
                    setUserData(prevProfile => ({
                        ...prevProfile,
                        oldEmail: kcProfile.email,
                        id: kcProfile.id,
                        email: kcProfile.email,
                        firstName: `${kcProfile.firstName}`,
                        lastName: `${kcProfile.lastName}`
                    }));
                })
                .catch((error) => {
                    console.error('Failed to load user profile', error);
                });
        }
    }, [keycloak]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSave = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            setIsEditing(false);
            const response = await fetch(`https://api-dev.eventlah.com/api/user-models?email=${encodeURIComponent(userData.oldEmail)}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: userData.id, // Assuming you have the user's ID in the profile state
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    // Add any other fields you want to update
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.description || 'Failed to update profile');
            }

            setSnackbar({
                open: true,
                message: 'Profile updated successfully!',
                severity: 'success'
            });
        } catch (error) {
            console.error('Error updating profile:', error);
            setSnackbar({
                open: true,
                message: error.message || 'An error occurred while updating the profile',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
        // try {
        //     // Here you would typically make an API call to save the data
        //     // For Keycloak, you might need to use its API to update user info
        //     // Simulating an API call with a timeout
        //     await new Promise(resolve => setTimeout(resolve, 1000));
        //
        //     setIsEditing(false);
        //
        //
        //
        //     setSnackbar({
        //         open: true,
        //         message: 'Profile updated successfully!',
        //         severity: 'success'
        //     });
        // } catch (error) {
        //     console.error('Failed to update user profile:', error);
        //     setSnackbar({
        //         open: true,
        //         message: 'Failed to update user profile',
        //         severity: 'error'
        //     });
        // } finally {
        //     setLoading(false);
        // }
    };

    const handleCancel = () => {
        setIsEditing(false);
        // Reset to original data
        if (keycloak.authenticated) {
            setUserData({
                email: keycloak.tokenParsed.email || '',
                firstName: keycloak.tokenParsed.given_name || '',
                lastName: keycloak.tokenParsed.family_name || '',
                // role: isAdmin ? 'Admin' : 'User',
            });
        }
    };

    const handleOpenResetDialog = () => {
        setOpenResetDialog(true);
    };

    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const renderField = (label, field, required = false, options = null) => (
        <>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    {label} {required && <RequiredIndicator>*</RequiredIndicator>}
                </TextFieldTitle>
                {isEditing ? (
                    options ? (
                        <FormControl fullWidth sx={{ paddingRight: { md: '50px', lg: '240px' } }}>
                            <InputLabel id={`${field}-select-label`}>Select {label}</InputLabel>
                            <Select
                                labelId={`${field}-select-label`}
                                id={`${field}-select`}
                                name={field}
                                value={userData[field] || ''}
                                onChange={handleInputChange}
                                required={required}
                                label={`Select ${label}`}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            name={field}
                            value={userData[field] || ''}
                            onChange={handleInputChange}
                            fullWidth
                            label={`Enter ${label}`}
                            required={required}
                            sx={{ paddingRight: { md: '50px', lg: '240px' } }}
                        />
                    )
                ) : (
                    <Typography variant='h2'>{userData[field] || 'N/A'}</Typography>
                )}
            </Box>
            <Divider />
        </>
    );

    return(
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSave}>
            <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
                Please note: Changing your email address will also update your login credentials. You will need to use your new email to sign in after saving these changes.
            </Alert>
            <Box sx={{display:'flex', justifyContent: 'space-between', marginBottom: 2}}>
                <Typography variant='h1' fontSize='20px' fontWeight='600'>
                    {isAdmin ? 'Admin Profile' : 'User Profile'}
                </Typography>
                {isEditing ? (
                    <Box>
                        <Button variant='cancel' onClick={handleCancel} sx={{height:'32px', marginRight: 1}}>
                            Cancel
                        </Button>
                        <Button variant='save' type="submit" sx={{height:'32px'}} disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </Box>
                ) : (
                    <Button variant='cancel' onClick={handleEditClick} sx={{height:'32px'}}>
                        <EditNoteIcon/> Edit
                    </Button>
                )}
            </Box>

            <Box>
                <Typography variant='h2' fontSize='16px' fontWeight='600' sx={{mb: 2}}>
                    {isAdmin ? 'Admin Dashboard' : storeName}
                </Typography>
                {renderField('Email', 'email', true)}
                {renderField('First Name', 'firstName', true)}
                {renderField('Last Name', 'lastName', true)}
                {/*{renderField('Role', 'role', true)}*/}
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                    <TextFieldTitle>
                        Password
                    </TextFieldTitle>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h2' sx={{ marginRight: 3, mb:1 }}>••••••••</Typography>
                        <Link
                            component="button"
                            onClick={handleOpenResetDialog}
                            sx={{
                                textDecoration: 'none',
                                color: 'orange',
                                fontSize: '14px',
                                '&:hover': {
                                    textDecoration: 'underline',
                                }
                            }}
                        >
                            Reset Password
                        </Link>
                    </Box>
                </Box>
                <Divider />
            </Box>

            <ResetPassword open={openResetDialog} onClose={handleCloseResetDialog} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UserProfileSettings;
import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';

const PaymentSummary = ({ selectedVariants, bookingFee }) => {
    const subtotal = selectedVariants.reduce((total, variant) => {
        return total + (Number(variant.price) * variant.quantity);
    }, 0);

    const totalAmount = subtotal + bookingFee;

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 3, bgcolor: '#fff' }}>
            <Typography variant="h6" gutterBottom>Payment Details</Typography>

            {selectedVariants.map((variant, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Box>
                        <Typography variant="body1">{variant.variant_name}: {variant.variant_value}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {variant.quantity}x RM{Number(variant.price).toFixed(2)}
                        </Typography>
                    </Box>
                    <Typography variant="body1">RM{(Number(variant.price) * variant.quantity).toFixed(2)}</Typography>
                </Box>
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2 }}>
                <Typography variant="body1">Booking Fee</Typography>
                <Typography variant="body1">RM{bookingFee.toFixed(2)}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Total Amount</Typography>
                <Typography variant="h6">RM{totalAmount.toFixed(2)}</Typography>
            </Box>
        </Paper>
    );
};

export default PaymentSummary;
import { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, Alert, Skeleton } from '@mui/material';
import ChartComponent from "./ChartComponent";
import keycloak from "../../keycloak";

const MainDashboard = () => {
    const [organizationId, setOrganizationId] = useState(null);
    const [chartData, setChartData] = useState({
        daily: null,
        weekly: null,
        monthly: null,
        quarterly: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const timeRanges = ['daily', 'weekly', 'monthly', 'quarterly'];

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            const checkAuthentication = () => {
                if (keycloak.authenticated) {
                    const token = keycloak.tokenParsed;
                    const organization = token?.organization;
                    setOrganizationId(organization);
                    // console.log('Organization ID:', organization);
                    fetchAllChartData();
                } else {
                    console.log('User is not authenticated');
                    setLoading(false);
                    setError('User is not authenticated. Please log in.');
                    // Optionally redirect to login
                    // keycloak.login();
                }
            };

            checkAuthentication();
        }
    }, [isMounted]);

    const fetchAllChartData = async () => {
        const apiEndpoints = {
            daily: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-daily',
            weekly: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-weekly',
            monthly: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-monthly',
            quarterly: 'https://api-dev.eventlah.com/api/merchant-reportings?report=line-quarterly'
        };

        setLoading(true);
        setError(null);
        const newChartData = {};

        for (const [key, url] of Object.entries(apiEndpoints)) {
            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${keycloak.token}`
                    }
                });
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error?.message || `HTTP error! status: ${response.status}`);
                }
                // console.log(`Data fetched for ${key}:`, data);
                newChartData[key] = data.data;
            } catch (error) {
                console.error(`Error fetching ${key} data:`, error);
                newChartData[key] = [];
                if (key === 'quarterly') {
                    setError(`Quarterly data is currently unavailable. Error: ${error.message}`);
                }
            }
        }

        // console.log('All chart data:', newChartData);
        setChartData(newChartData);
        setLoading(false);
    };

    return (
        <Container>
            {error && <Alert severity="warning" style={{marginBottom: '20px'}}>{error}</Alert>}
            {loading ? (
                <Grid container spacing={3}>
                    {timeRanges.map((range) => (
                        <Grid item xs={12} sm={6} key={range}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {range.charAt(0).toUpperCase() + range.slice(1)} Overview
                                    </Typography>
                                    <Box height={300}>
                                        <Skeleton variant="rectangular" width="100%" height={300} />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={3}>
                    {timeRanges.map((range) => (
                        <Grid item xs={12} sm={6} key={range}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {range.charAt(0).toUpperCase() + range.slice(1)} Overview
                                    </Typography>
                                    <Box height={300}>
                                        {chartData[range] ? (
                                            <ChartComponent data={chartData[range]} timeRange={range} />
                                        ) : (
                                            <Skeleton variant="rectangular" width="100%" height={300} />
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
};

export default MainDashboard;

import {useEffect, useState} from 'react';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {fetchUsers} from "./UsersAPI";

const SkeletonRow = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', height: 52, px: 2 }}>
        <Skeleton variant="rectangular" width="20%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="30%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rectangular" width="15%" height={20} sx={{ mr: 2 }} />
        <Skeleton variant="circular" width={24} height={24} sx={{ mr: 1 }} />
        <Skeleton variant="circular" width={24} height={24} />
    </Box>
);

const Revenues = () => {
    const [agents, setAgents] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);

    const loadAgents = async () => {
        setLoading(true);
        try {
            const data = await fetchUsers(paginationModel.page, paginationModel.pageSize, searchQuery);
            setAgents(data.data.users);
            setTotalCount(data.data.pagination.totalCount || 0);
        } catch (error) {
            console.error('Error loading agents:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadAgents();
    }, [paginationModel, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 90 },
        { field: 'first_name', headerName: 'First Name', flex: 2, minWidth: 150 },
        { field: 'last_name', headerName: 'Last Name', flex: 2, minWidth: 150 },
        { field: 'email', headerName: 'Email', flex: 3, minWidth: 200 },
        {
            field: 'phone_number',
            headerName: 'Phone Number',
            flex: 2,
            minWidth: 150,
        },
        { field: 'keycloak_id', headerName: 'Keycloak ID', flex: 4, minWidth: 250 },
        // Uncomment and modify if you need an actions column
        // {
        //     field: 'actions',
        //     headerName: 'Actions',
        //     flex: 2,
        //     minWidth: 150,
        //     renderCell: (params) => (
        //         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
        //             <IconButton aria-label="view">
        //                 <ViewIcon/>
        //             </IconButton>
        //             <IconButton aria-label="edit" onClick={() => handleEditClick(params.row)}>
        //                 <EditIcon/>
        //             </IconButton>
        //             <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row)}>
        //                 <DeleteIcon/>
        //             </IconButton>
        //         </Box>
        //     ),
        // },
    ];

    return (
        <Box>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search agents"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        Revenues
                    </Typography>

                    {loading ? (
                        <Box>
                            <Box sx={{ px: 2, py: 1 }}>
                                <Skeleton variant="rectangular" height={52} />
                            </Box>
                            {[...Array(5)].map((_, index) => (
                                <SkeletonRow key={index} />
                            ))}
                        </Box>
                    ) : error ? (
                        <Typography color="error" sx={{ p: 2 }}>{error}</Typography>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={agents}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={loading}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default Revenues;
import Confetti from 'react-confetti';
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {setCurrentStep} from "../../redux/accountOnboardingSlice";
import {useDispatch} from "react-redux";

const Finish = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleGoToDashboard = () => {
        dispatch(setCurrentStep('completed'));
        // navigate('/');
    };

    return (
        <>
            <Confetti recycle={false} numberOfPieces={500} tweenDuration={10000} />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ textAlign: 'center', mt: 5, px: 2 }}
            >
                <Box
                    component="img"
                    sx={{
                        width: { xs: '80px', sm: '100px' },
                        height: 'auto',
                        mb: 2,
                    }}
                    alt="Confetti"
                    src="/confetti.svg"
                />
                <Typography variant="h1">
                    Account Created Successfully!
                </Typography>
                <Typography variant="h2">
                    You're all set to start your journey with Eventlah. Let's get started!
                </Typography>
                <Button
                    variant="save"
                    onClick={handleGoToDashboard}
                    sx={{
                        width: { xs: '100%', sm: '80%', md: '700px' },
                        maxWidth: '700px',
                        mt:3
                    }}
                >
                    Start Now
                </Button>
            </Box>
        </>
    );
};

export default Finish;

import { Box, TextField, MenuItem, IconButton, InputAdornment, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const SectionTitle = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
});

const AddButton = styled(Box)({
    color: '#1570EF',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    marginTop: '16px',
    '&:hover': {
        opacity: 0.8
    }
});

const dayOptions = [
    { value: 'weekdays', label: 'Weekdays' },
    { value: 'weekends', label: 'Weekends' },
    { value: 'everyday', label: 'Everyday' }
];

const PeakHoursSection = ({ peakHourRules, setPeakHourRules }) => {
    const handleAddPeakHour = () => {
        const newRule = {
            id: Date.now().toString(),
            days: 'weekdays',
            timeStart: '',
            timeEnd: '',
            rateAdjustment: ''
        };
        setPeakHourRules([...peakHourRules, newRule]);
    };

    const handlePeakHourChange = (ruleId, field, value) => {
        const updatedRules = peakHourRules.map(rule => {
            if (rule.id === ruleId) {
                return { ...rule, [field]: value };
            }
            return rule;
        });
        setPeakHourRules(updatedRules);
    };

    const handleDeletePeakHour = (ruleId) => {
        setPeakHourRules(peakHourRules.filter(rule => rule.id !== ruleId));
    };

    return (
        <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <SectionTitle>Peak Hours</SectionTitle>
                <AddButton onClick={handleAddPeakHour}>
                    <AddIcon sx={{ fontSize: 20 }} />
                    Add Peak Hour Rule
                </AddButton>
            </Box>

            {peakHourRules.map((rule) => (
                <Box key={rule.id}>
                    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
                        <TextField
                            select
                            value={rule.days}
                            onChange={(e) => handlePeakHourChange(rule.id, 'days', e.target.value)}
                            fullWidth
                        >
                            {dayOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            type="time"
                            value={rule.timeStart}
                            onChange={(e) => handlePeakHourChange(rule.id, 'timeStart', e.target.value)}
                        />

                        <TextField
                            type="time"
                            value={rule.timeEnd}
                            onChange={(e) => handlePeakHourChange(rule.id, 'timeEnd', e.target.value)}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                value={rule.rateAdjustment}
                                onChange={(e) => handlePeakHourChange(rule.id, 'rateAdjustment', e.target.value)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                            {peakHourRules.length > 1 && (
                                <IconButton onClick={() => handleDeletePeakHour(rule.id)} sx={{ color: '#F04438' }}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default PeakHoursSection;
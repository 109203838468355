import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Paper,
    Button,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    FormControlLabel,
    Link,
    CircularProgress,
    Alert,
    Snackbar,
} from '@mui/material';
import { styled } from '@mui/system';
import { ChromePicker } from 'react-color';
import { CompanyLogo } from "../../../header/HeaderSvgImages";
import { fetchThemeComponents, updateThemeComponent } from '../../../../redux/themeComponentsSlice';
import keycloak from "../../../../keycloak";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';
import { fetchOrganizationPublicDetails } from "../../../../redux/organizationPublicDetailsSlice";

const s3Client = new S3Client({
    region: "ap-southeast-5",
    credentials: {
        accessKeyId: process.env.AWS_BITBUCKET_ACCESS_KEY_ID,
        secretAccessKey: process.env.AWS_BITBUCKET_SECRET_ACCESS_KEY
    },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
}));

const ColorBox = styled(Box)(({ bgcolor }) => ({
    width: '24px',
    height: '24px',
    backgroundColor: bgcolor,
    cursor: 'pointer',
    border: '1px solid #000',
    borderRadius: '50%',
    marginRight: '8px',
}));

const InvoiceTab = () => {
    const dispatch = useDispatch();
    const { themeComponents, status, error } = useSelector(state => state.themeComponents);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success or error

    const [formData, setFormData] = useState({
        logo: <CompanyLogo/>,
        mainText: 'Invoice',
        description: "Thank you for choosing us. Below is the detailed summary of your recent purchase.",
        invoiceNumber: 'INV-001',
        invoiceDate: '2024-09-28',
        dueDate: '2024-10-28',
        customerName: 'John Doe',
        customerEmail: 'john.doe@example.com',
        items: [
            { description: 'Product 1', quantity: 2, price: 50 },
            { description: 'Product 2', quantity: 1, price: 100 },
        ],
        subtotal: 200,
        tax: 20,
        total: 220,
        footerText: 'Payment is due within 30 days. Thank you for your business!',
        paymentButton: 'Pay Now',
        font: 'Helvetica',
        companyName: '',
        companyAddress: '',
        businessWebsite: '',
        showCompanyName: false,
        showCompanyAddress: false,
        showBusinessWebsite: false,
        colors: {
            body: {
                text: '#333333',
                buttonsAndLinks: '#ffeb3b',
                buttonLabelsAndOutlines: '#434040',
            },
            background: {
                background1: '#FFFFFF',
                background2: '#F5F5F5',
            },
            footer: {
                textAndIcons: '#666666',
                background: '#F9F9F9',
            },
        },
    });

    const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
    const [activeColorField, setActiveColorField] = useState(null);

    const organizationPublicDetails = useSelector((state) => state.organizationPublicDetails.details);

    useEffect(() => {
        if (organizationPublicDetails && organizationPublicDetails.length > 0) {
            setFormData(prevData => ({
                ...prevData,
                companyName: organizationPublicDetails[0]?.data?.business_name || '',
                companyAddress: `${organizationPublicDetails[0]?.data?.customer_support_address_line_1 || ''}, ${organizationPublicDetails[0]?.data?.customer_support_address_line_2 || ''}, ${organizationPublicDetails[0]?.data?.postal_code || ''}, ${organizationPublicDetails[0]?.data?.city || ''}, ${organizationPublicDetails[0]?.data?.state || ''}`.replace(/^[,\s]+|[,\s]+$/g, '').replace(/,\s*,/g, ','),
                businessWebsite: organizationPublicDetails[0]?.data?.business_website || '',
            }));
        }
    }, [organizationPublicDetails]);

    useEffect(() => {
        if (status === 'idle' && !isEditing) {
            const fetchData = async () => {
                try {
                    const token = await keycloak.updateToken(30);
                    const organizationId = keycloak.tokenParsed?.organization_id;
                    if (organizationId) {
                        dispatch(fetchOrganizationPublicDetails(organizationId));
                        dispatch(fetchThemeComponents({ page: 1, limit: 10, organization_id: organization }));
                    } else {
                        console.error('Organization not found in the token');
                    }
                } catch (error) {
                    console.error('Failed to update token or fetch theme components:', error);
                }
            };
            fetchData();
        }
    }, [dispatch, status, isEditing]);

    useEffect(() => {
        if (themeComponents.length > 0 && !isEditing) {
            const invoiceComponents = themeComponents.filter(component => component.name === 'invoice');
            if (invoiceComponents.length > 0) {
                const invoiceComponent = invoiceComponents[0];
                setFormData(prevData => ({
                    ...prevData,
                    mainText: invoiceComponent.main_text || 'Invoice',
                    description: invoiceComponent.description || "Thank you for choosing us. Below is the detailed summary of your recent purchase.",
                    font: invoiceComponent.typography || 'Helvetica',
                    showCompanyName: invoiceComponent.show_company_name || false,
                    showCompanyAddress: invoiceComponent.show_company_address || false,
                    showBusinessWebsite: invoiceComponent.show_business_website || false,
                    colors: {
                        body: {
                            text: invoiceComponent.body_text_color || '#333333',
                            buttonsAndLinks: invoiceComponent.button_color || '#ffeb3b',
                            buttonLabelsAndOutlines: invoiceComponent.button_label_color || '#434040',
                        },
                        background: {
                            background1: invoiceComponent.background_color_1 || '#FFFFFF',
                            background2: invoiceComponent.background_color_2 || '#F5F5F5',
                        },
                        footer: {
                            textAndIcons: invoiceComponent.footer_text_color || '#666666',
                            background: invoiceComponent.footer_background_color || '#F9F9F9',
                        },
                    },
                    footerText: invoiceComponent.other_text_1 || 'Payment is due within 30 days. Thank you for your business!',
                    paymentButton: invoiceComponent.other_text_2 || 'Pay Now',
                    logo: invoiceComponent.logo || <CompanyLogo/>,
                }));
            }
        }
    }, [themeComponents, isEditing]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setIsEditing(true);
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        setIsLoading(true); // Start loading when saving
        const invoiceComponent = themeComponents.find(component => component.name === 'invoice');
        if (invoiceComponent) {
            const serializableData = {
                name: 'invoice',
                main_text: formData.mainText,
                description: formData.description,
                typography: formData.font,
                show_company_name: formData.showCompanyName,
                show_company_address: formData.showCompanyAddress,
                show_business_website: formData.showBusinessWebsite,
                body_text_color: formData.colors.body.text,
                button_color: formData.colors.body.buttonsAndLinks,
                button_label_color: formData.colors.body.buttonLabelsAndOutlines,
                background_color_1: formData.colors.background.background1,
                background_color_2: formData.colors.background.background2,
                footer_text_color: formData.colors.footer.textAndIcons,
                footer_background_color: formData.colors.footer.background,
                other_text_1: formData.footerText,
                other_text_2: formData.paymentButton,
                logo: formData.logo,
            };

            try {
                const result = await dispatch(updateThemeComponent({
                    id: invoiceComponent.id,
                    themeComponentData: serializableData
                })).unwrap();

                setSnackbarSeverity('success');
                setSnackbarMessage('Invoice updated successfully!');
                setSnackbarOpen(true); // Show success message

                setFormData(prevData => ({
                    ...prevData,
                    ...serializableData
                }));

                const organization = keycloak.tokenParsed?.organization;
                if (organization) {
                    await dispatch(fetchThemeComponents({ page: 1, limit: 10, organization_id: organization }));
                }

                setIsEditing(false);
            } catch (error) {
                setSnackbarSeverity('error');
                setSnackbarMessage('Failed to update invoice.');
                setSnackbarOpen(true); // Show error message
                console.error('Update failed:', error);
            } finally {
                setIsLoading(false); // Stop loading after the save process finishes
            }
        } else {
            setSnackbarSeverity('error');
            setSnackbarMessage('Invoice component not found.');
            setSnackbarOpen(true); // Show error message
            setIsLoading(false); // Stop loading
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setIsEditing(true);
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleColorChange = (color) => {
        if (!activeColorField) return;

        setIsEditing(true);
        setFormData((prevData) => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [activeColorField.group]: {
                    ...prevData.colors[activeColorField.group],
                    [activeColorField.field]: color.hex,
                },
            },
        }));
    };

    const handleColorBoxClick = (event, group, field) => {
        setColorPickerAnchor(event.currentTarget);
        setActiveColorField({ group, field });
    };

    const handleLogoUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploading(true);
            try {
                const timestamp = Date.now();
                const uniqueId = uuidv4().slice(0, 6);
                const fileNameParts = file.name.split('.');
                const fileExtension = fileNameParts.pop();
                const fileName = fileNameParts.join('.');
                const uniqueFileName = `${fileName}_${timestamp}_${uniqueId}.${fileExtension}`;
                const organizationId = keycloak.tokenParsed?.organization || '1';
                const key = `${organizationId}/${uniqueFileName}`;

                const params = {
                    Bucket: "my.merchants",
                    Key: key,
                    Body: file,
                    ContentType: file.type,
                };

                const response = await s3Client.send(new PutObjectCommand(params));
                console.info('S3 upload response:', response);

                const fileUrl = `https://s3.ap-southeast-5.amazonaws.com/my.merchants/${encodeURIComponent(key)}`;

                const invoiceComponent = themeComponents.find(component => component.name === 'invoice');
                const result = await dispatch(updateThemeComponent({
                    id: invoiceComponent.id,
                    themeComponentData: {
                        logo: fileUrl
                    }
                })).unwrap();

                setFormData((prevData) => ({
                    ...prevData,
                    logo: fileUrl,
                }));
                setIsEditing(true);
            } catch (err) {
                console.error("Error uploading file:", err);
                // Handle error (e.g., show error message to user)
            } finally {
                setUploading(false);
            }
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const renderColorOption = (group, field, label, allowNone = false) => {
        const color = formData.colors[group][field];
        return (
            <Box display="flex" alignItems="center" mb={1}>
                <ColorBox
                    bgcolor={color || 'transparent'}
                    onClick={(e) => handleColorBoxClick(e, group, field)}
                />
                <Typography variant="body2">
                    {label}
                    {color ? ` ${color}` : ' None'}
                </Typography>
            </Box>
        );
    };

    const fontOptions = [
        'Arial', 'Helvetica', 'Verdana', 'Trebuchet MS', 'Gill Sans',
        'Noto Sans', 'Avantgarde', 'Optima', 'Arial Narrow', 'sans-serif',
        'Times', 'Times New Roman', 'Didot', 'Georgia', 'Palatino',
        'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    if (status === 'failed') {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Typography color="error">Error: {error}</Typography>
        </Box>;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' }, // Column for mobile, row for larger screens
                minHeight: '100vh',
            }}
        >
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 0.8 }, // Full width on mobile, 0.8 on larger screens
                    overflowY: 'auto',
                    borderRadius: '12px',
                    p: { xs: 2, md: 3 }, // Adjust padding for mobile
                }}
            >
                <Typography sx={{ fontSize: '16px', fontWeight: '700' }} gutterBottom>
                    Logo
                </Typography>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                    onChange={handleLogoUpload}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="save" component="span" disabled={uploading}>
                        {uploading ? <CircularProgress size={24} /> : 'Upload Logo'}
                    </Button>
                </label>

                <Typography variant="h6" gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Main Text
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="mainText"
                    value={formData.mainText}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Description
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />

                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }}>
                    Typography
                </Typography>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel>Font</InputLabel>
                    <Select
                        value={formData.font}
                        onChange={handleInputChange}
                        label="Font"
                        name="font"
                    >
                        {fontOptions.map((font) => (
                            <MenuItem key={font} value={font}>
                                {font}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Company Information
                    <Alert severity="info" sx={{ mt: 1, mb: 2 }}>
                        If you want to change your business details,{' '}
                        <Link
                            href="/settings/profile-settings#public-details"
                            underline="always"
                            sx={{ color: 'black', '&:hover': { color: 'black' }, fontWeight: 'bold' }}
                        >
                            click here
                        </Link>
                    </Alert>
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyName}
                                onChange={handleCheckboxChange}
                                name="showCompanyName"
                            />
                        }
                        label="Show Company Name"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showCompanyAddress}
                                onChange={handleCheckboxChange}
                                name="showCompanyAddress"
                            />
                        }
                        label="Show Company Address"
                        sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.showBusinessWebsite}
                                onChange={handleCheckboxChange}
                                name="showBusinessWebsite"
                            />
                        }
                        label="Show Business Website"
                        sx={{ marginLeft: 0 }}
                    />
                </Box>

                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Colors
                </Typography>
                <Box mb={2}>
                    <Typography variant="subtitle1">Body</Typography>
                    {renderColorOption('body', 'text', 'Text')}
                    {renderColorOption('body', 'buttonsAndLinks', 'Buttons and links')}
                    {renderColorOption('body', 'buttonLabelsAndOutlines', 'Button labels and outlines')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Background</Typography>
                    {renderColorOption('background', 'background1', 'Background 1')}
                    {renderColorOption('background', 'background2', 'Background 2')}
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle1">Footer</Typography>
                    {renderColorOption('footer', 'textAndIcons', 'Text and icons', true)}
                    {renderColorOption('footer', 'background', 'Background', true)}
                </Box>

                <Typography sx={{ fontSize: '16px', fontWeight: '700', mt: 2 }} gutterBottom>
                    Other Texts
                </Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    name="footerText"
                    label="Footer Text"
                    value={formData.footerText}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    name="paymentButton"
                    label="Payment Button Text"
                    value={formData.paymentButton}
                    onChange={handleInputChange}
                    sx={{ mb: 2 }}
                />
                <Button variant="save" onClick={handleSave} disabled={isLoading || !isEditing}>
                    {isLoading ? <CircularProgress size={24} /> : 'Save Changes'}
                </Button>
            </StyledPaper>

            {/* Preview Section */}
            <StyledPaper
                elevation={3}
                sx={{
                    flex: { xs: 1, md: 1 }, // Full width on mobile, partial width on larger screens
                    borderRadius: '12px',
                    mt: { xs: 3, md: 0 }, // Add margin-top for mobile
                    p: { xs: 2, md: 3 }, // Adjust padding for mobile
                }}
            >
                <Box
                    sx={{
                        bgcolor: formData.colors.background.background1,
                        p: 2,
                        mb: 6,
                        mt: 3,
                        borderRadius: '12px',
                        fontFamily: formData.font,
                    }}
                >
                    <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {typeof formData.logo === 'string' ? (
                            <img src={formData.logo} alt="Company Logo" style={{ maxWidth: '100px', height: 'auto' }} />
                        ) : (
                            formData.logo
                        )}
                        <Typography variant="h4" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                            {formData.mainText}
                        </Typography>
                    </Box>
                    <Box sx={{ backgroundColor: 'black', height: '1px', mx: 'auto', mb: 3 }} />
                    <Box sx={{ mb: 2 }}>
                        {formData.showCompanyName && (
                            <Typography variant="h6" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                {formData.companyName}
                            </Typography>
                        )}
                        {formData.showCompanyAddress && (
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                {formData.companyAddress}
                            </Typography>
                        )}
                        {formData.showBusinessWebsite && (
                            <Link
                                href={formData.businessWebsite}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: formData.colors.body.buttonLabelsAndOutlines,
                                    fontFamily: 'inherit',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            >
                                {formData.businessWebsite}
                            </Link>
                        )}
                    </Box>
                    <Typography variant="body1" sx={{ color: formData.colors.body.text, fontFamily: 'inherit', pb: 2 }}>
                        {formData.description}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Invoice Number: {formData.invoiceNumber}
                            </Typography>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Invoice Date: {formData.invoiceDate}
                            </Typography>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Due Date: {formData.dueDate}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Customer: {formData.customerName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: formData.colors.body.text, fontFamily: 'inherit' }}>
                                Email: {formData.customerEmail}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        bgcolor: formData.colors.background.background2,
                        p: 2,
                        mb: 6,
                        borderRadius: '12px',
                        fontFamily: formData.font,
                    }}
                >
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formData.items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell align="right">{item.quantity}</TableCell>
                                        <TableCell align="right">RM {item.price.toFixed(2)}</TableCell>
                                        <TableCell align="right">RM {(item.quantity * item.price).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right">Subtotal:</TableCell>
                                    <TableCell align="right">RM {formData.subtotal.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right">Tax:</TableCell>
                                    <TableCell align="right">RM {formData.tax.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} />
                                    <TableCell align="right">
                                        <strong>Total:</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong>RM {formData.total.toFixed(2)}</strong>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        sx={{
                            bgcolor: formData.colors.footer.background || 'transparent',
                            color: formData.colors.footer.textAndIcons || formData.colors.body.text,
                            p: 2,
                            mt: 2,
                            textAlign: 'center',
                            fontFamily: formData.font,
                        }}
                    >
                        <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                            {formData.footerText}
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                mt: 2,
                                bgcolor: formData.colors.body.buttonsAndLinks,
                                color: formData.colors.body.buttonLabelsAndOutlines,
                                fontFamily: 'inherit',
                            }}
                        >
                            {formData.paymentButton}
                        </Button>
                    </Box>
                </Box>
            </StyledPaper>

            <Popover
                open={Boolean(colorPickerAnchor)}
                anchorEl={colorPickerAnchor}
                onClose={() => {
                    setColorPickerAnchor(null);
                    setActiveColorField(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <ChromePicker
                    color={activeColorField ? formData.colors[activeColorField.group][activeColorField.field] : '#fff'}
                    onChange={handleColorChange}
                />
            </Popover>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>

    );
};

export default InvoiceTab;
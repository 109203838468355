import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Paper,
    Typography,
    TextField,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Skeleton,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from "axios";
import keycloak from "../../../keycloak";

const TransactionHistory = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [organizationId, setOrganizationId] = useState(1);
    const dispatch = useDispatch();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [error, setError] = useState(null);
    // const transactionsData = useSelector((state) => state.transactions.transactions);
    const [transactionsData, setTransactionsData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const { pagination, status } = useSelector((state) => state.transactions);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });
    const [totalCount, setTotalCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [openPaymentSuccessDialog, setOpenPaymentSuccessDialog] = useState(false);

    useEffect(() => {
        const fetchTransactionsData = async () => {
            setIsInitialLoad(true);
            setError(null);
            try {
                const response = await axios.get(`${API_BASE_URL}/purchases`, {
                    params: {
                        page: paginationModel.page + 1,
                        pageSize: paginationModel.pageSize,
                        search: searchQuery,
                        organization_id: organizationId,
                        status_id: 3,
                        timeframe: 'current'
                    },
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`
                    }
                });

                if (response.data.code === 200 && response.data.data && Array.isArray(response.data.data.purchases)) {
                    const mappedData = response.data.data.purchases.map((purchase) => {
                        const { id, created_at, invoice_number, total_amount, purchase_type, customer, agent, ticketing_details } = purchase;
                        return {
                            id,
                            created_at,
                            invoice_number,
                            total_amount,
                            purchase_type,
                            // ticketing_total_amount,
                            // ticketing_total_quantity,
                            // ticketing_invoice_number: ticketing_details[0]?.event_id,
                            customer_name: `${customer?.first_name || ''} ${customer?.last_name || ''}`.trim(),
                            agent_name: agent ? `${agent.first_name || ''} ${agent.last_name || ''}`.trim() : 'N/A',
                            // event_name: ticketing_details[0]?.event?.name || 'No event',
                            // event_date: ticketing_details[0]?.event?.daily_events[0]?.event_date || 'No daily event',
                            // ticket_category: ticketing_details[0]?.ticket_category || 'N/A',
                        };
                    });
                    console.log('mappedData', mappedData);
                    setTransactionsData(mappedData);
                    setTotalRows(response.data.data.pagination?.totalCount || 0);
                } else {
                    throw new Error(response.data.message || 'Invalid data structure received from the server');
                }
            } catch (err) {
                setError('Failed to fetch transactions data: ' + (err.message || 'Unknown error'));
                console.error('Error fetching transactions data:', err);
                setTransactionsData([]);
            } finally {
                setIsInitialLoad(false);
            }
        };

        fetchTransactionsData();
    }, [paginationModel.page, paginationModel.pageSize, searchQuery, organizationId]);

    useEffect(() => {
        if (pagination) {
            setTotalCount(pagination.totalCount || 0);
        }
    }, [pagination]);

    useEffect(() => {
        console.log('Raw transactionsData:', transactionsData);
    }, [transactionsData]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const handleConfirm = () => {
        setOpen(false);
        setOpenPaymentSuccessDialog(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderSkeletons = () => (
        <Box sx={{padding:'0 32px 24px 32px'}}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mx: 1, my: 2 }}>
                    <Skeleton variant="rectangular" width={60} height={40} sx={{ mr: 5, borderRadius:'4px' }} />
                    <Box sx={{ width: '100%' }}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const rows = useMemo(() => {
        console.log('Transforming transactionsData:', transactionsData);
        return transactionsData?.map(transaction => ({
            ...transaction
        })) || [];
    }, [transactionsData]);

    console.log('Transformed rows:', rows);

    const columns = [
        {
            field: 'invoice_number',
            headerName: 'Invoice Number',
            flex: 0.5,
            minWidth: 150,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'purchase_type',
            headerName: 'Type',
            flex: 0.2,
            minWidth: 50,
        },
        {
            field: 'created_at',
            headerName: 'Purchase Date',
            flex: 1,
            minWidth: 100,
            valueFormatter: (params) => {
                return new Date(params).toLocaleDateString();
            },
        },
        {
            field: 'customer_name',
            headerName: 'Customer Name',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'total_amount',
            headerName: 'Total Amount',
            flex: 0.7,
            minWidth: 200,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
                    <Typography variant="body2">RM{params.value}</Typography>
                </Box>
            )
        }
    ];

    return (
        <Box sx={{ display:'flex' }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Transaction History</Typography>
                        <Typography variant='h2'>View your transaction history and details.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', alignItems: 'center', gap:1 }}>
                        <Button variant='save' startIcon={<AddIcon />}>
                            Create payment
                        </Button>
                        <Button variant='cancel' onClick={() => setOpen(true)}>
                            Verify this Order
                        </Button>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Description"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Transactions
                    </Typography>

                    {(isInitialLoad || status === 'loading') ? (
                        renderSkeletons()
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={rows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={totalCount}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            loading={status === 'loading'}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>

            {/* Dialog for Verification */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Verify Order</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to verify this order?</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mb: 1 }}>
                    <Button variant='save' onClick={handleConfirm}>Confirm</Button>
                    <Button variant='cancel' onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Payment Success Dialog */}
            <Dialog
                open={openPaymentSuccessDialog}
                onClose={() => setOpenPaymentSuccessDialog(false)}
                maxWidth='xs'
                fullWidth
            >
                <DialogContent
                    sx={{
                        textAlign: 'center',
                        padding: { xs: '24px', sm: '32px' },
                    }}
                >
                    <CheckCircleIcon
                        color='success'
                        sx={{ fontSize: { xs: 40, sm: 60 } }}
                    />
                    <Typography
                        variant='h5'
                        fontWeight='bold'
                        gutterBottom
                        sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}
                    >
                        Order Successfully Verified!
                    </Typography>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        gutterBottom
                    >
                        The order has been successfully verified. A confirmation email has been sent to admin@mycompany.com.
                    </Typography>
                    <Typography variant='body2' fontWeight='bold' gutterBottom>
                        Order Number
                    </Typography>
                    <Typography variant='h6' gutterBottom>
                        12345
                    </Typography>
                    <Button
                        variant='save'
                        onClick={() => setOpenPaymentSuccessDialog(false)}
                        sx={{
                            marginTop: '16px',
                            width: { xs: '100%', sm: 'auto' },
                        }}
                    >
                        Return to Orders
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default TransactionHistory;
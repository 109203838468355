import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    IconButton,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VariantTable from './VariantTable';

const VariantForm = ({
                         variants,
                         error,
                         addVariant,
                         updateVariant,
                         updateVariantValue,
                         addOptionValue,
                         removeVariant,
                         removeOptionValue,
                         handleSave,
                         loading,
                         product
                     }) => {
    return (
        <Box sx={{ width: '50%', paddingRight: 2 }}>
            <Typography sx={{ fontSize: '22px', fontWeight: '500', mb: 1 }}>
                Variants
            </Typography>
            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            {variants.length === 0 && (
                <Button
                    onClick={addVariant}
                    variant='save'
                    sx={{ marginBottom: 2 }}
                >
                    + Add options like size or color
                </Button>
            )}
            {variants.map((variant) => (
                <Paper key={variant.id} elevation={3} sx={{ padding: 2, marginBottom: 2, borderRadius: '12px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <TextField
                            fullWidth
                            label="Option name"
                            placeholder="Enter option details (e.g., size, color, material)"
                            value={variant.optionName}
                            onChange={(e) => updateVariant(variant.id, 'optionName', e.target.value)}
                            sx={{ marginRight: 1 }}
                        />
                        <IconButton onClick={() => removeVariant(variant.id)} sx={{width:'80px'}}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <VariantTable
                        variant={variant}
                        updateVariantValue={updateVariantValue}
                        removeOptionValue={removeOptionValue}
                        product={product}
                    />
                    <Button
                        onClick={() => addOptionValue(variant.id)}
                        variant='cancel'
                        sx={{
                            width: '100%',
                            height: '42px',
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                            fontWeight: '500',
                            fontSize: '16px',
                            marginBottom: '16px',
                            marginTop: '0px',
                        }}
                    >
                        + Add another value
                    </Button>
                </Paper>
            ))}
            {variants.length > 0 && (
                <Box sx={{ display: 'flex' }}>
                    <Button
                        onClick={addVariant}
                        variant='save'
                        sx={{ marginTop: 2, marginRight: 2 }}
                    >
                        + Add another option
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant='cancel'
                        disabled={loading}
                        sx={{ marginTop: 2 }}
                    >
                        {loading ? 'Saving...' : 'Save'}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default VariantForm;
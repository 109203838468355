import { useState, useEffect } from "react";
import { Box, Divider, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { createProduct, updateProduct } from "../../../../../redux/productSlice";
import keycloak from "../../../../../keycloak";
import SharedSelect from "../../../../shared/SharedSelect";
import {
    createProductCategory,
    fetchProductCategories,
    clearNewlyCreatedCategory
} from "../../../../../redux/productCategorySlice";

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    width: '312px',
}));

const CustomizeProduct = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const product = location.state?.product || {};
    const [organization, setOrganization] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(product.product_category_id || '');

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            id: product.id || '',
            title: product.name || '',
            category: product.product_category_id || '',
            price: product.price || '',
            description: product.description || '',
        }
    });

    useEffect(() => {
        const initKeycloak = async () => {
            try {
                if (keycloak.authenticated) {
                    const token = keycloak.tokenParsed;
                    setOrganization(token?.organization);
                }
            } catch (error) {
                console.error("Keycloak initialization failed:", error);
            }
        };

        initKeycloak();
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);

        const postData = {
            name: data.title,
            product_category_id: selectedCategoryId,
            price: data.price,
            minimum_pax: 0,
            maximum_pax: 0,
            minimum_amount: parseFloat(data.price),
            maximum_amount: parseFloat(data.price),
            description: data.description,
            user_organization_id: 1,
            user_role_id: 3,
            categoryId: 2,
            minimumCurrencyId: 2,
            maximumCurrencyId: 2,
            statusOwnershipId: 15,
        };

        try {
            if (data.id) {
                await dispatch(updateProduct({ id: data.id, postData })).unwrap();
            } else {
                await dispatch(createProduct(postData)).unwrap();
            }
            setSuccessDialogOpen(true);
        } catch (error) {
            console.error("There was an error creating/updating the product:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDialogClose = () => {
        setSuccessDialogOpen(false);
        navigate('/settings/product-catalog');
    };

    return (
        <Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Product Category</TextFieldTitle>
                    <Controller
                        name="category"
                        control={control}
                        render={({ field }) => (
                            <SharedSelect
                                {...field}
                                label="Enter the category for product"
                                value={selectedCategoryId}
                                onChange={(value) => {
                                    setSelectedCategoryId(value);
                                    field.onChange(value);
                                }}
                                options={[
                                    { name: 'name', label: 'Category Name' },
                                ]}
                                createNewOption={createProductCategory}
                                fetchOptions={fetchProductCategories}
                                selectSelector={(state) => state.productCategories.productCategories}
                                createSelector={(state) => state.productCategories.newlyCreatedCategory}
                                clearNewlyCreatedSelector={clearNewlyCreatedCategory}
                                errorSelector={(state) => state.productCategories.error}
                                sx={{ width: '100%' }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Product Name</TextFieldTitle>
                    <Controller
                        name="title"
                        control={control}
                        rules={{ required: "Product name is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the name for product"
                                fullWidth
                                error={!!errors.title}
                                helperText={errors.title?.message}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Product Price</TextFieldTitle>
                    <Controller
                        name="price"
                        control={control}
                        rules={{
                            required: "Price is required",
                            validate: {
                                positive: value => parseFloat(value) > 0 || "Please enter a valid price. Use numbers only (e.g., 59.99)",
                                float: value => /^[+-]?\d+(\.\d+)?$/.test(value) || "Please enter a valid price. Use numbers only (e.g., 59.99)"
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the pricing for product"
                                fullWidth
                                error={!!errors.price}
                                helperText={errors.price?.message}
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9.]*',
                                    step: '0.01',
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*\.?\d*$/.test(value)) {
                                        field.onChange(value);
                                    }
                                }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box display="flex" sx={{paddingRight: { xs: '24px', sm: '44px', md: '74px', lg: '264px' }}}>
                    <TextFieldTitle>Description for Product</TextFieldTitle>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Enter the description for product"
                                fullWidth
                                multiline
                                rows={7}
                                inputProps={{ maxLength: 250 }}
                                helperText={`${field.value.length}/250`}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: '15px',
                                        '& .MuiOutlinedInput-input': {
                                            padding: 0,
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </Box>

                <Divider />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button
                        variant="save"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : product.id ? 'Update Product' : 'Create Product'}
                    </Button>
                </Box>
            </form>

            <Dialog
                open={successDialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The product has been {product.id ? 'updated' : 'created'} successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='save' autoFocus sx={{margin:'0 18px 8px 0'}}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomizeProduct;
import { useState } from 'react';
import { Box, Button, CircularProgress, Divider } from "@mui/material";
import BasePrice from "./BasePrice";
import PricingRules from "./PricingRules/PricingRules";
import DepositSettings from "./DepositSettings/DepositSettings";
import DurationBasePricing from "./DurationBasePricing";

const PaymentStructure = () => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
        try {
            setIsLoading(true);
            // Add your save logic here
            // Example:
            // await savePaymentStructure(data);

            // Simulate API call
            await new Promise(resolve => setTimeout(resolve, 1000));

            // You might want to show a success message here
        } catch (error) {
            // Handle error appropriately
            console.error('Error saving payment structure:', error);
            // You might want to show an error message here
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        // Add your cancel logic here
        // Example:
        // - Reset form
        // - Navigate back
        // - Close modal
    };

    return (
        <>
            {/*Payment*/}
            <BasePrice />

            <Divider />

            {/*DurationBasedPricing*/}
            <DurationBasePricing />

            <Divider />

            {/*BookingDeposit*/}
            <DepositSettings />

            <Divider />

            {/*PricingRules*/}
            <PricingRules />

            <Divider />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', mt: 2 }}>
                <Button
                    variant="cancel"
                    onClick={handleCancel}
                    disabled={isLoading}
                >
                    Cancel
                </Button>
                <Button
                    variant="save"
                    onClick={handleSave}
                    disabled={isLoading}
                    startIcon={isLoading ? <CircularProgress size={20} /> : null}
                >
                    {isLoading ? 'Saving...' : 'Save'}
                </Button>
            </Box>
        </>
    );
};

export default PaymentStructure;
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Divider,
    CircularProgress, IconButton,
} from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fetchSubscriptionFeatures } from '../../redux/subscriptionFeaturesSlice';
import {Delete as DeleteIcon, Edit as EditIcon, Visibility as ViewIcon} from "@mui/icons-material";

const SubscriptionFeaturesList = () => {
    const dispatch = useDispatch();
    const subscriptionFeaturesData = useSelector((state) => state.subscriptionFeatures.features);
    const { pagination, status } = useSelector((state) => state.subscriptionFeatures);
    const [searchQuery, setSearchQuery] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchSubscriptionFeatures({
                page: paginationModel.page + 1,
                limit: paginationModel.pageSize,
            }));
        }
    }, [status, dispatch, paginationModel]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const handlePaginationModelChange = (newModel) => {
        setPaginationModel(newModel);
    };

    const filteredRows = useMemo(() => {
        if (!subscriptionFeaturesData || !Array.isArray(subscriptionFeaturesData)) {
            return [];
        }

        return subscriptionFeaturesData
            .map(feature => ({
                id: `${feature.subscription_id}-${feature.feature_id}`,
                subscriptionId: feature.subscription_id,
                featureId: feature.feature_id,
                createdAt: new Date(feature.created_at).toLocaleString(),
            }))
            .filter((row) =>
                row.subscriptionId && row.subscriptionId.toString().includes(searchQuery)
            );
    }, [subscriptionFeaturesData, searchQuery]);

    const columns = [
        {
            field: 'subscriptionId',
            headerName: 'Subscription ID',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'featureId',
            headerName: 'Feature ID',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="body2">{params.value}</Typography>
                </Box>
            )
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%', width: '100%' }}>
                    <IconButton aria-label="view" onClick={() => handleView(params.row.id)}>
                        <ViewIcon/>
                    </IconButton>
                    <IconButton aria-label="edit" onClick={() => handleEdit(params.row)}>
                        <EditIcon/>
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row.id)}>
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            )
        },
    ];

    return (
        <Box sx={{padding:'32px'}}>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                        <Typography variant='h1'>Subscription Features</Typography>
                        <Typography variant='h2'>View, edit, and manage all your subscription features in one place.</Typography>
                    </Box>
                </Box>

                <Divider/>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box/>
                    <Box display="flex" gap="12px">
                        <TextField
                            variant="outlined"
                            placeholder="Search by Subscription ID"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            size="small"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '34px',
                                    '& input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    },
                                    '& .MuiInputBase-input': {
                                        height: '34px',
                                        padding: '0 14px',
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Paper
                    sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginTop: '24px',
                        border: "1px solid var(--Gray-200, #EAECF0)",
                        background: "var(--White, #FFF)",
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Gray-900, #101828)",
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "28px",
                            padding: "20px 24px",
                        }}
                    >
                        All Subscription Features
                    </Typography>

                    {status === 'loading' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <DataGrid
                            slots={{ toolbar: GridToolbar }}
                            rows={filteredRows}
                            columns={columns}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[5, 10, 20]}
                            rowCount={pagination?.totalCount || 0}
                            paginationMode="server"
                            checkboxSelection
                            disableRowSelectionOnClick
                            rowHeight={70}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                '& .MuiDataGrid-toolbarContainer .MuiButtonBase-root': {
                                    backgroundColor: '#737373',
                                    color: '#fff',
                                    height: '34px',
                                    my:1,
                                    minWidth: 'auto',
                                    '&:hover': {
                                        backgroundColor: '#404040',
                                    },
                                },
                            }}
                        />
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default SubscriptionFeaturesList;
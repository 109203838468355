import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box
} from '@mui/material';

const EditShippingRateDialog = ({ open, onClose, shippingRate, onChange, onSave }) => {
    if (!shippingRate) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Shipping Rate</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                    <TextField
                        name="amount"
                        label="Amount"
                        type="number"
                        value={shippingRate.amount}
                        onChange={onChange}
                        fullWidth
                    />
                    <TextField
                        name="currency_id"
                        label="Currency ID"
                        value={shippingRate.currency_id}
                        onChange={onChange}
                        fullWidth
                    />
                    <TextField
                        name="description"
                        label="Description"
                        value={shippingRate.description}
                        onChange={onChange}
                        fullWidth
                        multiline
                        rows={3}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '15px',
                                '& .MuiOutlinedInput-input': {
                                    padding: 0,
                                },
                            },
                        }}
                    />
                    <TextField
                        name="estimated_shipping_time_min"
                        label="Min Shipping Time"
                        type="number"
                        value={shippingRate.estimated_shipping_time_min}
                        onChange={onChange}
                        fullWidth
                    />
                    <TextField
                        name="estimated_shipping_time_max"
                        label="Max Shipping Time"
                        type="number"
                        value={shippingRate.estimated_shipping_time_max}
                        onChange={onChange}
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='cancel' onClick={onClose}>Cancel</Button>
                <Button variant='save' onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditShippingRateDialog;
import { useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Checkbox,
    Button,
    FormControlLabel,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Alert
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AgentFirstStep from './AgentFirstStep';
import AgentSecondStep from './AgentSecondStep';
import AgentThirdStep from './AgentThirdStep';

const Section = ({ icon, title, content, onEdit, isComplete = true }) => (
    <Paper
        elevation={0}
        sx={{
            mb: 2.5,
            p: 2.5,
            borderRadius: 2,
            border: '1px solid',
            borderColor: isComplete ? '#E5E7EB' : '#ffeb3b',
            position: 'relative',
            '&:hover': {
                borderColor: '#ffeb3b',
                transition: 'all 0.2s ease-in-out'
            }
        }}
    >
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
        }}>
            <Box sx={{
                color: isComplete ? 'orange' : '#ffeb3b',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
                borderRadius: 1,
                bgcolor: isComplete ? '#FFF9E6' : '#FFFDE7'
            }}>
                {icon}
            </Box>
            <Box sx={{ flex: 1 }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1.5
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        color: 'black',
                        fontSize: '16px'
                    }}>
                        {title}
                        {isComplete && (
                            <CheckCircleOutlineIcon
                                sx={{
                                    ml: 1,
                                    fontSize: 18,
                                    color: 'orange',
                                    verticalAlign: 'text-bottom'
                                }}
                            />
                        )}
                    </Typography>
                    <Button
                        startIcon={<EditOutlinedIcon />}
                        onClick={onEdit}
                        sx={{
                            color: 'orange',
                            textTransform: 'none',
                            fontWeight: 500,
                            '&:hover': {
                                bgcolor: '#FFFDE7'
                            }
                        }}
                    >
                        Edit
                    </Button>
                </Box>
                {content}
            </Box>
        </Box>
    </Paper>
);

const InfoGrid = ({ items }) => (
    <Box sx={{
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
        gap: 2,
        '& .label': {
            color: '#666666',
            fontSize: '14px',
            mb: 0.5
        },
        '& .value': {
            color: 'black',
            fontSize: '14px',
            fontWeight: 500
        }
    }}>
        {items.map((item, index) => (
            <Box key={index}>
                <Typography className="label">{item.label}</Typography>
                <Typography className="value">{item.value}</Typography>
            </Box>
        ))}
    </Box>
);

const AgentFourthStep = () => {
    const [currentStep, setCurrentStep] = useState(null);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [confirmAccuracy, setConfirmAccuracy] = useState(false);

    const personalInfo = [
        { label: 'Full Name', value: 'John Doe' },
        { label: 'Email', value: 'johndoe@example.com' },
        { label: 'Address', value: '123 Main Street, Apt 4B' },
        { label: 'Location', value: 'Kuala Lumpur, 50000' },
        { label: 'Country', value: 'Malaysia' }
    ];

    const bankInfo = [
        { label: 'Bank Name', value: 'Example Bank' },
        { label: 'Account Number', value: '•••• •••• •••• 3456' },
        { label: 'Account Name', value: 'John Doe' }
    ];

    return (
        <Box>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" sx={{
                    fontWeight: 600,
                    color: 'black',
                    mb: 1
                }}>
                    Review & Submit
                </Typography>
                <Typography sx={{ color: '#666666' }}>
                    Please review your information before submitting
                </Typography>
            </Box>

            <Alert
                severity="info"
                sx={{
                    mb: 3,
                    borderRadius: 2,
                    '& .MuiAlert-icon': {
                        color: 'orange'
                    },
                    '& .MuiAlert-message': {
                        color: 'black'
                    },
                    backgroundColor: '#FFFDE7',
                    border: '1px solid #ffeb3b'
                }}
            >
                All fields have been completed. Please review your information carefully before proceeding.
            </Alert>

            <Section
                icon={<PersonOutlineOutlinedIcon />}
                title="Personal Information"
                content={<InfoGrid items={personalInfo} />}
                onEdit={() => setCurrentStep('personal')}
            />

            <Section
                icon={<AccountBalanceOutlinedIcon />}
                title="Bank Information"
                content={<InfoGrid items={bankInfo} />}
                onEdit={() => setCurrentStep('bank')}
            />

            <Section
                icon={<InsertDriveFileOutlinedIcon />}
                title="Uploaded Documents"
                content={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{
                            color: 'black',
                            fontSize: '14px',
                            fontWeight: 500
                        }}>
                            3 documents uploaded
                        </Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography sx={{
                            color: 'orange',
                            fontSize: '14px'
                        }}>
                            All required documents provided
                        </Typography>
                    </Box>
                }
                onEdit={() => setCurrentStep('documents')}
            />

            <Paper elevation={0} sx={{
                mt: 4,
                p: 2.5,
                borderRadius: 2,
                border: '1px solid #E5E7EB',
                mb: 4,
            }}>
                <Typography sx={{
                    fontWeight: 600,
                    color: 'black',
                    mb: 2
                }}>
                    Declaration
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agreeToTerms}
                                onChange={(e) => setAgreeToTerms(e.target.checked)}
                                sx={{ '&.Mui-checked': { color: 'orange' } }}
                            />
                        }
                        label={
                            <Typography sx={{ fontSize: '14px', color: '#374151' }}>
                                I agree to the Terms and Conditions and Privacy Policy
                            </Typography>
                        }
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={confirmAccuracy}
                                onChange={(e) => setConfirmAccuracy(e.target.checked)}
                                sx={{ '&.Mui-checked': { color: 'orange' } }}
                            />
                        }
                        label={
                            <Typography sx={{ fontSize: '14px', color: '#374151' }}>
                                I confirm that all provided information is accurate
                            </Typography>
                        }
                    />
                </Box>
            </Paper>

            <Dialog
                open={!!currentStep}
                onClose={() => setCurrentStep(null)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle sx={{
                    pb: 1,
                    fontWeight: 600,
                    color: 'black'
                }}>
                    Edit {currentStep === 'personal' ? 'Personal Information' :
                    currentStep === 'bank' ? 'Bank Information' :
                        'Documents'}
                </DialogTitle>
                <DialogContent>
                    {currentStep === 'personal' && <AgentFirstStep />}
                    {currentStep === 'bank' && <AgentSecondStep />}
                    {currentStep === 'documents' && <AgentThirdStep />}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                        mt: 3
                    }}>
                        <Button
                            variant="outlined"
                            onClick={() => setCurrentStep(null)}
                            sx={{
                                borderColor: '#E5E7EB',
                                color: 'black',
                                '&:hover': {
                                    borderColor: '#ffeb3b',
                                    backgroundColor: '#FFFDE7'
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setCurrentStep(null)}
                            sx={{
                                backgroundColor: '#ffeb3b',
                                color: 'black',
                                '&:hover': {
                                    backgroundColor: 'orange'
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default AgentFourthStep;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchSubscriptionFeatures = createAsyncThunk(
    'subscriptionFeatures/fetchSubscriptionFeatures',
    async ({ page, limit }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/subscription-features`, {
                headers,
                params: {
                    page,
                    limit,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createSubscriptionFeature = createAsyncThunk(
    'subscriptionFeatures/create',
    async (featureData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/subscription-features`, featureData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateSubscriptionFeature = createAsyncThunk(
    'subscriptionFeatures/update',
    async ({ subscriptionId, featureId, featureData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(
                `${API_BASE_URL}/subscription-features/${subscriptionId}/${featureId}`,
                featureData,
                { headers }
            );
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteSubscriptionFeature = createAsyncThunk(
    'subscriptionFeatures/delete',
    async ({ subscriptionId, featureId }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/subscription-features/${subscriptionId}/${featureId}`, { headers });
            return { subscriptionId, featureId };
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const subscriptionFeaturesSlice = createSlice({
    name: 'subscriptionFeatures',
    initialState: {
        features: [],
        status: 'idle',
        error: null,
        pagination: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubscriptionFeatures.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubscriptionFeatures.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = action.payload.data.subscriptionFeatures;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchSubscriptionFeatures.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch subscription features';
            })
            .addCase(createSubscriptionFeature.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createSubscriptionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features.push(action.payload);
            })
            .addCase(createSubscriptionFeature.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create subscription feature';
            })
            .addCase(updateSubscriptionFeature.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSubscriptionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.features.findIndex(
                    feature => feature.subscription_id === action.payload.subscription_id &&
                        feature.feature_id === action.payload.feature_id
                );
                if (index !== -1) {
                    state.features[index] = action.payload;
                }
            })
            .addCase(updateSubscriptionFeature.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update subscription feature';
            })
            .addCase(deleteSubscriptionFeature.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteSubscriptionFeature.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.features = state.features.filter(
                    feature => !(feature.subscription_id === action.payload.subscriptionId &&
                        feature.feature_id === action.payload.featureId)
                );
            })
            .addCase(deleteSubscriptionFeature.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete subscription feature';
            });
    },
});

export default subscriptionFeaturesSlice.reducer;
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, Divider, styled, TextField, FormControl, InputAdornment, FormGroup, FormControlLabel, Checkbox, Switch, Autocomplete, Chip } from '@mui/material';
import { useGetSpacesQuery } from '../../../api-services/spaceApi';
import { fetchProducts } from '../../../../redux/productSlice';
import { fetchEvents } from '../../../../redux/eventsSlice';

// Styled components remain the same...
const SettingRow = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': { backgroundColor: 'rgba(255, 235, 59, 0.04)' }
});

const SubSection = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#000',
    marginBottom: '16px'
});

const InlineFormGroup = styled(FormGroup)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '24px',
    '& .MuiFormControlLabel-root': {
        margin: 0,
        borderRadius: '6px',
        transition: 'all 0.2s',
        '&:hover': { backgroundColor: 'rgba(255, 235, 59, 0.08)' }
    },
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 500
    }
});

const CategoryLabel = styled(FormControlLabel)({
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '16px',
    marginLeft: 3,
});

const LabelBox = styled(Box)({
    '& .title': {
        fontSize: '15px',
        fontWeight: 600,
        marginBottom: '2px'
    },
    '& .subtitle': {
        fontSize: '13px',
        color: 'rgba(0, 0, 0, 0.6)'
    }
});

const NestedSection = styled(Box)({
    marginBottom: '24px',
});

const InputContainer = styled(Box)({
    maxWidth: '280px',
    width: '100%',
});

const ItemsPreview = styled(Box)({
    marginTop: '8px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    padding: '8px',
    borderRadius: '4px',
    minHeight: '32px',
    border: '1px solid rgba(255, 235, 59, 0.2)'
});

const StyledChip = styled(Chip)({
    height: '24px',
    fontSize: '12px',
    backgroundColor: 'rgba(255, 152, 0, 0.08)',
    borderColor: 'rgba(255, 152, 0, 0.3)',
    '&:hover': {
        backgroundColor: 'rgba(255, 152, 0, 0.12)'
    },
    '& .MuiChip-label': {
        padding: '0 8px',
    },
    '& .MuiChip-deleteIcon': {
        color: '#ff9800',
        fontSize: '16px',
        margin: '0 4px 0 -4px',
        '&:hover': {
            color: '#f57c00'
        }
    }
});

const DropdownBox = styled(Box)({
    marginTop: '8px',
    width: '100%',
    maxWidth: '280px',
    '& .MuiOutlinedInput-root': {
        height: '42px',
        '& .MuiAutocomplete-input': {
            padding: '0 !important',
            fontSize: '14px'
        }
    }
});

const CommissionSettings = () => {
    const dispatch = useDispatch();
    const [settings, setSettings] = useState({
        commissionType: 'percentage',
        commissionRate: '10',
        categories: {
            allCategories: false,
            products: { enabled: false, all: false, specific: false, selectedItems: [] },
            booking: { enabled: false, all: false, specific: false, selectedItems: [] },
            ticketing: { enabled: false, all: false, specific: false, selectedItems: [] }
        }
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [itemsList, setItemsList] = useState({
        products: [],
        booking: [],
        ticketing: []
    });
    const [inputValues, setInputValues] = useState({
        products: '',
        booking: '',
        ticketing: ''
    });

    // Fetch booking data with RTK Query
    const { data: bookingData, isLoading: isBookingLoading } = useGetSpacesQuery({
        page: 1,
        pageSize: 100
    });

    useEffect(() => {
        // Fetch products
        dispatch(fetchProducts({ page: 0, pageSize: 100 }))
            .unwrap()
            .then(response => {
                // Check if response.products exists and is an array
                const products = response?.products || response?.data || [];
                if (Array.isArray(products)) {
                    const productItems = products.map(product => ({
                        id: product.id,
                        name: product.name
                    }));
                    setItemsList(prev => ({ ...prev, products: productItems }));
                }
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                setItemsList(prev => ({ ...prev, products: [] }));
            });

        // Fetch events (ticketing)
        dispatch(fetchEvents({ page: 1, pageSize: 100 }))
            .unwrap()
            .then(response => {
                // The events are now directly available in the response
                const events = response.events || [];
                if (Array.isArray(events)) {
                    const eventItems = events.map(event => ({
                        id: event.id,
                        name: event.name
                    }));
                    setItemsList(prev => ({ ...prev, ticketing: eventItems }));
                }
            })
            .catch(error => {
                console.error('Error fetching events:', error);
                setItemsList(prev => ({ ...prev, ticketing: [] }));
            });
    }, [dispatch]);

    // Update booking items when data is available
    useEffect(() => {
        if (bookingData?.data?.spaces && Array.isArray(bookingData.data.spaces)) {
            const bookingItems = bookingData.data.spaces.map(space => ({
                id: space.id,
                name: space.name
            }));
            setItemsList(prev => ({ ...prev, booking: bookingItems }));
        }
    }, [bookingData]);

    // Handler functions remain the same...
    const updateSettings = (newSettings) => {
        setSettings(newSettings);
        setHasChanges(true);
    };

    const handleCommissionTypeChange = (type) => (event) => {
        if (event.target.checked) {
            updateSettings({ ...settings, commissionType: type });
        }
    };

    const handleCategorySwitch = (category) => (event) => {
        const newSettings = { ...settings };
        if (category === 'allCategories') {
            Object.keys(newSettings.categories).forEach(key => {
                if (key !== 'allCategories') {
                    newSettings.categories[key] = { enabled: false, all: false, specific: false, selectedItems: [] };
                }
            });
            newSettings.categories.allCategories = event.target.checked;
        } else {
            newSettings.categories.allCategories = false;
            newSettings.categories[category] = {
                ...newSettings.categories[category],
                enabled: event.target.checked,
                all: false,
                specific: false,
                selectedItems: []
            };
        }
        updateSettings(newSettings);
    };

    const handleSubCategoryChange = (category, subCategory) => (event) => {
        const newSettings = { ...settings };
        newSettings.categories[category] = {
            ...newSettings.categories[category],
            [subCategory]: event.target.checked,
            ...(subCategory === 'all' && event.target.checked ? { specific: false, selectedItems: [] } : {}),
            ...(subCategory === 'specific' && event.target.checked ? { all: false } : {})
        };
        updateSettings(newSettings);
    };

    const handleItemsChange = (category, newValue) => {
        updateSettings({
            ...settings,
            categories: {
                ...settings.categories,
                [category]: { ...settings.categories[category], selectedItems: newValue }
            }
        });
    };

    const renderCategorySection = (category) => {
        const categorySettings = settings.categories[category];
        const availableItems = itemsList[category] || [];

        // Filter out already selected items from available options
        const unselectedItems = availableItems.filter(
            item => !categorySettings.selectedItems.some(selected => selected.id === item.id)
        );

        return (
            <Box key={category}>
                <CategoryLabel
                    control={
                        <Switch
                            checked={categorySettings.enabled}
                            onChange={handleCategorySwitch(category)}
                            disabled={settings.categories.allCategories}
                        />
                    }
                    label={
                        <LabelBox>
                            <Typography className="title">{category.charAt(0).toUpperCase() + category.slice(1)}</Typography>
                            <Typography className="subtitle">Apply commission to {category}</Typography>
                        </LabelBox>
                    }
                />
                {categorySettings.enabled && (
                    <NestedSection>
                        <FormControlLabel
                            control={<Checkbox checked={categorySettings.all} onChange={handleSubCategoryChange(category, 'all')} />}
                            label={`All ${category}`}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={categorySettings.specific} onChange={handleSubCategoryChange(category, 'specific')} />}
                            label={`Specific ${category}`}
                        />
                        {categorySettings.specific && (
                            <>
                                <DropdownBox>
                                    <Autocomplete
                                        options={unselectedItems}
                                        getOptionLabel={(option) => option?.name || ''}
                                        value={null}
                                        inputValue={inputValues[category]}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValues(prev => ({
                                                ...prev,
                                                [category]: newInputValue
                                            }));
                                        }}
                                        onChange={(_, newValue) => {
                                            if (newValue) {
                                                handleItemsChange(category, [...categorySettings.selectedItems, newValue]);
                                                // Clear the input after selection
                                                setInputValues(prev => ({
                                                    ...prev,
                                                    [category]: ''
                                                }));
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                size="large"
                                                placeholder={`Search ${category}...`}
                                            />
                                        )}
                                    />
                                </DropdownBox>
                                <ItemsPreview>
                                    {categorySettings.selectedItems.map((item) => (
                                        <StyledChip
                                            key={item.id}
                                            label={item?.name || ''}
                                            size="small"
                                            variant="outlined"
                                            onDelete={() => {
                                                const updatedItems = categorySettings.selectedItems.filter(
                                                    selected => selected.id !== item.id
                                                );
                                                handleItemsChange(category, updatedItems);
                                            }}
                                        />
                                    ))}
                                </ItemsPreview>
                            </>
                        )}
                    </NestedSection>
                )}
            </Box>
        );
    };

    return (
        <Box>
            <Box sx={{ marginBottom: 3 }}>
                <Typography variant="h6" fontWeight={600} gutterBottom>Commission Settings</Typography>
                <Typography variant="body2" color="text.secondary">Define how agents earn their commission</Typography>
            </Box>

            <Divider />

            <SettingRow>
                <Box sx={{ width: '100%' }}>
                    <SubSection>Commission Type</SubSection>
                    <InlineFormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={settings.commissionType === 'percentage'} onChange={handleCommissionTypeChange('percentage')} />}
                            label="Percentage"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={settings.commissionType === 'fixed'} onChange={handleCommissionTypeChange('fixed')} />}
                            label="Fixed"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={settings.commissionType === 'hybrid'} onChange={handleCommissionTypeChange('hybrid')} />}
                            label="Hybrid"
                        />
                    </InlineFormGroup>
                </Box>
            </SettingRow>

            <Box sx={{ my: 3 }}>
                <SubSection>Commission Rate</SubSection>
                <InputContainer>
                    <FormControl fullWidth>
                        <TextField
                            value={settings.commissionRate}
                            onChange={(e) => updateSettings({ ...settings, commissionRate: e.target.value })}
                            variant="outlined"
                            size="small"
                            type="number"
                            InputProps={{
                                endAdornment: settings.commissionType === 'percentage' ?
                                    <InputAdornment position="end">%</InputAdornment> :
                                    <InputAdornment position="start">RM</InputAdornment>,
                                inputProps: { min: 0, max: settings.commissionType === 'percentage' ? 100 : 999999 }
                            }}
                        />
                    </FormControl>
                </InputContainer>
            </Box>

            <Divider/>

            <SubSection>Select categories to apply commission</SubSection>

            <CategoryLabel
                control={<Switch checked={settings.categories.allCategories} onChange={handleCategorySwitch('allCategories')} />}
                label={
                    <LabelBox>
                        <Typography className="title">All Categories</Typography>
                        <Typography className="subtitle">Apply commission to all categories</Typography>
                    </LabelBox>
                }
            />

            {['products', 'booking', 'ticketing'].map(category => renderCategorySection(category))}

            <Divider/>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                <Button variant="save" disabled={!hasChanges} onClick={() => {
                    console.log('Saving settings:', settings);
                    setHasChanges(false);
                }}>Save Settings</Button>
            </Box>
        </Box>
    );
};

export default CommissionSettings;
import { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Paper,
    styled,
    Tooltip,
    Divider,
    alpha
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SessionCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.palette.grey[200]}`,
    transition: 'all 0.3s ease',
    '&:hover': {
        boxShadow: theme.shadows[3],
        borderColor: theme.palette.primary.main,
        transform: 'translateY(-2px)'
    }
}));

const TimeSlotChip = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    transition: 'all 0.2s ease',
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        borderColor: alpha(theme.palette.primary.main, 0.2)
    },
    '& .MuiTypography-root': {
        marginRight: theme.spacing(1)
    }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: theme.spacing(1),
        transition: 'all 0.2s ease',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            }
        }
    }
}));

const FixedTimeSlots = () => {
    const [sessionTypes, setSessionTypes] = useState([]);
    const [newSessionDialog, setNewSessionDialog] = useState(false);
    const [newTimeDialog, setNewTimeDialog] = useState(false);
    const [editingSessionIndex, setEditingSessionIndex] = useState(null);
    const [currentTimeSlotSessionIndex, setCurrentTimeSlotSessionIndex] = useState(null);

    const [sessionData, setSessionData] = useState({
        name: '',
        startTime: '',
        endTime: ''
    });

    const [newTimeSlot, setNewTimeSlot] = useState({
        startTime: '',
        endTime: ''
    });

    const convertTo12Hour = (time24) => {
        if (!time24) return '';
        const [hours, minutes] = time24.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minutes} ${ampm}`;
    };

    const handleOpenNewSession = () => {
        setEditingSessionIndex(null);
        setSessionData({ name: '', startTime: '', endTime: '' });
        setNewSessionDialog(true);
    };

    const handleOpenEdit = (sessionIndex) => {
        setEditingSessionIndex(sessionIndex);
        setSessionData({
            name: sessionTypes[sessionIndex].name,
            startTime: sessionTypes[sessionIndex].startTime,
            endTime: sessionTypes[sessionIndex].endTime
        });
        setNewSessionDialog(true);
    };

    const handleSaveSession = () => {
        const newSession = {
            ...sessionData,
            timeSlots: editingSessionIndex !== null ?
                sessionTypes[editingSessionIndex].timeSlots :
                []
        };

        if (editingSessionIndex !== null) {
            const updatedSessions = [...sessionTypes];
            updatedSessions[editingSessionIndex] = newSession;
            setSessionTypes(updatedSessions);
        } else {
            setSessionTypes([...sessionTypes, { ...newSession, timeSlots: [] }]);
        }
        setNewSessionDialog(false);
    };

    const handleAddTime = (sessionIndex) => {
        setCurrentTimeSlotSessionIndex(sessionIndex);
        setNewTimeSlot({ startTime: '', endTime: '' });
        setNewTimeDialog(true);
    };

    const handleSaveTimeSlot = () => {
        if (newTimeSlot.startTime && newTimeSlot.endTime) {
            const updatedSessions = [...sessionTypes];
            updatedSessions[currentTimeSlotSessionIndex].timeSlots.push({
                startTime: newTimeSlot.startTime,
                endTime: newTimeSlot.endTime
            });
            setSessionTypes(updatedSessions);
            setNewTimeDialog(false);
            setNewTimeSlot({ startTime: '', endTime: '' });
        }
    };

    const handleSessionRemove = (sessionIndex) => {
        const updatedSessions = sessionTypes.filter((_, index) => index !== sessionIndex);
        setSessionTypes(updatedSessions);
    };

    const handleTimeSlotRemove = (sessionIndex, slotIndex) => {
        const updatedSessions = [...sessionTypes];
        updatedSessions[sessionIndex].timeSlots = updatedSessions[sessionIndex].timeSlots.filter(
            (_, index) => index !== slotIndex
        );
        setSessionTypes(updatedSessions);
    };

    const isTimeValid = (time) => {
        if (!time) return false;
        const [hours, minutes] = time.split(':');
        return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60;
    };

    const isTimeSlotValid = () => {
        if (!isTimeValid(newTimeSlot.startTime) || !isTimeValid(newTimeSlot.endTime)) return false;
        const [startHours, startMinutes] = newTimeSlot.startTime.split(':');
        const [endHours, endMinutes] = newTimeSlot.endTime.split(':');
        const startTotal = parseInt(startHours) * 60 + parseInt(startMinutes);
        const endTotal = parseInt(endHours) * 60 + parseInt(endMinutes);
        return endTotal > startTotal;
    };

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4,
                pb: 3,
                borderBottom: '1px solid',
                borderColor: 'grey.200'
            }}>
                <Box>
                    <Typography sx={{ fontSize:'16px', fontWeight: 500, mb: 1 }}>
                        Session Types
                    </Typography>
                    <Typography color="text.secondary" sx={{ fontSize:'14px', fontWeight: 400 }}>
                        Manage your available session types and time slots
                    </Typography>
                </Box>
                <Button
                    startIcon={<AddIcon />}
                    onClick={handleOpenNewSession}
                    variant="save"
                >
                    Add New Session
                </Button>
            </Box>

            {sessionTypes.length === 0 ? (
                <Paper sx={{
                    p: 6,
                    textAlign: 'center',
                    borderRadius: 2,
                }}>
                    <Typography variant="h6" sx={{ mb: 2, color: 'text.secondary' }}>
                        No session types added yet
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                        Start by adding your first session type to manage your availability
                    </Typography>
                    <Button
                        variant="save"
                        startIcon={<AddIcon />}
                        onClick={handleOpenNewSession}
                    >
                        Add Your First Session
                    </Button>
                </Paper>
            ) : (
                sessionTypes.map((session, sessionIndex) => (
                    <SessionCard key={sessionIndex} elevation={0}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
                                    {session.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {session.timeSlots.length} time slots available
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Tooltip title="Edit Session">
                                    <IconButton
                                        onClick={() => handleOpenEdit(sessionIndex)}
                                        // size="small"
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Session">
                                    <IconButton
                                        onClick={() => handleSessionRemove(sessionIndex)}
                                        // size="small"
                                        sx={{
                                            color: 'error.main',
                                        }}
                                    >
                                        <DeleteOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: 3,
                            mb: 3,
                            p: 2.5,
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.02),
                            borderRadius: 2
                        }}>
                            <Box flex={1}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <AccessTimeIcon fontSize="small" />
                                    Start Time
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                    {convertTo12Hour(session.startTime)}
                                </Typography>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box flex={1}>
                                <Typography variant="body2" color="text.secondary" sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <AccessTimeIcon fontSize="small" />
                                    End Time
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                    {convertTo12Hour(session.endTime)}
                                </Typography>
                            </Box>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" sx={{
                                mb: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                color: 'text.secondary'
                            }}>
                                Available Time Slots
                                <Tooltip title="These are the specific times when this session type is available for booking">
                                    <InfoOutlinedIcon fontSize="small" sx={{ color: 'text.secondary' }} />
                                </Tooltip>
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                                {session.timeSlots.map((slot, slotIndex) => (
                                    <TimeSlotChip key={slotIndex}>
                                        <Box sx={{ mr: 1 }}>
                                            <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                                {convertTo12Hour(slot.startTime)} - {convertTo12Hour(slot.endTime)}
                                            </Typography>
                                        </Box>
                                        <Tooltip title="Remove Time Slot">
                                            <IconButton
                                                onClick={() => handleTimeSlotRemove(sessionIndex, slotIndex)}
                                                size="small"
                                                sx={{
                                                    ml: 1,
                                                    '&:hover': {
                                                        color: 'error.main'
                                                    }
                                                }}
                                            >
                                                <DeleteOutlineIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </TimeSlotChip>
                                ))}
                            </Box>
                            <Button
                                startIcon={<AddIcon />}
                                onClick={() => handleAddTime(sessionIndex)}
                                variant='save'
                                sx={{ mt: 1 }}
                            >
                                Add Time Slot
                            </Button>
                        </Box>
                    </SessionCard>
                ))
            )}

            {/* Session Dialog */}
            <Dialog
                open={newSessionDialog}
                onClose={() => setNewSessionDialog(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2
                    }
                }}
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        {editingSessionIndex !== null ? 'Edit Session Type' : 'Add Session Type'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                        Define the session details and availability window
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box>
                            <Typography sx={{ mb: 1, fontWeight: 500 }}>Session Name</Typography>
                            <StyledTextField
                                fullWidth
                                value={sessionData.name}
                                onChange={(e) => setSessionData({ ...sessionData, name: e.target.value })}
                                placeholder="Enter session name"
                                variant="outlined"
                            />
                        </Box>
                        <Box>
                            <Typography sx={{ mb: 1, fontWeight: 500 }}>Start Time</Typography>
                            <StyledTextField
                                fullWidth
                                type="time"
                                value={sessionData.startTime}
                                onChange={(e) => setSessionData({ ...sessionData, startTime: e.target.value })}
                            />
                        </Box>
                        <Box>
                            <Typography sx={{ mb: 1, fontWeight: 500 }}>End Time</Typography>
                            <StyledTextField
                                fullWidth
                                type="time"
                                value={sessionData.endTime}
                                onChange={(e) => setSessionData({ ...sessionData, endTime: e.target.value })}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button
                        onClick={() => setNewSessionDialog(false)}
                        variant='cancel'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveSession}
                        variant="save"
                        sx={{
                            borderRadius: 1,
                            textTransform: 'none',
                            px: 3
                        }}
                    >
                        {editingSessionIndex !== null ? 'Save Changes' : 'Add Session'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Time Slot Dialog */}
            <Dialog
                open={newTimeDialog}
                onClose={() => setNewTimeDialog(false)}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2
                    }
                }}
            >
                <DialogTitle sx={{ pb: 1 }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        Add Time Slot
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                        Specify the start and end time for this slot
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box>
                            <Typography sx={{ mb: 1, fontWeight: 500 }}>Start Time</Typography>
                            <StyledTextField
                                fullWidth
                                type="time"
                                value={newTimeSlot.startTime}
                                onChange={(e) => setNewTimeSlot({ ...newTimeSlot, startTime: e.target.value })}
                                InputProps={{
                                    endAdornment: <AccessTimeIcon color="action" />,
                                }}
                            />
                        </Box>
                        <Box>
                            <Typography sx={{ mb: 1, fontWeight: 500 }}>End Time</Typography>
                            <StyledTextField
                                fullWidth
                                type="time"
                                value={newTimeSlot.endTime}
                                onChange={(e) => setNewTimeSlot({ ...newTimeSlot, endTime: e.target.value })}
                                InputProps={{
                                    endAdornment: <AccessTimeIcon color="action" />,
                                }}
                            />
                        </Box>
                        {newTimeSlot.startTime && newTimeSlot.endTime && !isTimeSlotValid() && (
                            <Typography
                                color="error"
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.04),
                                    p: 1.5,
                                    borderRadius: 1
                                }}
                            >
                                End time must be after start time
                            </Typography>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                    <Button
                        onClick={() => setNewTimeDialog(false)}
                        variant='cancel'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveTimeSlot}
                        variant="save"
                        disabled={!isTimeSlotValid()}
                    >
                        Add Time Slot
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FixedTimeSlots;
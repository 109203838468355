import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api'
// const API_BASE_URL = 'https://api-dev.eventlah.com/api/user-models'

const handleError = (error, operation) => {
    console.error(`Error ${operation}:`, error);
    throw error;
};

// GET - Fetch all (with pagination and search)
export const fetchAgents = async (page, pageSize, searchQuery) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user-agents`, {
            params: {
                page: page + 1,
                limit: pageSize,
                search: searchQuery
            }
        });
        return response.data;
    } catch (error) {
        handleError(error, 'fetching coupons');
    }
};

// GET - Fetch all (with pagination and search)
export const fetchUsers = async (page, pageSize, searchQuery) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user-agents`, {
            params: {
                page: page + 1,
                limit: pageSize,
                search: searchQuery
            }
        });
        return response.data;
    } catch (error) {
        handleError(error, 'fetching coupons');
    }
};
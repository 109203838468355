import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Box,
    IconButton, Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ChangeDateDialog = ({ orderId, currentBookingDate }) => {
    const [open, setOpen] = useState(false);
    const [newBookingDate, setNewBookingDate] = useState('');
    const [message, setMessage] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDateChange = (event) => {
        setNewBookingDate(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSave = () => {
        // Here you would implement the logic to save the new date and send email
        console.log('Saving new date:', newBookingDate);
        console.log('Message:', message);
        handleClose();
    };

    return (
        <>
            <Tooltip title="Change Date">
                <IconButton aria-label="change-date" onClick={handleClickOpen}>
                    <CalendarTodayIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Booking Details Change Date
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        This action will cancel old ticketing and create new ticket and email them to customer.
                    </Typography>
                    <Box my={2}>
                        <Typography variant="body2" gutterBottom>Order ID: {orderId}</Typography>
                        <Typography variant="body2" gutterBottom>Current Booking Date: {currentBookingDate}</Typography>
                    </Box>
                    <TextField
                        label="New Booking Date"
                        type="date"
                        value={newBookingDate}
                        onChange={handleDateChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                    <TextField
                        margin="dense"
                        id="message"
                        label="Message"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        value={message}
                        onChange={handleMessageChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="cancel">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} variant="save">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ChangeDateDialog;
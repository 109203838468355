import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api'

// Helper function to handle errors
const handleError = (error, operation) => {
    console.error(`Error ${operation}:`, error);
    throw error;
};

// GET - Fetch all shipping rates (with pagination and search)
export const fetchShippingRates = async (page, pageSize, searchQuery) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/shipping-rates`, {
            params: {
                page: page + 1,
                limit: pageSize,
                search: searchQuery
            }
        });
        return response.data;
    } catch (error) {
        handleError(error, 'fetching shipping rates');
    }
};

// GET - Fetch a single shipping rate by ID
export const fetchShippingRateById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/shipping-rates/${id}`);
        return response.data;
    } catch (error) {
        handleError(error, `fetching shipping rate with id ${id}`);
    }
};

// POST - Create a new shipping rate
export const createShippingRate = async (shippingRateData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/shipping-rates`, shippingRateData);
        return response.data;
    } catch (error) {
        handleError(error, 'creating shipping rate');
    }
};

// PATCH - Update an existing shipping rate
export const updateShippingRate = async (id, shippingRateData) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/shipping-rates?id=${id}`, shippingRateData);
        return response.data;
    } catch (error) {
        handleError(error, `updating shipping rate with id ${id}`);
    }
};

// DELETE - Delete a shipping rate
export const deleteShippingRate = async (id) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/shipping-rates?id=${id}`);
        return response.data;
    } catch (error) {
        handleError(error, `deleting shipping rate with id ${id}`);
    }
};
import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Switch,
    Button,
    Snackbar,
    Alert,
    CircularProgress, DialogTitle
} from '@mui/material';

const EditCouponDialog = ({ open, onClose, coupon, onSave }) => {
    const [couponData, setCouponData] = useState(coupon || {
        name: '',
        discount_type: 'percentage',
        discount_value: '',
        apply_to_specific_products: false,
        limit_total_usage: false,
        limit_date_range: false,
        use_customer_facing_code: false,
        customer_facing_code: '',
    });
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        setCouponData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' || type === 'switch' ? checked : value
        }));
    };

    const openSnackbar = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({
            ...snackbar,
            open: false
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const dataToSend = {
                ...couponData,
                discount_value: parseFloat(couponData.discount_value),
                customer_facing_code: couponData.use_customer_facing_code ? couponData.customer_facing_code : null
            };
            delete dataToSend.use_customer_facing_code;

            await onSave(dataToSend);
            openSnackbar('Coupon updated successfully!', 'success');
            onClose();
        } catch (error) {
            console.error('Error updating coupon:', error);
            openSnackbar('Failed to update coupon. Please try again.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent sx={{padding:4}}>
                <Typography variant='h1' fontSize='25px' fontWeight='700'>
                    Edit coupons
                </Typography>
                <Typography variant="body1" color="textSecondary" fontSize='15px' sx={{ mb: 4 }}>
                    Coupons can be used to discount invoices, subscriptions, or entire customer accounts.
                </Typography>
                <Box>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                required
                                label="Name"
                                name="name"
                                value={couponData.name}
                                onChange={handleInputChange}
                                placeholder="First purchase discount"
                                helperText="This will appear on customers' receipts and invoices."
                                variant="outlined"
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <FormControl component="fieldset" required>
                                <FormLabel component="legend" sx={{fontWeight:'700', fontSize:'15px'}}>
                                    Type
                                </FormLabel>
                                <RadioGroup
                                    name="discount_type"
                                    value={couponData.discount_type}
                                    onChange={handleInputChange}
                                >
                                    <FormControlLabel
                                        value="percentage"
                                        control={<Radio />}
                                        label={<Typography variant="body2" fontSize="13px">Percentage discount</Typography>}
                                    />
                                    <FormControlLabel
                                        value="fixed"
                                        control={<Radio />}
                                        label={<Typography variant="body2" fontSize="13px">Fixed amount discount</Typography>}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <TextField
                                fullWidth
                                required
                                label={couponData.discount_type === 'percentage' ? "Percentage off" : "Amount off"}
                                name="discount_value"
                                value={couponData.discount_value}
                                onChange={handleInputChange}
                                placeholder={couponData.discount_type === 'percentage' ? "%" : "$"}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <Typography>{couponData.discount_type === 'percentage' ? '%' : '$'}</Typography>,
                                }}
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="apply_to_specific_products"
                                        checked={couponData.apply_to_specific_products}
                                        onChange={handleInputChange}
                                    />
                                }
                                label="Apply to specific products"
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="limit_total_usage"
                                        checked={couponData.limit_total_usage}
                                        onChange={handleInputChange}
                                    />
                                }
                                label="Limit the total number of times this coupon can be redeemed"
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="limit_date_range"
                                        checked={couponData.limit_date_range}
                                        onChange={handleInputChange}
                                    />
                                }
                                label="Limit the date range when customers can redeem this coupon"
                            />
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name="use_customer_facing_code"
                                        checked={couponData.use_customer_facing_code}
                                        onChange={handleInputChange}
                                    />
                                }
                                label="Use customer-facing coupon codes"
                            />
                        </Box>

                        {couponData.use_customer_facing_code && (
                            <Box sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    required
                                    label="Customer Facing Code"
                                    name="customer_facing_code"
                                    value={couponData.customer_facing_code}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                />
                            </Box>
                        )}

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 3 }}>
                            <Button variant="cancel" onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="save" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                            </Button>
                        </Box>
                    </form>

                    <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EditCouponDialog;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Paper,
    Box,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    CircularProgress,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { fetchProducts } from '../../redux/productSlice';

const SelectProduct = ({ setSelectedVariants, setBookingFee }) => {
    const dispatch = useDispatch();
    const { products, status, error } = useSelector(state => state.products);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentVariant, setCurrentVariant] = useState('');
    const [currentQuantity, setCurrentQuantity] = useState(1);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [editQuantity, setEditQuantity] = useState(1);
    const [localSelectedVariants, setLocalSelectedVariants] = useState([]);

    useEffect(() => {
        dispatch(fetchProducts({ page: 0, pageSize: 10, searchQuery: '' }));
    }, [dispatch]);

    useEffect(() => {
        setSelectedVariants(localSelectedVariants);
    }, [localSelectedVariants, setSelectedVariants]);

    const handleProductClick = product => {
        setSelectedProduct(product);
        setCurrentVariant('');
        setCurrentQuantity(1);
        setEditingIndex(-1);
    };

    const handleVariantChange = (event) => {
        setCurrentVariant(event.target.value);
    };

    const handleQuantityChange = (event) => {
        const value = event.target.value === '' ? '' : Math.max(1, parseInt(event.target.value));
        setCurrentQuantity(value);
    };

    const handleAddVariant = () => {
        if (currentVariant && currentQuantity > 0) {
            const variant = selectedProduct.product_variants.find(v => v.id === currentVariant);
            const newVariant = {
                ...variant,
                quantity: currentQuantity
            };
            setLocalSelectedVariants(prev => [...prev, newVariant]);
            setCurrentVariant('');
            setCurrentQuantity(1);
        }
    };

    const handleRemoveVariant = (index) => {
        setLocalSelectedVariants(prev => prev.filter((_, i) => i !== index));
        setEditingIndex(-1);
    };

    const handleEditVariant = (index) => {
        setEditingIndex(index);
        setEditQuantity(localSelectedVariants[index].quantity);
    };

    const handleSaveEdit = (index) => {
        setLocalSelectedVariants(prev => prev.map((variant, i) =>
            i === index ? { ...variant, quantity: editQuantity } : variant
        ));
        setEditingIndex(-1);
    };

    const handleEditQuantityChange = (event) => {
        const value = event.target.value === '' ? '' : Math.max(1, parseInt(event.target.value));
        setEditQuantity(value);
    };

    return (
        <Box sx={{ padding: { xs: 0, sm: '0 32px' }, mb:'12px' }}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CalendarTodayIcon sx={{ marginRight: 1 }} />
                    <Typography variant="body1">Select Package</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: { xs: '0px', sm: '32px' },
                        }}
                    >
                        <Grid container spacing={2}>
                            {products.map((product, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Paper
                                        onClick={() => handleProductClick(product)}
                                        sx={{
                                            padding: '16px',
                                            cursor: 'pointer',
                                            backgroundColor: selectedProduct === product ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
                                        }}
                                    >
                                        <Typography variant="h6">{product.name}</Typography>
                                        <Typography variant="body1">RM {Number(product.price).toFixed(2)}</Typography>
                                    </Paper>
                                </Grid>
                            ))}

                            {selectedProduct && (
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Selected Product: {selectedProduct.name}</Typography>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} sm={1}>
                                            <TextField
                                                type="number"
                                                label="Quantity"
                                                value={currentQuantity}
                                                onChange={handleQuantityChange}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            X
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <FormControl fullWidth>
                                                <InputLabel>Select Variant</InputLabel>
                                                <Select value={currentVariant} onChange={handleVariantChange}>
                                                    {selectedProduct.product_variants.map((variant) => (
                                                        <MenuItem key={variant.id} value={variant.id}>
                                                            {variant.variant_name}: {variant.variant_value} - RM {Number(variant.price).toFixed(2)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Button variant="contained" onClick={handleAddVariant} fullWidth>Add</Button>
                                        </Grid>
                                    </Grid>

                                    <List>
                                        {localSelectedVariants.map((variant, index) => (
                                            <ListItem key={index}>
                                                <ListItemText
                                                    primary={`${variant.variant_name}: ${variant.variant_value}`}
                                                    secondary={editingIndex === index
                                                        ? <TextField
                                                            type="number"
                                                            value={editQuantity}
                                                            onChange={handleEditQuantityChange}
                                                            size="small"
                                                        />
                                                        : `Quantity: ${variant.quantity} - Total: RM ${(Number(variant.price) * variant.quantity).toFixed(2)}`
                                                    }
                                                />
                                                <ListItemSecondaryAction>
                                                    {editingIndex === index ? (
                                                        <IconButton edge="end" onClick={() => handleSaveEdit(index)}>
                                                            <SaveIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton edge="end" onClick={() => handleEditVariant(index)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    )}
                                                    <IconButton edge="end" onClick={() => handleRemoveVariant(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default SelectProduct;
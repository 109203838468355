import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import AddPackageDialog from "./AddNewPackageDialogForm/AddNewPackageDialogForm";
import PackageList from "./PackageList";
import { fetchPackages } from "../../../redux/packagesSlice";
import {useDispatch, useSelector} from "react-redux";

const SearchBar = ({ handleDialogOpen }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <Box sx={{ display: 'flex' }}>
            <Button
                variant='cancel'
                sx={{
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    height: '34px',
                }}
            >
                Active Packages
            </Button>
            <Button
                variant='cancel'
                sx={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    color: 'var(--dark-50, #71717A)',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    whiteSpace: 'nowrap',
                    lineHeight: '20px',
                    background: 'var(--gray-30, #F7F7F7)',
                }}
            >
                Archived Packages
            </Button>
        </Box>
        <TextField
            variant="outlined"
            placeholder="Search for package"
            InputProps={{
                startAdornment: <SearchIcon sx={{ marginRight: 1 }} />,
            }}
            sx={{ mx: '48px', flexGrow: 1,  }}
        />
        <Box display='flex'>
            <Button variant="cancel" startIcon={<FilterListIcon />} sx={{ marginRight: 1, height: '34px' }}>
                Filters
            </Button>
            <Button variant="cancel" startIcon={<AddIcon />} onClick={handleDialogOpen} sx={{ whiteSpace: 'nowrap', height: '34px' }}>
                Add Packages
            </Button>
        </Box>
    </Box>
);

const AddPackageToEvent = () => {
    const dispatch = useDispatch();
    const { packages, status, error, packagesLength } = useSelector(state => state.packages);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchPackages());
        }
    }, [status, dispatch]);

    const handleDialogOpen = () => {
        setOpen(true);
    };
    const handleDialogClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <SearchBar handleDialogOpen={handleDialogOpen} />
            </Box>

            {/*Packagelist*/}
            <PackageList handleDialogOpen={handleDialogOpen} />

            {/* Dialog for adding a package */}
            <AddPackageDialog open={open} handleClose={handleDialogClose} />
        </Box>
    );
};

export default AddPackageToEvent;

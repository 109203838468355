import {
    TextField,
    Paper,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment,
    Box,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const VariantTable = ({ variant, updateVariantValue, removeOptionValue, product }) => {
    const defaultImage = product?.product_images?.[0]?.image_url;

    return (
        <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: '80px' }}>Image</TableCell>
                        <TableCell>Variant</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {variant.optionValues.map((value, index) => {
                        const variantId = variant.ids?.[index];
                        const variantImage = variant.variantImages?.[index];
                        const hasImage = variantImage || defaultImage;

                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '60px',
                                            height: '60px',
                                            border: '1px dashed #ccc',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                borderColor: '#1976d2',
                                                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                            },
                                        }}
                                    >
                                        {hasImage ? (
                                            <>
                                                <Box
                                                    component="img"
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '4px',
                                                        objectFit: 'cover',
                                                    }}
                                                    alt={variantImage ? "Variant Image" : "Product Image"}
                                                    src={hasImage}
                                                />
                                                {/* Change overlay */}
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                                        color: 'white',
                                                        fontSize: '10px',
                                                        py: 0.5,
                                                        textAlign: 'center',
                                                        borderBottomLeftRadius: '4px',
                                                        borderBottomRightRadius: '4px',
                                                    }}
                                                >
                                                    Change
                                                </Box>
                                            </>
                                        ) : (
                                            // Empty state with upload indicator
                                            <Box
                                                sx={{
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    color: '#666',
                                                    gap: 0.5,
                                                }}
                                            >
                                                <AddPhotoAlternateIcon sx={{ fontSize: '24px' }} />
                                                <Typography variant="caption" sx={{
                                                    color: '#666',
                                                    textAlign: 'center',
                                                    lineHeight: 1,
                                                }}>
                                                    Add photo
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        value={value}
                                        onChange={(e) => updateVariantValue(variant.id, 'optionValues', index, e.target.value)}
                                        placeholder={`Value ${index + 1}`}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        size="small"
                                        placeholder="20.00"
                                        type="number"
                                        value={variant.prices[index] || ""}
                                        onChange={(e) => updateVariantValue(variant.id, 'prices', index, e.target.value)}
                                        inputProps={{
                                            step: "0.01",
                                            min: "0"
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">RM</InputAdornment>
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => removeOptionValue(variant.id, index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default VariantTable;
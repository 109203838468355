import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    Radio,
    RadioGroup,
    FormControlLabel,
    styled,
    Alert
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TimeTextField = styled(TextField)({
    width: '130px',
    '& input': {
        padding: '8px 14px',
    }
});

const PaymentRule = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '12px',
    padding: '16px',
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
});

const StyledRadioGroup = styled(RadioGroup)({
    '& .MuiFormControlLabel-root': {
        marginRight: '24px',
    },
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        color: '#344054',
    }
});

const SubSectionTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#101828',
    marginBottom: '8px'
});

const PaymentDeadlines = ({ depositDueHours, paymentRules, onDepositDueChange, onPaymentRulesChange }) => {
    const handleHoursChange = (event) => {
        let value = event.target.value;
        // Allow only positive numbers
        if (value === '' || parseInt(value) >= 0) {
            onDepositDueChange(value);
        }
    };

    const handlePaymentRuleChange = (ruleId, field, value) => {
        const newPaymentRules = paymentRules.map(rule => {
            if (rule.id === ruleId) {
                return { ...rule, [field]: value };
            }
            return rule;
        });
        onPaymentRulesChange(newPaymentRules);
    };

    return (
        <Box sx={{ mb: 3 }}>
            <SubSectionTitle>Payment Deadlines</SubSectionTitle>

            {/* Deposit Due Section */}
            <Box sx={{ mb: 3 }}>
                <Typography sx={{ color: '#344054', fontSize: '14px', mb: 1 }}>Deposit Due</Typography>
                <PaymentRule>
                    <TimeTextField
                        size="medium"
                        type="number"
                        value={depositDueHours}
                        onChange={handleHoursChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                        }}
                    />
                    <Typography sx={{ color: '#344054', fontSize: '14px' }}>
                        after booking is confirmed
                    </Typography>
                </PaymentRule>
            </Box>

            {/* Full Payment Rules */}
            <Box>
                <Typography sx={{ color: '#344054', fontSize: '14px', mb: 1 }}>Full Payment Due</Typography>
                {paymentRules.map((rule) => (
                    <PaymentRule key={rule.id}>
                        <TimeTextField
                            type="number"
                            value={rule.value}
                            onChange={(e) => handlePaymentRuleChange(rule.id, 'value', e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                            }}
                        />
                        <StyledRadioGroup
                            row
                            value={rule.type}
                            onChange={(e) => handlePaymentRuleChange(rule.id, 'type', e.target.value)}
                        >
                            <FormControlLabel
                                value="before_event"
                                control={<Radio />}
                                label="Days before the event"
                            />
                            <FormControlLabel
                                value="after_booking"
                                control={<Radio />}
                                label="Days after event"
                            />
                        </StyledRadioGroup>
                    </PaymentRule>
                ))}
                    <Alert
                        icon={<InfoOutlinedIcon />}
                        severity="info"
                        sx={{ mb: 3, backgroundColor: '#F9FAFB', color: '#101828' }}
                    >
                        If you set "0 days before the event", full payment is due on the day of the event<br />
                    </Alert>
            </Box>
        </Box>
    );
};

export default PaymentDeadlines;
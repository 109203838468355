import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api';

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchBookings = createAsyncThunk(
    'bookings/fetchBookings',
    async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/bookings`, {
                headers,
                params: {
                    page,
                    limit: pageSize,
                    search: searchQuery,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createBooking = createAsyncThunk(
    'bookings/create',
    async (bookingData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/bookings`, bookingData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchBookingById = createAsyncThunk(
    'bookings/fetchById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/bookings/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateBooking = createAsyncThunk(
    'bookings/update',
    async ({ id, bookingData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/bookings/${id}`, bookingData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteBooking = createAsyncThunk(
    'bookings/delete',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/bookings/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const bookingsSlice = createSlice({
    name: 'bookings',
    initialState: {
        bookings: null,
        status: 'idle',
        error: null,
        pagination: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBookings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBookings.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = action.payload.data.bookings;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchBookings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch bookings';
            })

            .addCase(createBooking.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createBooking.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings.push(action.payload);
            })
            .addCase(createBooking.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create booking';
            })

            .addCase(fetchBookingById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBookingById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedBooking = action.payload.data;
            })
            .addCase(fetchBookingById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch booking by ID';
            })

            .addCase(updateBooking.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateBooking.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.bookings.findIndex(booking => booking.id === action.payload.id);
                if (index !== -1) state.bookings[index] = action.payload;
                if (state.selectedBooking && state.selectedBooking.id === action.payload.id) {
                    state.selectedBooking = action.payload;
                }
            })
            .addCase(updateBooking.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update booking';
            })

            .addCase(deleteBooking.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteBooking.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.bookings = state.bookings.filter(booking => booking.id !== action.payload);
                if (state.selectedBooking && state.selectedBooking.id === action.payload) {
                    state.selectedBooking = null;
                }
            })
            .addCase(deleteBooking.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete booking';
            });
    },
});

export default bookingsSlice.reducer;
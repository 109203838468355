import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import keycloak from "../keycloak";

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    try {
        await new Promise(resolve => setTimeout(resolve, 3000));
        if (keycloak.token) {
            return {
                'Authorization': `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            };
        } else {
            throw new Error('Token is not available');
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        throw new Error('Failed to refresh authentication token');
    }
};

export const fetchOrganizationPublicDetails = createAsyncThunk(
    'organizationPublicDetails/fetchOrganizationPublicDetails',
    async (organizationId, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/organization-public-details?organization_id=${organizationId}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const saveOrganizationPublicDetails = createAsyncThunk(
    'organizationPublicDetails/saveOrganizationPublicDetails',
    async (details, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/organization-public-details`, details, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateOrganizationPublicDetails = createAsyncThunk(
    'organizationPublicDetails/updateOrganizationPublicDetails',
    async ({ id, details }, { rejectWithValue }) => {
        if (!details || Object.keys(details).length === 0) {
            return rejectWithValue('Public details data is required');
        }

        try {
            const headers = await getAuthHeaders();
            console.log('Payload data:', details);
            const response = await axios.patch(`${API_BASE_URL}/organization-public-details?id=${id}`, details, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const organizationPublicDetailsSlice = createSlice({
    name: 'organizationPublicDetails',
    initialState: {
        details: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationPublicDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchOrganizationPublicDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.details = [action.payload];
            })
            .addCase(fetchOrganizationPublicDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch organization public details';
            })
            .addCase(saveOrganizationPublicDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveOrganizationPublicDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.details.push(action.payload);
            })
            .addCase(saveOrganizationPublicDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to save organization public details';
            })
            .addCase(updateOrganizationPublicDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateOrganizationPublicDetails.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.details.findIndex(detail => detail.id === action.payload.id);
                if (index !== -1) {
                    state.details[index] = action.payload;
                }
            })
            .addCase(updateOrganizationPublicDetails.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update organization public details';
            });
    },
});

export default organizationPublicDetailsSlice.reducer;
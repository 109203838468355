import { Box, TextField, Typography, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const CapacityTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        height: '40px',
    },
    '& input': {
        textAlign: 'center',
    }
});

const StyledFormControlLabel = styled(FormControlLabel)({
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '100%',
    margin: 0,
    marginBottom: '24px',
    '& .MuiSwitch-root': {
        marginLeft: 8,
    }
});

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const CapacitySettings = ({
                              minCapacityEnabled,
                              maxCapacityEnabled,
                              venueData,
                              onMinCapacityChange,
                              onMaxCapacityChange,
                              onInputChange
                          }) => {
    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px', paddingRight: { md: '50px', lg: '240px' } }}>
            <TextFieldTitle>
                Capacity Settings
            </TextFieldTitle>

            <Box sx={{ width: '100%' }}>
                {/* Minimum Capacity */}
                <Box sx={{ mb: 5 }}>
                    <StyledFormControlLabel
                        control={
                            <Switch
                                checked={minCapacityEnabled}
                                onChange={(e) => onMinCapacityChange(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <LabelContent>
                                <Typography className="heading">Minimum Capacity</Typography>
                                <Typography className="subtext">Set minimum number of pax required</Typography>
                            </LabelContent>
                        }
                    />

                    {minCapacityEnabled && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            borderRadius: 1,
                            ml:0,
                        }}>
                            <Typography sx={{ fontSize: '14px', color: 'var(--Gray-600)' }}>
                                Minimum guests required:
                            </Typography>
                            <CapacityTextField
                                name="minimum_pax"
                                value={venueData.minimum_pax}
                                onChange={onInputChange}
                                type="number"
                                size="small"
                                sx={{ width: '100px' }}
                                inputProps={{
                                    min: 1,
                                    max: venueData.maximum_pax || 999999,
                                    'aria-label': 'Minimum capacity'
                                }}
                            />
                            <Typography sx={{ fontSize: '14px', color: 'var(--Gray-600)' }}>
                                pax
                            </Typography>
                        </Box>
                    )}
                </Box>

                {/* Maximum Capacity */}
                <Box>
                    <StyledFormControlLabel
                        control={
                            <Switch
                                checked={maxCapacityEnabled}
                                onChange={(e) => onMaxCapacityChange(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <LabelContent>
                                <Typography className="heading">Maximum Capacity</Typography>
                                <Typography className="subtext">Set maximum number of pax allowed</Typography>
                            </LabelContent>
                        }
                    />

                    {maxCapacityEnabled && (
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            ml:0,
                            borderRadius: 1
                        }}>
                            <Typography sx={{ fontSize: '14px', color: 'var(--Gray-600)' }}>
                                Maximum guests allowed:
                            </Typography>
                            <CapacityTextField
                                name="maximum_pax"
                                value={venueData.maximum_pax}
                                onChange={onInputChange}
                                type="number"
                                size="small"
                                sx={{ width: '100px' }}
                                inputProps={{
                                    min: venueData.minimum_pax || 1,
                                    'aria-label': 'Maximum capacity'
                                }}
                            />
                            <Typography sx={{ fontSize: '14px', color: 'var(--Gray-600)' }}>
                                pax
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default CapacitySettings;
import {useState} from "react";
import {
    Box,
    Typography,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    styled
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import CustomDuration from './CustomDuration';
import FixedTimeSlots from './FixedTimeSlots';

const TextFieldTitle = styled(Typography)(({ theme }) => ({
    color: 'var(--Gray-700, #344054)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    width: '100%',
    display: 'flex',
    marginTop: 4,
    [theme.breakpoints.up('md')]: {
        width: '312px',
    }
}));

const LabelContent = styled(Box)({
    '& .heading': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828',
        marginBottom: '4px'
    },
    '& .subtext': {
        fontSize: '14px',
        color: '#667085'
    }
});

const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px'
});

const TitleSection = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .title': {
        fontSize: '16px',
        fontWeight: 500,
        color: '#101828'
    }
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    width: '100%',
    marginBottom: '24px',
    padding: '4px',
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    '& .MuiToggleButtonGroup-grouped': {
        border: 'none',
        borderRadius: '6px !important',
        margin: '0 !important',
        flex: 1,
        textTransform: 'none',
        '&.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: '#101828',
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1)',
        }
    }
});

const AddNewPackagesTimeSlots = () => {
    const [isOverrideEnabled, setIsOverrideEnabled] = useState(false);
    const [timeSlotMode, setTimeSlotMode] = useState('custom');
    const [sessionTypes, setSessionTypes] = useState([
        {
            name: 'Premium Lunch Session',
            startTime: '11:00',
            endTime: '14:00',
            timeSlots: ['12:00 PM', '1:00 PM']
        }
    ]);
    const [settings, setSettings] = useState({
        minDurationHours: '0',
        minDurationMinutes: '30',
        maxDurationHours: '3',
        maxDurationMinutes: '0',
        timeIncrementHours: '0',
        timeIncrementMinutes: '15'
    });

    // Dialog states
    const [newSessionDialog, setNewSessionDialog] = useState(false);
    const [newTimeDialog, setNewTimeDialog] = useState(false);
    const [activeSessionIndex, setActiveSessionIndex] = useState(null);
    const [newSessionData, setNewSessionData] = useState({
        name: '',
        startTime: null,
        endTime: null,
    });
    const [newTimeSlot, setNewTimeSlot] = useState(null);

    const handleSettingChange = (field, value) => {
        // Ensure value is not negative
        const sanitizedValue = Math.max(0, parseInt(value) || 0);

        // For minutes, ensure value is less than 60
        if (field.includes('Minutes') && sanitizedValue >= 60) {
            return;
        }

        setSettings(prev => ({
            ...prev,
            [field]: sanitizedValue.toString()
        }));
    };

    const handleTimeSlotRemove = (sessionIndex, slotIndex) => {
        const newSessionTypes = [...sessionTypes];
        newSessionTypes[sessionIndex].timeSlots.splice(slotIndex, 1);
        setSessionTypes(newSessionTypes);
    };

    const handleSessionRemove = (index) => {
        const newSessionTypes = [...sessionTypes];
        newSessionTypes.splice(index, 1);
        setSessionTypes(newSessionTypes);
    };

    const handleAddSession = () => {
        if (newSessionData.name && newSessionData.startTime && newSessionData.endTime) {
            const newSession = {
                name: newSessionData.name,
                startTime: format(newSessionData.startTime, 'HH:mm'),
                endTime: format(newSessionData.endTime, 'HH:mm'),
                timeSlots: []
            };
            setSessionTypes([...sessionTypes, newSession]);
            setNewSessionData({ name: '', startTime: null, endTime: null });
            setNewSessionDialog(false);
        }
    };

    const handleAddTimeSlot = () => {
        if (newTimeSlot && activeSessionIndex !== null) {
            const formattedTime = format(newTimeSlot, 'hh:mm a');
            const newSessionTypes = [...sessionTypes];
            const timeSlots = newSessionTypes[activeSessionIndex].timeSlots;

            if (!timeSlots.includes(formattedTime)) {
                timeSlots.push(formattedTime);
                timeSlots.sort((a, b) => {
                    return new Date(`2000/01/01 ${a}`) - new Date(`2000/01/01 ${b}`);
                });
                setSessionTypes(newSessionTypes);
            }

            setNewTimeSlot(null);
            setNewTimeDialog(false);
        }
    };

    const handleOpenAddTime = (sessionIndex) => {
        setActiveSessionIndex(sessionIndex);
        setNewTimeDialog(true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ marginBottom: '16px' }}>
                <TextFieldTitle>
                    Time Slots
                </TextFieldTitle>

                <Box sx={{ width: '100%' }}>
                    <Header>
                        <TitleSection>
                            <LabelContent>
                                <Typography className="heading">Time Settings</Typography>
                                <Typography className="subtext">Allow customers to customize availability for private events and special occasions.</Typography>
                            </LabelContent>
                        </TitleSection>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Switch
                                checked={isOverrideEnabled}
                                onChange={(e) => setIsOverrideEnabled(e.target.checked)}
                                color="primary"
                            />
                        </Box>
                    </Header>

                    {isOverrideEnabled && (
                        <>
                            <StyledToggleButtonGroup
                                value={timeSlotMode}
                                exclusive
                                onChange={(e, value) => value && setTimeSlotMode(value)}
                                aria-label="time slot mode"
                            >
                                <ToggleButton value="custom">Custom Duration</ToggleButton>
                                <ToggleButton value="fixed">Fixed Time Slots</ToggleButton>
                            </StyledToggleButtonGroup>

                            {timeSlotMode === 'custom' ? (
                                <CustomDuration
                                    settings={settings}
                                    handleSettingChange={handleSettingChange}
                                />
                            ) : (
                                <FixedTimeSlots
                                    sessionTypes={sessionTypes}
                                    handleSessionRemove={handleSessionRemove}
                                    handleTimeSlotRemove={handleTimeSlotRemove}
                                    handleOpenAddTime={handleOpenAddTime}
                                    newSessionDialog={newSessionDialog}
                                    setNewSessionDialog={setNewSessionDialog}
                                    newTimeDialog={newTimeDialog}
                                    setNewTimeDialog={setNewTimeDialog}
                                    newSessionData={newSessionData}
                                    setNewSessionData={setNewSessionData}
                                    newTimeSlot={newTimeSlot}
                                    setNewTimeSlot={setNewTimeSlot}
                                    handleAddSession={handleAddSession}
                                    handleAddTimeSlot={handleAddTimeSlot}
                                />
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default AddNewPackagesTimeSlots;
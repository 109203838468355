import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchCoupons = createAsyncThunk(
    'coupons/fetchCoupons',
    async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/coupons`, {
                headers,
                params: {
                    page: page + 1,
                    limit: pageSize,
                    search: searchQuery,
                },
            });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createCoupon = createAsyncThunk(
    'coupons/createCoupon',
    async (couponData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/coupons`, couponData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateCoupon = createAsyncThunk(
    'coupons/updateCoupon',
    async ({ id, postData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/coupons?id=${id}`, postData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteCoupon = createAsyncThunk(
    'coupons/deleteCoupon',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/coupons/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const couponSlice = createSlice({
    name: 'coupons',
    initialState: {
        coupons: [],
        status: 'idle',
        error: null,
        totalCount: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoupons.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCoupons.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.coupons = action.payload.coupons;
                state.totalCount = action.payload.pagination.totalCount;
            })
            .addCase(fetchCoupons.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch coupons';
            })
            .addCase(createCoupon.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.coupons.push(action.payload);
            })
            .addCase(updateCoupon.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.coupons.findIndex(coupon => coupon.id === action.payload.id);
                if (index !== -1) {
                    state.coupons[index] = action.payload;
                }
            })
            .addCase(deleteCoupon.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.coupons = state.coupons.filter(coupon => coupon.id !== action.payload);
            });
    }
});

export default couponSlice.reducer;
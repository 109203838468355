import {
    Box,
    Typography,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    InputAdornment,
    styled
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const SubSectionTitle = styled(Typography)({
    fontSize: '16px',
    fontWeight: 500,
    color: '#101828',
    marginBottom: '16px'
});

const StyledFormGroup = styled(FormGroup)({
    '& .MuiFormControlLabel-root': {
        marginRight: 0,
        marginBottom: '12px',
    },
    '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        color: '#344054',
    }
});

const RefundRule = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '12px',
    padding: '16px',
    backgroundColor: '#F9FAFB',
    borderRadius: '8px',
    '& .MuiTextField-root': {
        width: '160px', // Increased width to accommodate longer labels
    }
});

const AddRuleButton = styled(Button)({
    color: '#344054',
    fontSize: '14px',
    textTransform: 'none',
    padding: '8px 16px',
    border: '1px dashed #D0D5DD',
    borderRadius: '8px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#F9FAFB',
    }
});

const HelperText = styled(Typography)({
    fontSize: '14px',
    color: '#475467',
    marginBottom: '12px'
});

const DepositRefundPolicy = ({
                                 refundPolicy,
                                 customPolicy,
                                 refundRules = [],
                                 onRefundPolicyChange,
                                 onCustomPolicyChange,
                                 onRefundRulesChange
                             }) => {
    const handleCheckboxChange = (field) => (event) => {
        const newRefundPolicy = {
            ...refundPolicy,
            [field]: event.target.checked
        };
        onRefundPolicyChange(newRefundPolicy);

        if (field === 'partial-refund' && !event.target.checked) {
            onRefundRulesChange([]);
        }
    };

    const handleRefundRuleChange = (ruleId, field, value) => {
        const newRefundRules = refundRules.map(rule => {
            if (rule.id === ruleId) {
                return { ...rule, [field]: value };
            }
            return rule;
        });
        onRefundRulesChange(newRefundRules);
    };

    const addRefundRule = () => {
        const newRule = {
            id: Date.now(),
            days: '30',
            percentage: '50'
        };
        onRefundRulesChange([...refundRules, newRule]);
    };

    const removeRefundRule = (ruleId) => {
        const newRefundRules = refundRules.filter(rule => rule.id !== ruleId);
        onRefundRulesChange(newRefundRules);
    };

    return (
        <>
            <Box sx={{ mb: 4 }}>
                <SubSectionTitle>
                    How would you like to handle deposit refunds?
                </SubSectionTitle>
                <StyledFormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={refundPolicy['non-refundable']}
                                onChange={handleCheckboxChange('non-refundable')}
                            />
                        }
                        label="Non-Refundable (Upon Deposit Payment)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={refundPolicy['full-refund']}
                                onChange={handleCheckboxChange('full-refund')}
                            />
                        }
                        label="Full refund"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={refundPolicy['partial-refund']}
                                onChange={handleCheckboxChange('partial-refund')}
                            />
                        }
                        label="Partial refund"
                    />
                </StyledFormGroup>

                {refundPolicy['partial-refund'] && (
                    <Box sx={{ mt: 3 }}>
                        <Typography sx={{
                            color: '#344054',
                            fontSize: '14px',
                            fontWeight: 500,
                            mb: 2
                        }}>
                            Define your refund rules
                        </Typography>
                        {refundRules.map((rule) => (
                            <RefundRule key={rule.id}>
                                <TextField
                                    size="small"
                                    type="number"
                                    value={rule.days}
                                    onChange={(e) => handleRefundRuleChange(rule.id, 'days', e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                                    }}
                                />
                                <TextField
                                    size="small"
                                    type="number"
                                    value={rule.percentage}
                                    onChange={(e) => handleRefundRuleChange(rule.id, 'percentage', e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                />
                                <Button
                                    onClick={() => removeRefundRule(rule.id)}
                                    sx={{
                                        minWidth: 'auto',
                                        color: '#344054',
                                        '&:hover': {
                                            backgroundColor: '#F2F4F7'
                                        }
                                    }}
                                    startIcon={<DeleteOutlineIcon />}
                                >
                                    Remove
                                </Button>
                            </RefundRule>
                        ))}
                        {refundRules.length < 3 && (
                            <AddRuleButton
                                startIcon={<AddIcon />}
                                onClick={addRefundRule}
                            >
                                Add another refund rule
                            </AddRuleButton>
                        )}
                    </Box>
                )}
            </Box>

            <Box>
                <Typography sx={{
                    mb: 1,
                    color: '#344054',
                    fontSize: '14px',
                    fontWeight: 500
                }}>
                    Additional refund terms (Compulsory)
                </Typography>
                <HelperText>
                    Add any special conditions or exceptions to your refund policy
                </HelperText>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={customPolicy}
                    onChange={(e) => onCustomPolicyChange(e.target.value)}
                    placeholder="Example: Refunds may be granted in exceptional circumstances such as medical emergencies with proper documentation..."
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: '#FFFFFF'
                        }
                    }}
                />
            </Box>
        </>
    );
};

export default DepositRefundPolicy;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/authUtils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:3001/api';

const getAuthHeaders = async () => {
    const token = await getToken();
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const fetchTransactions = createAsyncThunk(
    'transactions/fetchTransactions',
    async ({ page, pageSize, searchQuery }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/transactions`, {
                headers,
                params: {
                    page,
                    limit: pageSize,
                    search: searchQuery,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const createTransaction = createAsyncThunk(
    'transactions/create',
    async (transactionData, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.post(`${API_BASE_URL}/transactions`, transactionData, { headers });
            return response.data.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const fetchTransactionById = createAsyncThunk(
    'transactions/fetchById',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.get(`${API_BASE_URL}/transactions/${id}`, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const updateTransaction = createAsyncThunk(
    'transactions/update',
    async ({ id, transactionData }, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            const response = await axios.patch(`${API_BASE_URL}/transactions/${id}`, transactionData, { headers });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

export const deleteTransaction = createAsyncThunk(
    'transactions/delete',
    async (id, { rejectWithValue }) => {
        try {
            const headers = await getAuthHeaders();
            await axios.delete(`${API_BASE_URL}/transactions/${id}`, { headers });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: error.message });
        }
    }
);

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: null,
        status: 'idle',
        error: null,
        selectedTransaction: null,
        pagination: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload.data.transactions;
                state.pagination = action.payload.data.pagination;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch transactions';
            })
            .addCase(createTransaction.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createTransaction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = state.transactions ? [...state.transactions, action.payload] : [action.payload];
            })
            .addCase(createTransaction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to create transaction';
            })
            .addCase(fetchTransactionById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactionById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectedTransaction = action.payload;
            })
            .addCase(fetchTransactionById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to fetch transaction by ID';
            })
            .addCase(updateTransaction.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateTransaction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.transactions.findIndex(transaction => transaction.id === action.payload.id);
                if (index !== -1) state.transactions[index] = action.payload;
                if (state.selectedTransaction && state.selectedTransaction.id === action.payload.id) {
                    state.selectedTransaction = action.payload;
                }
            })
            .addCase(updateTransaction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to update transaction';
            })
            .addCase(deleteTransaction.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteTransaction.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = state.transactions.filter(transaction => transaction.id !== action.payload);
                if (state.selectedTransaction && state.selectedTransaction.id === action.payload) {
                    state.selectedTransaction = null;
                }
            })
            .addCase(deleteTransaction.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload?.message || 'Failed to delete transaction';
            });
    },
});

export default transactionsSlice.reducer;
import React, { useState, useCallback, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import { isMobile } from 'react-device-detect';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';

const QRBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
}));

const QRDialog = ({ open, onClose, bookingId }) => {
    const [email, setEmail] = useState('');
    const [scannedResult, setScannedResult] = useState('');
    const [hasCamera, setHasCamera] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkCamera = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                setHasCamera(videoDevices.length > 0);
            } catch (error) {
                console.error('Error checking for camera:', error);
                setHasCamera(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkCamera();
    }, []);

    const handleEmailChange = useCallback((event) => {
        setEmail(event.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        console.log('Email:', email);
        console.log('Scanned Result:', scannedResult);
        onClose();
    }, [email, scannedResult, onClose]);

    const handleScan = useCallback((data) => {
        if (data) {
            const scannedUrl = data.text;
            setScannedResult(scannedUrl);
            // Redirect to the scanned URL
            window.location.href = scannedUrl;
        }
    }, []);

    const handleError = (error) => {
        console.error('QR scan error:', error);
        setHasCamera(false);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Scan Ticket QR Code</DialogTitle>
            <DialogContent>
                <QRBox>
                    {isLoading ? (
                        <CircularProgress />
                    ) : hasCamera ? (
                        <QrScanner
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            constraints={{
                                video: { facingMode: isMobile ? 'environment' : 'user' }
                            }}
                            style={{ width: '100%', height: '100%' }}
                        />
                    ) : (
                        <Typography variant="body1" color="error">
                            No camera detected. Please ensure your device has a camera and you've granted permission to use it.
                        </Typography>
                    )}
                </QRBox>
                {/*<TextField*/}
                {/*    autoFocus*/}
                {/*    margin="dense"*/}
                {/*    id="email"*/}
                {/*    label="Email Address"*/}
                {/*    type="email"*/}
                {/*    fullWidth*/}
                {/*    variant="outlined"*/}
                {/*    value={email}*/}
                {/*    onChange={handleEmailChange}*/}
                {/*/>*/}
                {scannedResult && (
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                        Scanned result: {scannedResult}
                    </Typography>
                )}
            </DialogContent>
            {/*<DialogActions>*/}
            {/*    <Button onClick={onClose} variant='cancel'>Cancel</Button>*/}
            {/*    <Button onClick={handleSubmit} variant='save'>Submit</Button>*/}
            {/*</DialogActions>*/}
        </Dialog>
    );
};

export default QRDialog;
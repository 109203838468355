import { useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, IconButton } from '@mui/material';
import AgentsSetupForm from "./AgentsSetupForm";
import AgentBankDetails from "./AgentBankDetails";

const AgentProfile = () => {
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ padding:{xs:1,md:'32px'}, height: '100%', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
            <Box flex={1}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', alignSelf: 'stretch', width: '100%'}}>
                    <Box>
                        <Typography variant='h1'>Agent Profile</Typography>
                        <Typography variant='h2'>Manage and configure your agents.</Typography>
                    </Box>
                    <Box sx={{ ml: 'auto', mr:'32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/*addbuttonhere*/}
                    </Box>
                </Box>

                <Box height='38px' paddingTop='32px'>
                    <Tabs value={value} onChange={handleTabChange} aria-label="service tabs">
                        <Tab label="Profile" />
                        <Tab label="Bank Details" />
                    </Tabs>
                </Box>

                <Divider sx={{margin: '41px 0 32px 0'}}/>

                <Box>
                    { value === 0 && <AgentsSetupForm/> }
                    { value === 1 && <AgentBankDetails/> }
                </Box>
            </Box>
        </Box>
    );
};

export default AgentProfile;